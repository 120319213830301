// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface Device
 */
export interface Device {
  /**
   *
   * @type {string}
   * @memberof Device
   */
  brand?: string
  /**
   *
   * @type {string}
   * @memberof Device
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof Device
   */
  name?: string
}

export function DeviceFromJSON(json: any): Device {
  return DeviceFromJSONTyped(json, false)
}

export function DeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Device {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brand: !exists(json, 'brand') ? undefined : json['brand'],
    model: !exists(json, 'model') ? undefined : json['model'],
    name: !exists(json, 'name') ? undefined : json['name'],
  }
}

export function DeviceToJSON(value?: Device | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand: value.brand,
    model: value.model,
    name: value.name,
  }
}
