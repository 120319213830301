import jwtDecode from 'jwt-decode'
import { useCallback, useEffect, useState } from 'react'
import { DecodedToken, TokenContext } from 'services/tokens'
import { FCC, Token } from 'types'

const TOKENS_KEY = 'tokens'

const safelyParseTokens = (): DecodedToken[] => {
  const json = localStorage.getItem(TOKENS_KEY) || '[]'
  try {
    const parsed: unknown = JSON.parse(json)
    if (parsed instanceof Array) return parsed.map(decodeToken)
    else return []
  } catch {
    return []
  }
}

const decodeToken = (token: Token): DecodedToken => {
  const decodedToken: DecodedToken = { ...jwtDecode(token), raw: token }
  if (!decodedToken.sub) throw new Error('sub is missing')
  if (!decodedToken.exp) throw new Error('exp is missing')
  return decodedToken
}

export const TokensProvider: FCC = ({ children }) => {
  const [tokens, setTokens] = useState(safelyParseTokens())

  useEffect(() => {
    localStorage.setItem(TOKENS_KEY, JSON.stringify(tokens.map(({ raw }) => raw)))
  }, [tokens])

  const addToken = useCallback((rawToken: Token) => {
    const decodedToken = decodeToken(rawToken)
    setTokens((oldTokens) => [...oldTokens.filter(({ sub }) => sub !== decodedToken.sub), decodedToken])
  }, [])

  const removeToken = useCallback((rawToken: Token) => {
    setTokens((oldTokens) => oldTokens.filter((oldToken) => rawToken !== oldToken.raw))
  }, [])

  const clearTokens = useCallback(() => setTokens([]), [])

  return (
    <TokenContext.Provider value={{ tokens, addToken, removeToken, clearTokens }}>{children}</TokenContext.Provider>
  )
}
