import { OneOf } from './types'

// POST-CODE-FREEZE: Add the new develop version and set it in:
// - package.json
// - azure-pipelines.yaml
// - azure-pipelines-mobile.yaml
export const Version = {
  v1_8_0: '1.8.0',
  v1_9_0: '1.9.0',
  v2_0_0: '2.0.0',
  v2_1_0: '2.1.0',
  v2_2_0: '2.2.0',
  v2_3_0: '2.3.0',
  v2_4_0: '2.4.0',
  v2_5_0: '2.5.0',
} as const
export type Version = OneOf<typeof Version>
