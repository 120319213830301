import { DEFAULT_STORAGE_KEY_TTL } from 'const'
import { SignupRequest } from 'oneid-api-frontend'
import { assert } from 'services/assert'
import { generateFormattedKeyPairData } from 'services/crypto'
import * as storage from 'services/storage'
import { oidcApi } from 'utils/apis'

export const signupOIDC = async (uid: string, request: Omit<SignupRequest, 'publicKey'>) => {
  const { publicKey, publicKeyHash, privateKey } = await generateFormattedKeyPairData()

  const response = await oidcApi.signupRaw({
    signupRequest: { ...request, publicKey },
    interactionId: uid,
  })

  await assert(response.raw)

  storage.set(publicKeyHash, JSON.stringify(privateKey), DEFAULT_STORAGE_KEY_TTL)

  return response.raw.url
}
