// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface FinishInteraction
 */
export interface FinishInteraction {
  /**
   *
   * @type {string}
   * @memberof FinishInteraction
   */
  context: string
  /**
   *
   * @type {string}
   * @memberof FinishInteraction
   */
  entityEndpoint: string
  /**
   *
   * @type {string}
   * @memberof FinishInteraction
   */
  signInToken?: string
  /**
   *
   * @type {string}
   * @memberof FinishInteraction
   */
  type: string
}

export function FinishInteractionFromJSON(json: any): FinishInteraction {
  return FinishInteractionFromJSONTyped(json, false)
}

export function FinishInteractionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinishInteraction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    context: json['context'],
    entityEndpoint: json['entityEndpoint'],
    signInToken: !exists(json, 'signInToken') ? undefined : json['signInToken'],
    type: json['type'],
  }
}

export function FinishInteractionToJSON(value?: FinishInteraction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    context: value.context,
    entityEndpoint: value.entityEndpoint,
    signInToken: value.signInToken,
    type: value.type,
  }
}
