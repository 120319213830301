import { isArrayOfTypes, isBoolean, isObject, isString, isStructure, isUndefinedOr } from 'oneid-common'

export const isJsonWebKey = (key: unknown): key is JsonWebKey => {
  const isProperKey = isStructure<JsonWebKey>({
    alg: isUndefinedOr(isString),
    crv: isUndefinedOr(isString),
    d: isUndefinedOr(isString),
    dp: isUndefinedOr(isString),
    dq: isUndefinedOr(isString),
    e: isUndefinedOr(isString),
    ext: isUndefinedOr(isBoolean),
    k: isUndefinedOr(isString),
    key_ops: isUndefinedOr(isArrayOfTypes(isString)),
    kty: isUndefinedOr(isString),
    n: isUndefinedOr(isString),
    oth: isUndefinedOr(
      isArrayOfTypes(
        isStructure({
          d: isUndefinedOr(isString),
          r: isUndefinedOr(isString),
          t: isUndefinedOr(isString),
        }),
      ),
    ),
    p: isUndefinedOr(isString),
    q: isUndefinedOr(isString),
    qi: isUndefinedOr(isString),
    use: isUndefinedOr(isString),
    x: isUndefinedOr(isString),
    y: isUndefinedOr(isString),
  })(key)
  if (!isProperKey) {
    console.error('Non-standard format of JsonWebKey')
  }
  return isObject(key)
}
