import * as base64 from 'base64-arraybuffer'

const hash = async (data: string) => {
  const encoder = new TextEncoder()
  const encodedData = encoder.encode(data)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', encodedData)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
}

const toBase64 = (data: ArrayBuffer) => base64.encode(data)

const fromBase64 = (data: string) => base64.decode(data)

const generateKeyPair = async (length = 1024) =>
  await window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: length,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256',
    },
    true,
    ['encrypt', 'decrypt'],
  )

const formatKeyBase64 = async (key: CryptoKey) => {
  const exportedKey = await window.crypto.subtle.exportKey('spki', key)
  return toBase64(exportedKey)
}

const formatKeyJWK = async (key: CryptoKey) => await window.crypto.subtle.exportKey('jwk', key)

export const generateFormattedKeyPairData = async () => {
  const keyPair = await generateKeyPair()
  const publicKey = await formatKeyBase64(keyPair.publicKey)
  const privateKey = await formatKeyJWK(keyPair.privateKey)
  const publicKeyHash = await hash(publicKey)
  return {
    publicKey,
    privateKey,
    publicKeyHash,
  }
}

export const encrypt = async (key: CryptoKey, message: string) => {
  const encoder = new TextEncoder()
  const encodedMessage = encoder.encode(message)

  const cipherText = await window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, key, encodedMessage)

  return toBase64(cipherText)
}

const keyFromJWK = async (key: JsonWebKey) => {
  const importParams = {
    name: 'RSA-OAEP',
    hash: 'SHA-256',
  }
  return await window.crypto.subtle.importKey('jwk', key, importParams, false, ['decrypt'])
}

export const decrypt = async (key: JsonWebKey, cipherText: string) => {
  const buffer = fromBase64(cipherText)
  const importedKey = await keyFromJWK(key)
  const message = await window.crypto.subtle.decrypt({ name: 'RSA-OAEP' }, importedKey, buffer)
  return toBase64(message)
}
