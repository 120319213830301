import { yupResolver } from '@hookform/resolvers/yup'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import {
  createOpenErrorToast,
  Form,
  PasswordField,
  SubmitButton,
  Template,
  TermsOfUseLink,
  TextField,
} from 'components'
import { useSubmitAction } from 'hooks/useSubmitAction'
import { FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes'
import { signup as signupValidator } from 'services/validators'
import { oidcApi } from 'utils/apis'

type FormData = {
  email: string
  password: string
}

export const WebSignup: FC = () => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(signupValidator()) })

  const history = useHistory()

  const [onSubmit, isSubmitting] = useSubmitAction(
    useCallback(
      async ({ email, password }: FormData) => {
        const activeLocale = i18n.locale
        await oidcApi.signupStart({
          signupStartRequest: { email, password, activeLocale },
        })
        history.push(Routes.finishSignup)
      },
      [history],
    ),
    createOpenErrorToast('toast-error'),
  )

  const passwordFieldValue = watch('password')
  return (
    <Template
      title={t({ id: 'signupPage.title', message: 'Create your account' })}
      isLoading={isSubmitting}
      wrapContent
      titleBottomMargin={44}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <>
            <TermsOfUseLink />
            <SubmitButton disabled={isSubmitting}>
              <Trans id="signupPage.signupButton.label">Sign up</Trans>
            </SubmitButton>
          </>
        }
      >
        <TextField
          label={t({ id: 'signupPage.emailField.label', message: 'E-mail' })}
          placeholder="john.doe@gmail.com"
          type="email"
          {...register('email')}
          error={errors.email}
        />
        <PasswordField
          label={t({ id: 'signupPage.passwordField.label', message: 'Password' })}
          {...register('password')}
          value={passwordFieldValue}
          error={errors.password}
          showStrength
        />
      </Form>
    </Template>
  )
}
