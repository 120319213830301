import { keyframes } from '@stitches/react'
import { ReactComponent as Logo } from 'assets/logos/light.svg'
import { LanguageSelector } from 'components/input'
import { styled, theme } from 'styles/stitches.config'

const templateAnimation = keyframes({
  from: {
    transform: 'translateY(-32px)',
    opacity: 0.25,
  },
  to: {
    transform: 'translateY(0)',
    opacity: 1,
  },
})

export const TemplateWrapperStyled = styled('div', {
  width: '100%',
  minHeight: '100%',
  position: 'relative',
  display: 'flex',
  flexFlow: 'column nowrap',
})

export const LogoStyled = styled(Logo, {
  flex: '0 0',
  flexBasis: theme.space.t32,
  marginBottom: theme.space.t24,

  '@desktop': {
    marginBottom: theme.space.t44,
  },
})

export const ContentStyled = styled('div', {
  display: 'flex',
  flexFlow: 'column nowrap',
  width: '365px',
  flex: '1 1 auto',

  variants: {
    centered: {
      true: {
        justifyContent: 'center',
        flex: 1,
      },
    },
    wide: {
      true: {
        width: '480px',

        // TODO: may be arbitrary to definition below
        '@mobile': {
          width: '100%',
        },
      },
    },
    isInIframe: {
      true: {
        height: '100%', // may be arbitrary
      },
    },
  },

  '& > a': {
    marginBottom: theme.space.t8,
  },

  '@mobile': {
    width: '100%',
  },
})

export const SubtitleStyled = styled('h3', {
  fontWeight: theme.fontWeights.medium,
  color: theme.colors.textSecondary,
  fontSize: theme.fontSizes.base,
  textAlign: 'center',

  '@mobile': {
    width: '100%',
    textAlign: 'start',
  },
})

export const TitleStyled = styled('h1', {
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes.h1,
  lineHeight: theme.lineHeights.heading,
  color: theme.colors.textPrimary,

  padding: 0,
  width: '100%',

  [`& + ${SubtitleStyled}`]: {
    marginTop: theme.space.t12,
  },

  variants: {
    isInIframe: {
      true: {
        margin: `0 auto ${theme.space.t24} auto`,
        width: '100%',
      },
    },
  },

  '@desktop': {
    textAlign: 'center',
  },
})

export const TemplateStyled = styled('div', {
  display: 'flex',
  boxSizing: 'border-box',
  flexFlow: 'column nowrap',

  borderRadius: theme.space.t12,
  borderWidth: theme.borderWidths.hairline,
  borderStyle: 'solid',
  borderColor: theme.colors.strokeAlt,
  padding: `${theme.space.t44} 0`,
  alignItems: 'center',
  width: '640px',
  animation: `${templateAnimation.name} 0.5s ease-out`,
  backgroundColor: theme.colors.backgroundSurface,
  position: 'relative',
  margin: `${theme.space.t24} auto`,

  variants: {
    isInIframe: {
      true: {
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: theme.colors.backgroundPrimary, // TODO: backgroundColor: theme.colors.gray200, - When this happens?
      },
    },
    screenType: {
      desktop: {
        // on desktop with narrow window
        '@mobile': {
          padding: `${theme.space.t44} ${theme.space.t24}`,
          maxWidth: '25.5rem',

          // specific use case override
          [`${TitleStyled}`]: {
            textAlign: 'center',
          },
        },
      },
      mobile: {},
    },
  },

  '@mobile': {
    border: 0,
    paddingTop: theme.space.t32,
    paddingBottom: 0,
    paddingInline: theme.space.t16,
    width: '100%',
    minHeight: '100%',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'start',
  },
})

export const InnerLanguageSelectorStyled = styled(LanguageSelector, {
  '@mobile': {
    display: 'block',
    marginTop: 'auto',
    padding: `${theme.space.t24} 0`,
  },
  '@desktop': {
    display: 'none',
  },
})

export const OuterLanguageSelectorStyled = styled(LanguageSelector, {
  '@mobile': {
    display: 'none',
  },
  '@desktop': {
    position: 'relative',
    top: `-${theme.space.t24}`, // TODO: figure out what is this
  },
})
