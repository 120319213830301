import { i18n, Messages } from '@lingui/core'
import { fromNavigator, fromStorage, multipleDetect } from '@lingui/detect-locale'
import { messages as catalogAr } from 'assets/locales/ar/messages'
import { messages as catalogEn } from 'assets/locales/en/messages'
import { DEFAULT_FALLBACK_LOCALE, LOCALE_STORAGE, rtlLanguages } from 'const'
import { AvailableLocale } from 'oneid-common'

const messageCatalogs: Record<AvailableLocale, Messages> = {
  en: catalogEn as Messages,
  ar: catalogAr as Messages,
}

const setDirection = (locale: AvailableLocale) => {
  const dir = rtlLanguages.includes(locale) ? 'rtl' : 'ltr'
  document.getElementById('HTML')?.setAttribute('dir', dir)
}

export const initLanguage = () => {
  const detectedLocales = multipleDetect(fromStorage(LOCALE_STORAGE), fromNavigator())
  const locale =
    detectedLocales
      .map((detectedLocale) =>
        Object.values(AvailableLocale).find((availableLocale) => availableLocale === detectedLocale),
      )
      .find((availableLocale) => availableLocale !== undefined) ?? DEFAULT_FALLBACK_LOCALE

  setDirection(locale)

  i18n.load(locale, messageCatalogs[locale])
  i18n.activate(locale)
}

export const selectLocale = (locale: AvailableLocale) => {
  localStorage.setItem(LOCALE_STORAGE, locale)
  initLanguage()
}
