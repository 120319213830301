import cx from 'classnames'
import { forwardRef } from 'react'
import { ChangeHandler, FieldError } from 'react-hook-form'
import { CSS, styled, theme } from 'styles/stitches.config'
import { Icon } from '../icon'

export const IconStyled = styled(Icon, {
  cursor: 'pointer',
  opacity: '0.35',
  display: 'flex',
})

export const InputWrapperStyled = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  borderRadius: theme.space.t4,
  borderWidth: theme.borderWidths.hairline,
  borderStyle: 'solid',
  borderColor: theme.colors.strokeAlt,
  backgroundColor: theme.colors.backgroundSurface,
  padding: `${theme.space.t12} ${theme.space.t16}`,
  height: theme.space.t48,
  width: '100%',
  boxSizing: 'border-box',
  transition: '0.3s',

  '&:focus-within': {
    borderColor: theme.colors.strokeFocus,

    [`& ${IconStyled.selector}`]: {
      opacity: 1,
    },
  },

  [`& ${IconStyled.selector}:hover`]: {
    opacity: 1,
  },
})

export const TextFieldStyled = styled('div', {
  width: '100%',
  marginBottom: theme.space.t20,

  [`&.error ${InputWrapperStyled.selector}`]: {
    borderColor: theme.colors.statesErrorBase,
  },
})

export const LabelTextStyled = styled('span', {
  color: theme.colors.textSecondary,
  display: 'block',
  fontWeight: theme.fontWeights.semiBold,
  margin: `${theme.space.t4} 0`,
  fontSize: theme.fontSizes.t14,
})

export const InputStyled = styled('input', {
  flex: '1 1 0',
  border: '0',
  backgroundColor: theme.colors.backgroundSurface,
  padding: '0',
  height: theme.space.t24,
  lineHeight: theme.space.t24,
  fontSize: theme.fontSizes.base,
  width: '100%',
  boxSizing: 'border-box',
  outline: 'none',

  '&:-webkit-autofill': {
    '&:hover, &:focus, &:active': {
      boxShadow: '0 0 0 1000px #fff inset',
      ['-webkit-box-shadow']: '0 0 0 1000px #fff inset',
    },
  },
})

export const ErrorMessageStyled = styled('span', {
  display: 'block',
  color: theme.colors.statesErrorBase,
  margin: `${theme.space.t8} 0`,
  fontSize: theme.fontSizes.t12,
})

type Props = {
  autoFocus?: boolean
  type: string
  label: string
  name: string
  value?: string
  placeholder?: string
  error?: FieldError
  className?: string
  inputClass?: string
  inputWrapperClass?: string
  onChange?: ChangeHandler
  onBlur?: ChangeHandler
  maxLength?: number
  marginBottom?: CSS['marginBottom']
}

export const TextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      type,
      className,
      label,
      name,
      placeholder,
      error,
      onChange,
      onBlur,
      autoFocus,
      inputClass,
      inputWrapperClass,
      maxLength,
      marginBottom,
    },
    ref,
  ) => (
    <TextFieldStyled className={cx({ ['error']: !!error }, className)} css={{ marginBottom: marginBottom }}>
      <label>
        <LabelTextStyled>{label}</LabelTextStyled>
        <InputWrapperStyled className={cx(inputWrapperClass)}>
          <InputStyled
            ref={ref}
            name={name}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus={autoFocus}
            className={cx(inputClass)}
            maxLength={maxLength}
          />
        </InputWrapperStyled>
      </label>
      {error && <ErrorMessageStyled>{error.message}</ErrorMessageStyled>}
    </TextFieldStyled>
  ),
)
