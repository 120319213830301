import { OneOf } from '../types'
import { isObject, TypeCheckFunction } from './basic'

type EnumRecord<
  TRequired extends Record<string, string>,
  TOptional extends Record<string, string>,
  TValue = unknown,
> = Record<OneOf<TRequired>, TValue> & Partial<Record<OneOf<TOptional>, TValue>>

export const isRecordOfEnumRequiredAndOptionalKeys =
  <TRequired extends Record<string, string>, TOptional extends Record<string, string>>(
    requiredKeyEnumObject: TRequired,
    optionalKeyEnumObject: TOptional,
  ): TypeCheckFunction<EnumRecord<TRequired, TOptional>> =>
  (param: unknown): param is EnumRecord<TRequired, TOptional> => {
    const [enumRequired, enumOptional] = [
      Object.values(requiredKeyEnumObject),
      optionalKeyEnumObject ? Object.values(optionalKeyEnumObject) : [],
    ]
    return (
      isObject(param) &&
      enumRequired.every((key) => Object.keys(param).includes(key)) &&
      Object.keys(param).every((key) => enumRequired.includes(key) || enumOptional.includes(key))
    )
  }

export const isRecordOfEnumKeys = <TRequiredKey extends Record<string, string>>(
  requiredKeyEnumObject: TRequiredKey,
): TypeCheckFunction<Record<OneOf<TRequiredKey>, unknown>> =>
  isRecordOfEnumRequiredAndOptionalKeys<TRequiredKey, Record<string, never>>(requiredKeyEnumObject, {})

export const isRecordOfEnumKeysAndValues =
  <TRequired extends Record<string, string>, TOptional extends Record<string, string>, TValue>(
    recordCheck: TypeCheckFunction<EnumRecord<TRequired, TOptional>>,
    valueCheck: TypeCheckFunction<TValue>,
  ): TypeCheckFunction<EnumRecord<TRequired, TOptional, TValue>> =>
  (value: unknown): value is EnumRecord<TRequired, TOptional, TValue> =>
    recordCheck(value) && Object.values(value).every((val) => valueCheck(val))
