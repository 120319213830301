// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface ConfirmConsentResponse
 */
export interface ConfirmConsentResponse {
  /**
   *
   * @type {string}
   * @memberof ConfirmConsentResponse
   */
  location: string
  /**
   *
   * @type {string}
   * @memberof ConfirmConsentResponse
   */
  signInToken?: string
}

export function ConfirmConsentResponseFromJSON(json: any): ConfirmConsentResponse {
  return ConfirmConsentResponseFromJSONTyped(json, false)
}

export function ConfirmConsentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmConsentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    location: json['location'],
    signInToken: !exists(json, 'signInToken') ? undefined : json['signInToken'],
  }
}

export function ConfirmConsentResponseToJSON(value?: ConfirmConsentResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    location: value.location,
    signInToken: value.signInToken,
  }
}
