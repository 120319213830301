// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const SpecialConsentType = {
  CreateCertificate: 'createCertificate',
  Sign: 'sign',
} as const
export type SpecialConsentType = (typeof SpecialConsentType)[keyof typeof SpecialConsentType]

export function SpecialConsentTypeFromJSON(json: any): SpecialConsentType {
  return SpecialConsentTypeFromJSONTyped(json, false)
}

export function SpecialConsentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialConsentType {
  return json as SpecialConsentType
}

export function SpecialConsentTypeToJSON(value?: SpecialConsentType | null): any {
  return value as any
}
