import { Trans } from '@lingui/macro'
import { Form, Icon, LinkButton, Template } from 'components'
import { useQuery } from 'hooks/useQuery'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { callbackOIDC, getRedirectUriCallbackOIDC } from 'services/oidc'
import { Platform, getPlatform } from 'services/platform'
import { styled, theme } from 'styles/stitches.config'
import { navigateTo } from 'utils'

const MessageWrapperStyled = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: `${theme.space.t44} auto 0 auto`,
  flex: 1,
})

const MessageStyled = styled('div', {
  marginTop: theme.space.t16,
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes.h1,
  textAlign: 'center',
})

type ErrorScreenProps = { redirectUri: string }

const ErrorScreen: FC<ErrorScreenProps> = ({ redirectUri }) => (
  <Template wrapContent>
    <Form
      footer={
        <LinkButton href={redirectUri}>
          <Trans id="oidcCallback.button.continue">Continue</Trans>
        </LinkButton>
      }
    >
      <MessageWrapperStyled>
        <Icon name="ErrorBig" size="extraBig" />
        <MessageStyled>
          <Trans id="oidcCallback.message.error">Consent denied</Trans>
        </MessageStyled>
      </MessageWrapperStyled>
    </Form>
  </Template>
)

type SuccessScreenProps = {
  redirectUri: string
  clientName?: string
}

const SuccessScreen: FC<SuccessScreenProps> = ({ redirectUri, clientName }) => (
  <Template wrapContent>
    <Form
      footer={
        <LinkButton href={redirectUri}>
          <Trans id="oidcCallback.button.continue">Continue</Trans>
        </LinkButton>
      }
    >
      <MessageWrapperStyled>
        <Icon name="Success" size="extraBig" />
        <MessageStyled>
          {clientName ? (
            <Trans id="oidcCallback.message.successWithClientName">Successfully signed in to {clientName}</Trans>
          ) : (
            <Trans id="oidcCallback.message.success">Successfully signed in</Trans>
          )}
        </MessageStyled>
      </MessageWrapperStyled>
    </Form>
  </Template>
)

enum ErrorOIDCCallbackParams {
  Error = 'error',
}

// Extra params that are to be removed from the redirect URI before setting the new code
enum ExtraOIDCCallbackParams {
  ClientName = 'clientName',
  ShowRedirectButtonAndroid = 'showRedirectButtonAndroid',
  Key = 'key',
  PubKeySha = 'pubKeySha',
  Code = 'code',
}

export const OIDCCallback = () => {
  const query = useQuery()

  const [redirectUri, setRedirectUri] = useState<string>()
  const clientName = useMemo(() => query.get(ExtraOIDCCallbackParams.ClientName), [query])
  const isAutoRedirect = useMemo(
    () => getPlatform() !== Platform.Android || query.get(ExtraOIDCCallbackParams.ShowRedirectButtonAndroid) !== 'true',
    [query],
  )
  const hasError = useMemo(() => query.get(ErrorOIDCCallbackParams.Error) !== null, [query])

  const clearExtraParams = useCallback((params: URLSearchParams) => {
    // Remove all the processed params from the query, retain the rest
    Object.values(ExtraOIDCCallbackParams).forEach((extraParam) => {
      params.delete(extraParam)
    })
  }, [])

  useEffect(() => {
    const asyncHandleCallback = async () => {
      const key = query.get(ExtraOIDCCallbackParams.Key)
      const pubKeySha = query.get(ExtraOIDCCallbackParams.PubKeySha)
      const code = query.get(ExtraOIDCCallbackParams.Code)

      clearExtraParams(query)
      if (code && pubKeySha) {
        try {
          const callbackOIDCResult = await callbackOIDC({ code, key, pubKeySha })
          query.set(ExtraOIDCCallbackParams.Code, callbackOIDCResult)
        } catch (e) {
          console.error(e instanceof Error ? e.message : 'Unknown OIDC callback error')
          return
        }
      }

      const redirectUri = getRedirectUriCallbackOIDC(query)
      if (redirectUri) {
        setRedirectUri(redirectUri)
      }
    }

    void asyncHandleCallback()
  }, [clearExtraParams, query])

  useEffect(() => {
    if (redirectUri && isAutoRedirect) navigateTo(redirectUri)
  }, [redirectUri, isAutoRedirect])

  return redirectUri && !isAutoRedirect ? (
    hasError ? (
      <ErrorScreen redirectUri={redirectUri} />
    ) : (
      <SuccessScreen redirectUri={redirectUri} clientName={clientName ?? undefined} />
    )
  ) : null
}
