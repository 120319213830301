// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AbortConsentResponse,
  AbortConsentResponseFromJSON,
  AccessTokenSuccessfulResponse,
  AccessTokenSuccessfulResponseFromJSON,
  ConfirmConsentResponse,
  ConfirmConsentResponseFromJSON,
  ConsentStatusResponse,
  ConsentStatusResponseFromJSON,
  EkycResponse,
  EkycResponseFromJSON,
  Interaction,
  InteractionFromJSON,
  InteractionLoginBody,
  InteractionLoginBodyToJSON,
  InteractionPasswordLoginBody,
  InteractionPasswordLoginBodyToJSON,
  InteractionTokenLoginBody,
  InteractionTokenLoginBodyToJSON,
  SignupRequest,
  SignupRequestToJSON,
  SignupStartRequest,
  SignupStartRequestToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface AbortInteractionRequest {
  interactionId: string
}

export interface AuthRequest {
  scope: string
  responseType: AuthResponseTypeEnum
  clientId: string
  redirectUri: string
  prompt: AuthPromptEnum
  responseMode?: AuthResponseModeEnum
  state?: string
  codeChallenge?: string
  codeChallengeMethod?: AuthCodeChallengeMethodEnum
  nonce?: string
  maxAge?: number
  idTokenHint?: string
  loginHint?: string
  acrValues?: string
}

export interface AuthTokenLoginRequest {
  interactionId: string
  interactionLoginBody?: InteractionLoginBody
}

export interface ConfirmInteractionRequest {
  interactionId: string
}

export interface FetchConsentStatusRequest {
  interactionId: string
}

export interface FinishMobileLoginRequest {
  interactionId: string
}

export interface GetEkycInteractionRequest {
  interactionId: string
}

export interface GetInteractionRequest {
  interactionId: string
}

export interface LoginUserRequest {
  interactionId: string
  interactionPasswordLoginBody?: InteractionPasswordLoginBody
}

export interface SignupOperationRequest {
  interactionId: string
  signupRequest?: SignupRequest
}

export interface SignupStartOperationRequest {
  signupStartRequest: SignupStartRequest
}

export interface TokenRequest {
  grantType: TokenGrantTypeEnum
  clientId?: string
  code?: string
  codeVerifier?: string
  redirectUri?: string
  scope?: string
}

export interface TokenLoginUserRequest {
  interactionId: string
  interactionTokenLoginBody?: InteractionTokenLoginBody
}

/**
 *
 */
export class OidcControllerApi extends runtime.BaseAPI {
  /**
   */
  async abortInteractionRaw(
    requestParameters: AbortInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AbortConsentResponse>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling abortInteraction.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/abort`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => AbortConsentResponseFromJSON(jsonValue))
  }
  /**
   */
  async abortInteraction(
    requestParameters: AbortInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<AbortConsentResponse> {
    const response = await this.abortInteractionRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   * request authorization code
   */
  async authRaw(requestParameters: AuthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.scope === null || requestParameters.scope === undefined) {
      throw new runtime.RequiredError(
        'scope',
        'Required parameter requestParameters.scope was null or undefined when calling auth.',
      )
    }
    if (requestParameters.responseType === null || requestParameters.responseType === undefined) {
      throw new runtime.RequiredError(
        'responseType',
        'Required parameter requestParameters.responseType was null or undefined when calling auth.',
      )
    }
    if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
      throw new runtime.RequiredError(
        'clientId',
        'Required parameter requestParameters.clientId was null or undefined when calling auth.',
      )
    }
    if (requestParameters.redirectUri === null || requestParameters.redirectUri === undefined) {
      throw new runtime.RequiredError(
        'redirectUri',
        'Required parameter requestParameters.redirectUri was null or undefined when calling auth.',
      )
    }
    if (requestParameters.prompt === null || requestParameters.prompt === undefined) {
      throw new runtime.RequiredError(
        'prompt',
        'Required parameter requestParameters.prompt was null or undefined when calling auth.',
      )
    }
    const queryParameters: any = {}
    if (requestParameters.scope !== undefined) {
      queryParameters['scope'] = requestParameters.scope
    }
    if (requestParameters.responseType !== undefined) {
      queryParameters['response_type'] = requestParameters.responseType
    }
    if (requestParameters.responseMode !== undefined) {
      queryParameters['response_mode'] = requestParameters.responseMode
    }
    if (requestParameters.clientId !== undefined) {
      queryParameters['client_id'] = requestParameters.clientId
    }
    if (requestParameters.redirectUri !== undefined) {
      queryParameters['redirect_uri'] = requestParameters.redirectUri
    }
    if (requestParameters.state !== undefined) {
      queryParameters['state'] = requestParameters.state
    }
    if (requestParameters.codeChallenge !== undefined) {
      queryParameters['code_challenge'] = requestParameters.codeChallenge
    }
    if (requestParameters.codeChallengeMethod !== undefined) {
      queryParameters['code_challenge_method'] = requestParameters.codeChallengeMethod
    }
    if (requestParameters.nonce !== undefined) {
      queryParameters['nonce'] = requestParameters.nonce
    }
    if (requestParameters.prompt !== undefined) {
      queryParameters['prompt'] = requestParameters.prompt
    }
    if (requestParameters.maxAge !== undefined) {
      queryParameters['max_age'] = requestParameters.maxAge
    }
    if (requestParameters.idTokenHint !== undefined) {
      queryParameters['id_token_hint'] = requestParameters.idTokenHint
    }
    if (requestParameters.loginHint !== undefined) {
      queryParameters['login_hint'] = requestParameters.loginHint
    }
    if (requestParameters.acrValues !== undefined) {
      queryParameters['acr_values'] = requestParameters.acrValues
    }
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/auth`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   * request authorization code
   */
  async auth(requestParameters: AuthRequest, initOverrides?: RequestInit): Promise<void> {
    await this.authRaw(requestParameters, initOverrides)
  }
  /**
   */
  async authTokenLoginRaw(
    requestParameters: AuthTokenLoginRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling authTokenLogin.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/authTokenLogin`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionLoginBodyToJSON(requestParameters.interactionLoginBody),
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async authTokenLogin(requestParameters: AuthTokenLoginRequest, initOverrides?: RequestInit): Promise<void> {
    await this.authTokenLoginRaw(requestParameters, initOverrides)
  }
  /**
   */
  async confirmInteractionRaw(
    requestParameters: ConfirmInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ConfirmConsentResponse>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling confirmInteraction.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/confirm`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmConsentResponseFromJSON(jsonValue))
  }
  /**
   */
  async confirmInteraction(
    requestParameters: ConfirmInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<ConfirmConsentResponse> {
    const response = await this.confirmInteractionRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async fetchConsentStatusRaw(
    requestParameters: FetchConsentStatusRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ConsentStatusResponse>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling fetchConsentStatus.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/consent/{interactionId}/status`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => ConsentStatusResponseFromJSON(jsonValue))
  }
  /**
   */
  async fetchConsentStatus(
    requestParameters: FetchConsentStatusRequest,
    initOverrides?: RequestInit,
  ): Promise<ConsentStatusResponse> {
    const response = await this.fetchConsentStatusRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async finishMobileLoginRaw(
    requestParameters: FinishMobileLoginRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling finishMobileLogin.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/finishMobileLogin`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async finishMobileLogin(requestParameters: FinishMobileLoginRequest, initOverrides?: RequestInit): Promise<void> {
    await this.finishMobileLoginRaw(requestParameters, initOverrides)
  }
  /**
   */
  async getEkycInteractionRaw(
    requestParameters: GetEkycInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<EkycResponse>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling getEkycInteraction.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/ekyc`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => EkycResponseFromJSON(jsonValue))
  }
  /**
   */
  async getEkycInteraction(
    requestParameters: GetEkycInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<EkycResponse> {
    const response = await this.getEkycInteractionRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async getInteractionRaw(
    requestParameters: GetInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Interaction>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling getInteraction.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/detail`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => InteractionFromJSON(jsonValue))
  }
  /**
   */
  async getInteraction(requestParameters: GetInteractionRequest, initOverrides?: RequestInit): Promise<Interaction> {
    const response = await this.getInteractionRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async loginUserRaw(
    requestParameters: LoginUserRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling loginUser.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/login`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionPasswordLoginBodyToJSON(requestParameters.interactionPasswordLoginBody),
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async loginUser(requestParameters: LoginUserRequest, initOverrides?: RequestInit): Promise<void> {
    await this.loginUserRaw(requestParameters, initOverrides)
  }
  /**
   */
  async signupRaw(
    requestParameters: SignupOperationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling signup.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/signup`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SignupRequestToJSON(requestParameters.signupRequest),
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async signup(requestParameters: SignupOperationRequest, initOverrides?: RequestInit): Promise<void> {
    await this.signupRaw(requestParameters, initOverrides)
  }
  /**
   */
  async signupStartRaw(
    requestParameters: SignupStartOperationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.signupStartRequest === null || requestParameters.signupStartRequest === undefined) {
      throw new runtime.RequiredError(
        'signupStartRequest',
        'Required parameter requestParameters.signupStartRequest was null or undefined when calling signupStart.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])
      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/signup`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SignupStartRequestToJSON(requestParameters.signupStartRequest),
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async signupStart(requestParameters: SignupStartOperationRequest, initOverrides?: RequestInit): Promise<void> {
    await this.signupStartRaw(requestParameters, initOverrides)
  }
  /**
   * The token endpoint is used by the native app or spa to obtain an access token and an id token by presenting its authorization grant (and code verifier if PKCE). If not using PKCE authentication is REQUIRED.
   * request access token and id token with authorization code or refresh token
   */
  async tokenRaw(
    requestParameters: TokenRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AccessTokenSuccessfulResponse>> {
    if (requestParameters.grantType === null || requestParameters.grantType === undefined) {
      throw new runtime.RequiredError(
        'grantType',
        'Required parameter requestParameters.grantType was null or undefined when calling token.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])
      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'application/x-www-form-urlencoded' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)
    let formParams: { append(param: string, value: any): any }
    let useForm = false
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }
    if (requestParameters.clientId !== undefined) {
      formParams.append('client_id', requestParameters.clientId as any)
    }
    if (requestParameters.code !== undefined) {
      formParams.append('code', requestParameters.code as any)
    }
    if (requestParameters.codeVerifier !== undefined) {
      formParams.append('code_verifier', requestParameters.codeVerifier as any)
    }
    if (requestParameters.grantType !== undefined) {
      formParams.append('grant_type', requestParameters.grantType as any)
    }
    if (requestParameters.redirectUri !== undefined) {
      formParams.append('redirect_uri', requestParameters.redirectUri as any)
    }
    if (requestParameters.scope !== undefined) {
      formParams.append('scope', requestParameters.scope as any)
    }
    const response = await this.request(
      {
        path: `/token`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenSuccessfulResponseFromJSON(jsonValue))
  }
  /**
   * The token endpoint is used by the native app or spa to obtain an access token and an id token by presenting its authorization grant (and code verifier if PKCE). If not using PKCE authentication is REQUIRED.
   * request access token and id token with authorization code or refresh token
   */
  async token(requestParameters: TokenRequest, initOverrides?: RequestInit): Promise<AccessTokenSuccessfulResponse> {
    const response = await this.tokenRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async tokenLoginUserRaw(
    requestParameters: TokenLoginUserRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling tokenLoginUser.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/interaction/{interactionId}/tokenLogin`.replace(
          `{${'interactionId'}}`,
          encodeURIComponent(String(requestParameters.interactionId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionTokenLoginBodyToJSON(requestParameters.interactionTokenLoginBody),
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async tokenLoginUser(requestParameters: TokenLoginUserRequest, initOverrides?: RequestInit): Promise<void> {
    await this.tokenLoginUserRaw(requestParameters, initOverrides)
  }
}
/**
 * @export
 * @enum {string}
 */
export enum AuthResponseTypeEnum {
  Code = 'code',
}
/**
 * @export
 * @enum {string}
 */
export enum AuthPromptEnum {
  Login = 'login',
  Create = 'create',
}
/**
 * @export
 * @enum {string}
 */
export enum AuthResponseModeEnum {
  Query = 'query',
  Fragment = 'fragment',
}
/**
 * @export
 * @enum {string}
 */
export enum AuthCodeChallengeMethodEnum {
  S256 = 'S256',
  Plain = 'plain',
}
/**
 * @export
 * @enum {string}
 */
export enum TokenGrantTypeEnum {
  AuthorizationCode = 'authorization_code',
}
