import { transparentize } from 'polished'
import { styled, theme } from 'styles/stitches.config'

export const ToastContainer = styled('div', {
  boxSizing: 'border-box',
  position: 'relative',
  padding: theme.space.t16,
  borderRadius: theme.radii.t4,
  display: 'inline-flex',
  gap: theme.space.t16,
  color: theme.colors.textPrimaryAlt,
  '& svg': {
    color: 'inherit',
  },

  variants: {
    type: {
      success: {
        backgroundColor: theme.colors.statesSuccessBase,
      },
      warning: {
        backgroundColor: theme.colors.statesWarningBase,
      },
      error: {
        backgroundColor: theme.colors.statesErrorBase,
      },
      info: {
        backgroundColor: theme.colors.statesInfoBase,
      },
    },
  },
})

export const MessageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: '1',
  gap: theme.space.t4,
  alignItems: 'flex-start',
})

export const Title = styled('span', {
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes.base,
})

export const Description = styled('span', {
  fontSize: theme.fontSizes.t14,
})

export const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.sizes.t32,
  height: theme.sizes.t32,
  borderRadius: theme.radii.round,
  border: `${theme.borderWidths.xs} solid ${transparentize(0.9)(theme.colors.textPrimaryAlt.value)}`,
  backgroundColor: transparentize(0.8)(theme.colors.textPrimaryAlt.value),
  position: 'relative',
  flexShrink: 0,
})

export const CloseIconContainer = styled('button', {
  display: 'flex',
  alignItems: 'center',
  width: theme.sizes.t32,
  height: theme.sizes.t32,
  background: 'transparent',
  border: 'none',
  color: theme.colors.iconPrimaryAlt,
  cursor: 'pointer',
})
