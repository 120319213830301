import { DEFAULT_STORAGE_KEY_TTL } from 'const'
import { isJsonWebKey } from 'utils'
import { decrypt, generateFormattedKeyPairData } from './crypto'
import * as storage from './storage'

export const createAndStoreKeyPair = async () => {
  const { publicKey, publicKeyHash, privateKey } = await generateFormattedKeyPairData()

  storage.set(publicKeyHash, JSON.stringify(privateKey), DEFAULT_STORAGE_KEY_TTL)

  return publicKey
}

export const decryptKeyUsingStoredKeyPair = async (storageName: string, key: string) => {
  const privateKeyJson = storage.get(storageName)

  if (!privateKeyJson) {
    throw Error('Private key is missing!')
  }

  const privateKey: unknown = JSON.parse(privateKeyJson)
  if (isJsonWebKey(privateKey)) {
    const encryptionKey = await decrypt(privateKey, key)

    storage.remove(storageName)

    return encryptionKey
  }
  return null
}
