import { ButtonHTMLAttributes, FC } from 'react'
import { styled, theme } from 'styles/stitches.config'

export const ButtonStyled = styled('button', {
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes.base,
  cursor: 'pointer',
  outline: 'none',
  transition: '0.3s box-shadow',
  width: '100%',
  padding: `${theme.space.t12} ${theme.space.t16}`,
  borderRadius: theme.space.t24,
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  boxSizing: 'border-box',

  '&:hover': {
    textDecoration: 'none',
  },

  '& svg': {
    marginRight: theme.space.t8,
    flex: '0 0 auto',
  },

  defaultVariants: {
    variant: 'primary',
  },

  borderWidth: theme.borderWidths.hairline,
  borderStyle: 'solid',

  variants: {
    variant: {
      primary: {
        background: theme.colors.buttonBackgroundPrimaryDefault,
        borderColor: theme.colors.buttonBackgroundPrimaryDefault,
        color: theme.colors.buttonContentDefault,

        '&:hover': {
          background: theme.colors.buttonBackgroundPrimaryHover,
        },
      },
      secondary: {
        backgroundColor: theme.colors.buttonBackgroundSecondaryDefault,
        borderColor: theme.colors.strokeAlt,
        color: theme.colors.buttonContentAlt,

        '&:hover': {
          background: theme.colors.buttonBackgroundSecondaryHover,
        },
      },
    },
    disabledStyle: {
      true: {
        background: theme.colors.buttonBackgroundDisabled,
        color: theme.colors.buttonContentDisabled,
        pointerEvents: 'none',
      },
    },
  },

  compoundVariants: [
    {
      variant: 'primary',
      disabledStyle: true,
      css: {
        borderColor: 'transparent',
      },
    },
    {
      variant: 'secondary',
      disabledStyle: true,
      css: {
        borderColor: theme.colors.buttonContentDisabled,
      },
    },
  ],
})

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary'
}

export const Button: FC<ButtonProps> = (props) => {
  return <ButtonStyled disabledStyle={props.disabled} {...props} />
}
