// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InteractionLoginBody
 */
export interface InteractionLoginBody {
  /**
   * Whether to issue sign-in token after consent
   * @type {boolean}
   * @memberof InteractionLoginBody
   */
  issueToken: boolean
  /**
   * Public key generated in user's browser
   * @type {string}
   * @memberof InteractionLoginBody
   */
  publicKey: string
}

export function InteractionLoginBodyFromJSON(json: any): InteractionLoginBody {
  return InteractionLoginBodyFromJSONTyped(json, false)
}

export function InteractionLoginBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteractionLoginBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    issueToken: json['issueToken'],
    publicKey: json['publicKey'],
  }
}

export function InteractionLoginBodyToJSON(value?: InteractionLoginBody | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    issueToken: value.issueToken,
    publicKey: value.publicKey,
  }
}
