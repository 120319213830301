import { useQuery } from 'hooks/useQuery'
import { isNull } from 'oneid-common'
import { FC } from 'react'
import { generatePath, Redirect } from 'react-router-dom'
import { Routes } from 'routes'

export const MobileNotCapturedRedirect: FC = () => {
  const query = useQuery()
  const interactionId = query.get('interactionId')
  const search = new URLSearchParams({ mobileRedirect: 'true' }).toString()
  if (!isNull(interactionId)) {
    return <Redirect to={{ pathname: generatePath(Routes.mobileLoginIntro, { uid: interactionId }), search }} />
  }
  return <Redirect to={{ pathname: generatePath(Routes.mobileLoginIntro), search }} />
}
