import { Trans } from '@lingui/macro'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { DecodedToken } from 'services/tokens'
import { TokenLoginSubmitData } from 'types'
import { Icon as IconLocal } from '../icon'
import { StyledTokenLoginForm } from './TokenLoginForm.styled'

const { Button, Email, Icon, IconWrapper, Note, TextWrapper, TokenLoginFormBody } = StyledTokenLoginForm

type TokenLoginFormProps = {
  onSubmit: (data: TokenLoginSubmitData) => Promise<void>
  tokens: DecodedToken[]
  passwordLoginRoute: string
}

export const TokenLoginForm: FC<TokenLoginFormProps> = ({ passwordLoginRoute, onSubmit, tokens }) => (
  <>
    <TokenLoginFormBody>
      {tokens.map(({ sub: email, exp: expiration, raw: token }) => (
        <li key={email}>
          <Button onClick={() => onSubmit({ token, email })} data-qa="token-login-button">
            <IconWrapper>
              <IconLocal name="Avatar" size="t40" />
            </IconWrapper>
            <TextWrapper>
              <Email data-qa="token-login-email">{email}</Email>
              {expiration * 1000 < Date.now() && <Note data-qa="token-login-signed-out">Signed out</Note>}
            </TextWrapper>
            <Icon name="CaretRightIcon" size="t32" color="iconPrimary" />
          </Button>
        </li>
      ))}
    </TokenLoginFormBody>
    <Link to={passwordLoginRoute}>
      <Trans id="loginForm.useAnotherAccountLink">Use another account</Trans>
    </Link>
  </>
)
