import { FC } from 'react'
import { getPlatform, Platform } from 'services/platform'

const storeLink = {
  [Platform.Android]: (
    <a href="https://play.google.com/store/apps/details?id=com.public2.oneid">
      <img src="/google-play-badge.png" alt="Google Play" />
    </a>
  ),
  [Platform.Ios]: (
    <a href="https://apps.apple.com/app/beyon-oneid/id1592996533">
      <img src="/app-store-badge.png" alt="App Store" />
    </a>
  ),
}

export const StoreLink: FC = () => {
  const detectedPlatform = getPlatform()
  if (detectedPlatform) return storeLink[detectedPlatform]
  return (
    <>
      {storeLink[Platform.Android]}
      {storeLink[Platform.Ios]}
    </>
  )
}
