import { t } from '@lingui/macro'
import { branding } from 'branding'
import { openErrorToast } from 'components'
import { useApiError } from 'hooks/useApiError'
import { useInteraction } from 'hooks/useInteraction'
import { useIsExpiredDate } from 'hooks/useIsExpiredDate'
import { useQuery } from 'hooks/useQuery'
import { ErrorBody, ErrorCode } from 'oneid-api-frontend'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { Routes } from 'routes'
import { isErrorResponse } from 'services/errors'
import {
  getMobileUniversalLink,
  redirectWithFinishMobileLoginOIDC,
  redirectWithPasswordLoginOIDC,
  redirectWithTokenLoginOIDC,
  useOidcConsent,
} from 'services/oidc'
import { createAndStoreKeyPair } from 'services/security'
import { DecodedToken, useTokens } from 'services/tokens'
import { LoginFormData, TokenLoginSubmitData, UidPathParams } from 'types'
import { DEFAULT_ERROR } from 'utils/apis'

export const useOidcLoginController = () => {
  const { interaction, error } = useInteraction()
  const [isSubmitting, setIsSubmitting] = useState<boolean>()

  const isExpired = useIsExpiredDate(interaction?.expiresAt)

  const handleSubmit = async ({ email, password, remember }: LoginFormData) => {
    if (!interaction) return

    setIsSubmitting(true)
    try {
      await redirectWithPasswordLoginOIDC(email, password, interaction.id, remember)
    } finally {
      setIsSubmitting(false)
    }
  }

  return { interaction, error, isExpired, isSubmitting, handleSubmit }
}

/**
 * Compares two tokens first by the email domain, then by the whole email.
 * It's meant to be used in the Array.sort() method
 */
const compareTokens = (t1: DecodedToken, t2: DecodedToken): number => {
  const getDomain = (email: string) => email.split('@')[1] ?? ''

  const email1 = t1.sub
  const domain1 = getDomain(email1)

  const email2 = t2.sub
  const domain2 = getDomain(email2)

  if (domain1 === domain2) return email1 > email2 ? 1 : -1
  return domain1 > domain2 ? 1 : -1
}

export const useOidcTokenLoginController = () => {
  const history = useHistory()
  const { interaction, error } = useInteraction()
  const [isSubmitting, setIsSubmitting] = useState<boolean>()
  const match = useRouteMatch<UidPathParams>()
  const passwordRoute = generatePath(Routes.passwordLogin, { uid: match.params.uid })
  const { tokens, removeToken } = useTokens()
  const [requestError, setRequestError] = useState<ErrorBody | undefined>()

  const isExpired = useIsExpiredDate(interaction?.expiresAt)

  const handleSubmit = useCallback(
    async ({ token, email }: TokenLoginSubmitData) => {
      if (!interaction || isSubmitting) return
      setIsSubmitting(true)
      try {
        await redirectWithTokenLoginOIDC(token, interaction.id)
      } catch (err: unknown) {
        if (isErrorResponse(err)) {
          if (err.error.code === ErrorCode.InvalidToken) {
            history.push(`${passwordRoute}?email=${encodeURIComponent(email)}`)
            removeToken(token)
          } else {
            setRequestError(err.error)
          }
        } else {
          setRequestError(DEFAULT_ERROR)
        }
      } finally {
        setIsSubmitting(false)
      }
    },
    [interaction, isSubmitting, history, passwordRoute, removeToken],
  )

  const sortedTokens = useMemo(() => tokens.slice().sort(compareTokens), [tokens])

  return {
    interaction,
    error,
    isExpired,
    isSubmitting,
    handleSubmit,
    tokens: sortedTokens,
    passwordRoute,
    requestError,
  }
}

const MOBILE_REDIRCT_PARAM = 'mobileRedirect'

export const useMobileLoginController = () => {
  const query = useQuery()
  const didFailOpeningApp = !!query.get(MOBILE_REDIRCT_PARAM)
  const history = useHistory()

  useEffect(() => {
    if (didFailOpeningApp) {
      const modifiedSearchParams = new URLSearchParams(query.toString())
      modifiedSearchParams.delete(MOBILE_REDIRCT_PARAM)
      history.replace({
        search: modifiedSearchParams.toString(),
      })
      openErrorToast(
        t({
          id: 'mobileLogin.noAppInstalled',
          message: `To continue you need to install the ${branding.brand} app`,
        }),
      )
    }
  }, [didFailOpeningApp, history, query])

  const { interaction, error, interactionUid } = useInteraction()
  const isExpired = useIsExpiredDate(interaction?.expiresAt)
  const [apiError, setApiError] = useApiError()

  const { consent } = useOidcConsent(interactionUid, true)

  const finishLogin = useCallback(async () => {
    try {
      await redirectWithFinishMobileLoginOIDC(interactionUid)
    } catch (err: unknown) {
      setApiError(err)
    }
  }, [interactionUid, setApiError])

  useEffect(() => {
    if (consent?.consentStatus) {
      void finishLogin()
    }
  }, [consent?.consentStatus, finishLogin])

  const [publicKey, setPublicKey] = useState<string>()

  const mobileUniversalLink = interaction && publicKey && getMobileUniversalLink(interactionUid, publicKey)

  useEffect(() => {
    const setupKeyPair = async () => {
      const newKey = await createAndStoreKeyPair()
      setPublicKey(newKey)
    }
    void setupKeyPair()
  }, [])

  return { error: error ?? apiError, isExpired, mobileUniversalLink, interaction }
}
