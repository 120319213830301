import { Form, OneXUIToast, SubmitButton, Template } from 'components'
import { DEFAULT_CLIENT, DEFAULT_CODE_VERIFIER } from 'const'
import jwtDecode from 'jwt-decode'
import { AccessTokenSuccessfulResponse, TokenGrantTypeEnum } from 'oneid-api-frontend'
import { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Routes } from 'routes'
import { getRedirectUri } from 'services/oidc'
import { styled, theme } from 'styles/stitches.config'
import { oidcApi } from 'utils/apis'

const UserInfoTemplateStyled = styled(Template, {
  width: '75%',
  maxWidth: '1200px',

  '& pre': {
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: '0',
  },

  '@mobile': {
    width: '100%',
  },
})

const FormStyled = styled(Form, {
  width: '100%',
})

const ContentStyled = styled('div', {
  '& > div': {
    marginBottom: theme.space.t20,
  },

  '& strong': {
    color: theme.colors.textPrimaryAlt,
  },
})

const useQuery = () => {
  const location = useLocation()
  return new URLSearchParams(location.hash.length > 1 ? location.hash.substring(1) : location.search)
}

function parseJwt(token: string) {
  const payload = jwtDecode(token)
  const header = jwtDecode(token, { header: true })
  return { payload, header }
}

const fetchIdToken = async (code: string) =>
  await oidcApi.token(
    {
      code,
      grantType: TokenGrantTypeEnum.AuthorizationCode,
      clientId: DEFAULT_CLIENT,
      redirectUri: getRedirectUri(),
      codeVerifier: DEFAULT_CODE_VERIFIER,
    },
    {
      cache: 'no-cache',
    },
  )

export const UserInfo: FC = () => {
  const [idToken, setIdToken] = useState<AccessTokenSuccessfulResponse | null>(null)
  const query = useQuery()

  useEffect(() => {
    if (idToken || !query.get('code')) {
      return
    }

    const asyncFetchIdToken = async () => {
      const code = query.get('code')
      if (code) {
        const token = await fetchIdToken(code)

        setIdToken(token)
      }
    }

    void asyncFetchIdToken()
  }, [query, idToken, setIdToken])

  const jwt = idToken?.idToken && parseJwt(idToken.idToken)

  return (
    <UserInfoTemplateStyled title="Testing page for fetching idToken" wrapContent titleBottomMargin={44}>
      <FormStyled
        footer={
          <Link to={Routes.index}>
            <SubmitButton>Back to login</SubmitButton>
          </Link>
        }
      >
        {!query.get('error') && (
          <ContentStyled data-qa="token-data">
            <div>
              <strong>Code:</strong>
              <pre data-qa="token-code">{query.get('code')}</pre>
            </div>
            <div>
              <strong>accessToken:</strong>
              <pre data-qa="access-token">{idToken?.accessToken}</pre>
            </div>
            <div>
              idToken header:
              <pre data-qa="token-header">{jwt && JSON.stringify(jwt.header, null, 2)}</pre>
            </div>
            <div>
              idToken payload:
              <pre data-qa="token-payload">{jwt && JSON.stringify(jwt.payload, null, 2)}</pre>
            </div>
            <div>
              <strong>state:</strong>
              <pre data-qa="token-state">{query.get('state')}</pre>
            </div>
          </ContentStyled>
        )}
        {query.get('error') && (
          <OneXUIToast.ToastMessage
            type="error"
            title={query.get('error')}
            message={query.get('error_description')}
            data-qa="error-toast"
            width="100%"
          />
        )}
      </FormStyled>
    </UserInfoTemplateStyled>
  )
}
