// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ConsentStatus, ConsentStatusFromJSON, ConsentStatusToJSON, Status, StatusFromJSON, StatusToJSON } from './'

/**
 *
 * @export
 * @interface ConsentStatusResponse
 */
export interface ConsentStatusResponse {
  /**
   *
   * @type {ConsentStatus}
   * @memberof ConsentStatusResponse
   */
  consentStatus?: ConsentStatus
  /**
   *
   * @type {Status}
   * @memberof ConsentStatusResponse
   */
  status?: Status
}

export function ConsentStatusResponseFromJSON(json: any): ConsentStatusResponse {
  return ConsentStatusResponseFromJSONTyped(json, false)
}

export function ConsentStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentStatusResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    consentStatus: !exists(json, 'consentStatus') ? undefined : ConsentStatusFromJSON(json['consentStatus']),
    status: !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
  }
}

export function ConsentStatusResponseToJSON(value?: ConsentStatusResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    consentStatus: ConsentStatusToJSON(value.consentStatus),
    status: StatusToJSON(value.status),
  }
}
