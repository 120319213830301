import { config } from 'config'
import { useRecursiveTimeout } from 'hooks/useRecursiveTimeout'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { refetchEKYCInteractionUrl } from 'services/ekyc'
import { UidPathParams } from 'types'
import { navigateTo } from 'utils'
import { FinishWebSignup } from '../FinishWebSignup'

export const EKYC: FC = () => {
  const { uid } = useParams<UidPathParams>()

  useRecursiveTimeout(async () => {
    try {
      if (!uid) {
        return
      }
      const url = await refetchEKYCInteractionUrl(uid)
      if (url) {
        navigateTo(url)
      }
    } catch {}
  }, config.ekycPollingInterval)

  return <FinishWebSignup />
}
