import { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { ButtonStyled } from './Button'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export interface LinkButtonProps extends Optional<LinkProps, 'to'> {
  variant?: 'primary' | 'secondary'
  href?: string
  disabled?: boolean
}

export const LinkButton: FC<LinkButtonProps> = ({ className, variant = 'primary', to, disabled, ...rest }) =>
  to ? (
    <ButtonStyled as={Link} variant={variant} to={to} {...rest} />
  ) : (
    <ButtonStyled as="a" variant={variant} {...rest} />
  )
