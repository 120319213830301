import { differenceInMilliseconds, isBefore } from 'date-fns'
import { useEffect, useState } from 'react'

export const useIsExpiredDate = (date?: Date) => {
  const [isExpired, setIsExpired] = useState<boolean>()

  useEffect(() => {
    if (!date) return () => undefined

    setIsExpired(isBefore(date, new Date()))
    const timeout = setTimeout(
      () => {
        setIsExpired(true)
      },
      differenceInMilliseconds(date, new Date()),
    )

    return () => {
      clearTimeout(timeout)
    }
  }, [date])

  return isExpired
}
