import { isNotNil } from '../typechecks/basic'
import { getObjectEntries } from '../typedObjectUtils'
import { OneOf } from '../types'

/**
 * Remap values using Object entries and fromEntries, forced to use key typing
 * @param o original object
 * @param remap function to remap values
 */
export const transformValues = <TKey extends PropertyKey, TOldValue, TNewValue>(
  o: Record<TKey, TOldValue>,
  remap: (from: TOldValue) => TNewValue,
): Record<TKey, TNewValue> =>
  Object.fromEntries(Object.entries<TOldValue>(o).map(([key, value]) => [key, remap(value)] as const)) as Record<
    TKey,
    TNewValue
  >

/**
 * Serially remap with await, using Object entries.
 * @param o original object
 * @param remap function to remap values
 * @return Record<string, remappedValue>
 */
export const transformValuesSerial = async <TKey extends PropertyKey, TOldValue, TNewValue>(
  o: Record<TKey, TOldValue>,
  remap: (value: TOldValue) => Promise<TNewValue>,
): Promise<Record<TKey, TNewValue>> => {
  const transformedValues: Partial<Record<TKey, TNewValue>> = {}
  for (const [key, value] of Object.entries(o)) {
    transformedValues[key as TKey] = await remap(value as TOldValue)
  }
  return transformedValues as Record<TKey, TNewValue>
}

/**
 * Make an object with enum as a key and values from a map function taking enum values
 * @param enumObj - object from which the values will be used as keys
 * @param mapFunction - function that is taking enum as an argument.
 ** Result will be assigned as a value to given key in resulting object.
 */
export const getEnumObjectMap = <TEnum extends Record<string, string>, K>(
  enumObj: TEnum,
  mapFunction: (enumObj: OneOf<TEnum>) => K,
): Record<OneOf<TEnum>, K> =>
  Object.fromEntries(Object.values(enumObj).map((e) => [e, mapFunction(e as OneOf<TEnum>)])) as Record<OneOf<TEnum>, K>

/**
 * Pick properties from object that are not null or undefined
 * @param o - Record<string, string | undefined | null>
 * @return res - Record<string, string>
 */
export const pickPropertiesNotNil = (o: Record<string, string | undefined | null>): Record<string, string> =>
  Object.fromEntries(getObjectEntries(o).filter(([, value]) => isNotNil(value)) as Array<[string, string]>)

/**
 * Filter key/value pairs with null value from partial object
 * @param o - partial object to filter
 */
export const filterNilValues = <T extends object>(o: Partial<T>): Partial<T> =>
  Object.fromEntries(Object.entries(o).filter(([, value]) => value !== undefined && value !== null)) as Partial<T>

/**
 * Create an object from entries
 *
 * Uses Object.fromEntries and forces keys type
 */
export const fromEntries = <K extends PropertyKey, T>(entries: Iterable<readonly [K, T]>): { [key in K]: T } =>
  Object.fromEntries(entries) as { [key in K]: T }
