import { useCallback, useEffect, useState } from 'react'

export interface Session {
  sessionId: string
  expiration: Date
  completed?: true
}

export interface SessionContextProps<T extends Session> {
  session?: T
  startSession: (session: T) => void
  completeSession: () => void
  clearSession: () => void
}

export function useSession<T extends Session>() {
  const [session, setSession] = useState<T>()

  const startSession = setSession
  const clearSession = useCallback(() => setSession(undefined), [])
  const completeSession = useCallback(() => {
    if (!session) throw new Error('No session to confirm')
    setSession({ ...session, completed: true })
  }, [session])

  // Watches for session expiration
  useEffect(() => {
    if (!session || session.completed) return
    const timeout = setTimeout(() => {
      clearSession()
    }, session.expiration.getTime() - Date.now())
    return () => clearTimeout(timeout)
  }, [session, clearSession])

  return { session, startSession, clearSession, completeSession }
}
