export const enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
}

export const getPlatform = () => {
  // Simple platform detection based on https://stackoverflow.com/a/21742107
  const userAgent: string = navigator.userAgent || navigator.vendor
  return /android/i.test(userAgent) ? Platform.Android : /iPad|iPhone|iPod/.test(userAgent) ? Platform.Ios : null
}
