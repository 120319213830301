// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface SignupStartRequest
 */
export interface SignupStartRequest {
  /**
   *
   * @type {string}
   * @memberof SignupStartRequest
   */
  activeLocale?: string
  /**
   *
   * @type {string}
   * @memberof SignupStartRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof SignupStartRequest
   */
  password: string
}

export function SignupStartRequestFromJSON(json: any): SignupStartRequest {
  return SignupStartRequestFromJSONTyped(json, false)
}

export function SignupStartRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignupStartRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activeLocale: !exists(json, 'activeLocale') ? undefined : json['activeLocale'],
    email: json['email'],
    password: json['password'],
  }
}

export function SignupStartRequestToJSON(value?: SignupStartRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activeLocale: value.activeLocale,
    email: value.email,
    password: value.password,
  }
}
