// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ConsentStatus, ConsentStatusFromJSON, ConsentStatusToJSON, Status, StatusFromJSON, StatusToJSON } from './'

/**
 *
 * @export
 * @interface InteractionStatus
 */
export interface InteractionStatus {
  /**
   *
   * @type {ConsentStatus}
   * @memberof InteractionStatus
   */
  consentStatus?: ConsentStatus
  /**
   *
   * @type {Date}
   * @memberof InteractionStatus
   */
  expiresAt: Date
  /**
   *
   * @type {string}
   * @memberof InteractionStatus
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof InteractionStatus
   */
  pubKeySha?: string
  /**
   *
   * @type {Status}
   * @memberof InteractionStatus
   */
  status?: Status
}

export function InteractionStatusFromJSON(json: any): InteractionStatus {
  return InteractionStatusFromJSONTyped(json, false)
}

export function InteractionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteractionStatus {
  if (json === undefined || json === null) {
    return json
  }
  return {
    consentStatus: !exists(json, 'consentStatus') ? undefined : ConsentStatusFromJSON(json['consentStatus']),
    expiresAt: new Date(json['expiresAt']),
    key: !exists(json, 'key') ? undefined : json['key'],
    pubKeySha: !exists(json, 'pubKeySha') ? undefined : json['pubKeySha'],
    status: !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
  }
}

export function InteractionStatusToJSON(value?: InteractionStatus | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    consentStatus: ConsentStatusToJSON(value.consentStatus),
    expiresAt: value.expiresAt.toISOString(),
    key: value.key,
    pubKeySha: value.pubKeySha,
    status: StatusToJSON(value.status),
  }
}
