import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Routes } from 'routes'
import { SamlPasswordLogin } from './SamlPasswordLogin'
import { SamlTokenLogin } from './SamlTokenLogin'

export const SamlLogin: FC = () => (
  <Switch>
    <Route path={Routes.samlLoginToken}>
      <SamlTokenLogin />
    </Route>
    <Route path={Routes.samlLoginPassword}>
      <SamlPasswordLogin />
    </Route>
    <Redirect to={Routes.samlLoginToken} from={Routes.samlLogin} />
  </Switch>
)
