// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InteractionTokenLoginBody
 */
export interface InteractionTokenLoginBody {
  /**
   * Whether to issue sign-in token after consent
   * @type {boolean}
   * @memberof InteractionTokenLoginBody
   */
  issueToken: boolean
  /**
   * Public key generated in user's browser
   * @type {string}
   * @memberof InteractionTokenLoginBody
   */
  publicKey: string
  /**
   * SignIn token
   * @type {string}
   * @memberof InteractionTokenLoginBody
   */
  signInToken: string
}

export function InteractionTokenLoginBodyFromJSON(json: any): InteractionTokenLoginBody {
  return InteractionTokenLoginBodyFromJSONTyped(json, false)
}

export function InteractionTokenLoginBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InteractionTokenLoginBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    issueToken: json['issueToken'],
    publicKey: json['publicKey'],
    signInToken: json['signInToken'],
  }
}

export function InteractionTokenLoginBodyToJSON(value?: InteractionTokenLoginBody | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    issueToken: value.issueToken,
    publicKey: value.publicKey,
    signInToken: value.signInToken,
  }
}
