// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InteractionPasswordLoginBody
 */
export interface InteractionPasswordLoginBody {
  /**
   * Whether to issue sign-in token after consent
   * @type {boolean}
   * @memberof InteractionPasswordLoginBody
   */
  issueToken: boolean
  /**
   * Public key generated in user's browser
   * @type {string}
   * @memberof InteractionPasswordLoginBody
   */
  publicKey: string
  /**
   * User e-mail
   * @type {string}
   * @memberof InteractionPasswordLoginBody
   */
  email: string
  /**
   * User password
   * @type {string}
   * @memberof InteractionPasswordLoginBody
   */
  password: string
}

export function InteractionPasswordLoginBodyFromJSON(json: any): InteractionPasswordLoginBody {
  return InteractionPasswordLoginBodyFromJSONTyped(json, false)
}

export function InteractionPasswordLoginBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InteractionPasswordLoginBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    issueToken: json['issueToken'],
    publicKey: json['publicKey'],
    email: json['email'],
    password: json['password'],
  }
}

export function InteractionPasswordLoginBodyToJSON(value?: InteractionPasswordLoginBody | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    issueToken: value.issueToken,
    publicKey: value.publicKey,
    email: value.email,
    password: value.password,
  }
}
