/**
 * Get object keys as keyof type array
 * @param obj
 */
export const getObjectKeys = <T extends Record<string, unknown>>(obj: T): Array<keyof T> =>
  Object.keys(obj) as Array<keyof T>

/**
 * Get object entries with key type
 * @param obj
 */

export const getObjectEntries = <TKey extends PropertyKey, TValue>(
  obj: { [s in TKey]?: TValue } | ArrayLike<TValue>,
): Array<[TKey, TValue]> => Object.entries(obj) as Array<[TKey, TValue]>
