// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Client, ClientFromJSON, ClientToJSON, User, UserFromJSON, UserToJSON } from './'

/**
 *
 * @export
 * @interface InteractionDetail
 */
export interface InteractionDetail {
  /**
   *
   * @type {Client}
   * @memberof InteractionDetail
   */
  client: Client
  /**
   *
   * @type {Date}
   * @memberof InteractionDetail
   */
  expiresAt: Date
  /**
   *
   * @type {string}
   * @memberof InteractionDetail
   */
  id: string
  /**
   *
   * @type {User}
   * @memberof InteractionDetail
   */
  user?: User
}

export function InteractionDetailFromJSON(json: any): InteractionDetail {
  return InteractionDetailFromJSONTyped(json, false)
}

export function InteractionDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteractionDetail {
  if (json === undefined || json === null) {
    return json
  }
  return {
    client: ClientFromJSON(json['client']),
    expiresAt: new Date(json['expiresAt']),
    id: json['id'],
    user: !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
  }
}

export function InteractionDetailToJSON(value?: InteractionDetail | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    client: ClientToJSON(value.client),
    expiresAt: value.expiresAt.toISOString(),
    id: value.id,
    user: UserToJSON(value.user),
  }
}
