import cx from 'classnames'
import { FC, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import {
  DelayStyled,
  IconStyled,
  OVERLAY_ANIMATION_DURATION,
  SpinnerStyled,
  SpinnerVisualStyled,
  SpinnerWrapperStyled,
  TitleStyled,
} from './Spinner.styled'

interface SpinnerProps {
  isLoading?: boolean
  className?: string
  title?: string
}

const overlayRoot = document.getElementById('overlay-root') as HTMLDivElement

export const Spinner: FC<SpinnerProps> = ({ title, className, isLoading }) => {
  const ref = useRef(null)

  return createPortal(
    <CSSTransition nodeRef={ref} in={isLoading} timeout={OVERLAY_ANIMATION_DURATION} unmountOnExit mountOnEnter>
      <SpinnerWrapperStyled ref={ref}>
        <SpinnerStyled className={cx(className)}>
          <SpinnerVisualStyled>
            <DelayStyled className="delayBig" />
            <DelayStyled className="delay" />
            <DelayStyled className="delaySmall" />
            <DelayStyled />
            <IconStyled name="Lock" />
          </SpinnerVisualStyled>
          {title && <TitleStyled>{title}</TitleStyled>}
        </SpinnerStyled>
      </SpinnerWrapperStyled>
    </CSSTransition>,
    overlayRoot,
  )
}
