// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const Status = {
  Ok: 'OK',
  Error: 'ERROR',
} as const
export type Status = (typeof Status)[keyof typeof Status]

export function StatusFromJSON(json: any): Status {
  return StatusFromJSONTyped(json, false)
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
  return json as Status
}

export function StatusToJSON(value?: Status | null): any {
  return value as any
}
