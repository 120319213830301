// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AbortInteraction
 */
export interface AbortInteraction {
  /**
   *
   * @type {string}
   * @memberof AbortInteraction
   */
  context: string
  /**
   *
   * @type {string}
   * @memberof AbortInteraction
   */
  entityEndpoint: string
  /**
   *
   * @type {string}
   * @memberof AbortInteraction
   */
  type: string
}

export function AbortInteractionFromJSON(json: any): AbortInteraction {
  return AbortInteractionFromJSONTyped(json, false)
}

export function AbortInteractionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbortInteraction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    context: json['context'],
    entityEndpoint: json['entityEndpoint'],
    type: json['type'],
  }
}

export function AbortInteractionToJSON(value?: AbortInteraction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    context: value.context,
    entityEndpoint: value.entityEndpoint,
    type: value.type,
  }
}
