import Stitches, { createStitches } from '@stitches/react'
import { branding } from 'branding'

import {
  COMMON_PALETTE,
  createDefaultTokenizedPalette,
  flattenPalette,
  themeConstants,
} from '@beyon-connect/onex-ui-web'
import { SPACE } from './constants'

const { BORDER_WIDTHS, FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS, RADII, SHADOWS, SIZE, TRANSITIONS } = themeConstants

const tokenizedPalette = createDefaultTokenizedPalette(branding.colors, COMMON_PALETTE)
const COLORS = flattenPalette(tokenizedPalette)

export const {
  styled,
  css,
  getCssText,
  theme,
  createTheme: _createTheme,
  config,
  globalCss,
} = createStitches({
  prefix: 'oneid-fe',
  theme: {
    colors: COLORS,
    space: SPACE,
    sizes: SIZE,
    fontWeights: FONT_WEIGHTS,
    fontSizes: FONT_SIZES,
    lineHeights: LINE_HEIGHTS,
    radii: RADII,
    borderWidths: BORDER_WIDTHS,
    transitions: TRANSITIONS,
    shadows: SHADOWS,
  },
  media: {
    mobile: '(max-width: 768px)',
    desktop: '(min-width: 769px)',
  },
})

export type CSS = Stitches.CSS<typeof config>
