/**
 * Returns context props inside required context. Throws error if undefined.
 * @param context
 */
import { Context, useContext } from 'react'

export const useDefinedContext = <ContextProps>(context: Context<ContextProps | undefined>): ContextProps => {
  const contextProps = useContext(context)
  if (!contextProps) throw new Error('Context used is not defined.')
  return contextProps
}
