import { config } from 'config'
import * as yup from 'yup'
import { email } from './email'
import { password } from './password'
import { getEmailError } from './utils'

export const signup = () =>
  yup.object().shape({
    email: email().required(getEmailError()),
    password: password(config.passwordMinLenght).required(),
  })

export const connectFlowSignup = () =>
  yup.object().shape({
    email: email().required(getEmailError()),
    password: password(config.passwordMinLenght).required(),
    remember: yup.boolean().required(),
  })
