// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ResetPasswordOtpRequest
 */
export interface ResetPasswordOtpRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordOtpRequest
   */
  otp: string
}

export function ResetPasswordOtpRequestFromJSON(json: any): ResetPasswordOtpRequest {
  return ResetPasswordOtpRequestFromJSONTyped(json, false)
}

export function ResetPasswordOtpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordOtpRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    otp: json['otp'],
  }
}

export function ResetPasswordOtpRequestToJSON(value?: ResetPasswordOtpRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    otp: value.otp,
  }
}
