import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { branding } from 'branding'
import { AvailableLocale } from 'oneid-common'
import { FC } from 'react'

export const TermsOfUseLink: FC = () => {
  const { i18n } = useLingui()
  const selectedLocale = i18n.locale

  const linkSuffix = selectedLocale === AvailableLocale.Ar ? 'AR' : ''

  return (
    <p>
      <Trans id="signupPage.termsOfUse.infoText">
        By tapping to “Sign Up” you agree to {`${branding.brand} `}
        <a href={branding.url.termsOfUse({ locale: linkSuffix })} target="_blank">
          Terms of use
        </a>
      </Trans>
    </p>
  )
}
