import { FC, useMemo } from 'react'
import { makePasswordRules } from 'services/validators'
import { styled, theme } from 'styles/stitches.config'
import { CheckItem } from './CheckItem'

const PasswordStrengthStyled = styled('ul', {
  listStyle: 'none',
  padding: 0,
  fontSize: theme.fontSizes.t12,
  margin: `${theme.space.t8} 0 0 0`,
})

type Props = {
  password?: string
  minLength: number
  hasError?: boolean
}

export const PasswordStrength: FC<Props> = ({ password, minLength, hasError }) => {
  const value = password || ''

  const rules = useMemo(() => makePasswordRules(minLength), [minLength])

  return (
    <PasswordStrengthStyled>
      {rules.map(({ description, test, id }) => (
        <CheckItem key={id} valid={test(value)} text={description} hasError={hasError} />
      ))}
    </PasswordStrengthStyled>
  )
}
