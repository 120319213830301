import { t, Trans } from '@lingui/macro'
import { Client, User } from 'oneid-api-frontend'
import { FC } from 'react'
import { styled, theme } from 'styles/stitches.config'
import { Icon } from '../icon'

const InteractionInfoStyled = styled('div', {
  width: '100%',
  borderCollapse: 'collapse',
  borderSpacing: 0,
  marginTop: theme.space.t12,
  marginBottom: theme.space.t28,
  fontSize: theme.fontSizes.t12,

  '& tr': {
    display: 'flex',
  },

  '& :is(td, th)': {
    display: 'flex',
    flex: '0 0 50%',
    alignItems: 'center',
    height: theme.space.t40,
  },

  '& th': {
    fontWeight: theme.fontWeights.semiBold,
    textAlign: 'left',
  },

  '& svg': {
    marginInlineEnd: theme.space.t8,
    flex: '0 0',
    flexBasis: theme.space.t20,
  },

  '& img': {
    width: theme.space.t20,
    height: theme.space.t20,
    borderRadius: theme.radii.round,
    // borderWidth: theme.borderWidths.hairline,
    // borderStyle: 'solid',
    // borderColor: theme.colors.gray300,
    marginInlineStart: theme.space.t8,
  },

  '& td': {
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
})

const ClientStyled = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > div': {
    display: 'flex',
    alignItems: 'center',
  },
})

type Props = {
  client?: Client
  user?: User
}

export const InteractionInfo: FC<Props> = ({ user, client }) => (
  <InteractionInfoStyled data-qa="interaction-info">
    {client && (
      <ClientStyled>
        <div>
          <Icon name="BagSimple" />
          <Trans id="interactionInfo.company.label">Who requires it?</Trans>
        </div>
        <div>
          {client?.name}
          {client?.logo && (
            <img src={client.logo} alt={t({ id: 'interactionInfo.company.alt', message: 'Client logo' })} />
          )}
        </div>
      </ClientStyled>
    )}
    {user && (
      <ClientStyled>
        <div>
          <Icon name="DeviceMobile" />
          <Trans id="interactionInfo.attachedDevice.label">Attached device</Trans>
        </div>
        <div>{user?.device && `${user.device.brand} (${user.device.model})`}</div>
      </ClientStyled>
    )}
  </InteractionInfoStyled>
)
