import { FC } from 'react'
import { styled, theme } from 'styles/stitches.config'

interface CountdownProps {
  remainingSeconds: number
  expirationLabel?: string
}

const formatSeconds = (seconds: number) => {
  const minuteFraction = Math.floor(seconds / 60)
  const secondFraction = seconds % 60
  return `${minuteFraction}:${secondFraction.toString().padStart(2, '0')}`
}

const CountdownBase: FC<CountdownProps> = ({ remainingSeconds, expirationLabel = '0:00', ...props }) => (
  <span {...props}>{remainingSeconds <= 0 ? expirationLabel : formatSeconds(remainingSeconds)}</span>
)

export const Countdown = styled(CountdownBase, {
  fontWeight: theme.fontWeights.bold,
  lineHeight: theme.lineHeights.table,
  fontSize: theme.fontSizes.t20,

  variants: {
    isExpired: {
      true: {
        color: theme.colors.statesErrorBase,
      },
      false: {
        color: theme.colors.brandSecondary,
      },
    },
  },
  defaultVariants: {
    isExpired: false,
  },
})
