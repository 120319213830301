const environment = process.env.NODE_ENV || 'development'

export const config = {
  environment,
  consentPollingInterval: 2000,
  ekycPollingInterval: 4000,
  apiPrefix: '/api/v1',
  oidcApiPrefix: '/oidc',
  passwordMinLenght: 6,
  samlClientLoginInit: '/api/v1/saml-client/request-login', // TODO part of RP app?
  allowedOrigin: process.env.REACT_APP_ALLOWED_ORIGIN,

  appLinkHostname: !!window.ENV_APP_LINK_HOSTNAME ? window.ENV_APP_LINK_HOSTNAME : window.location.origin,

  dataDog: {
    env: window.ENV_DATADOG_ENV,
    version: window.ENV_DATADOG_VERSION,
    applicationId: window.ENV_DATADOG_APPLICATION_ID,
    site: window.ENV_DATADOG_SITE,
    service: window.ENV_DATADOG_SERVICE,
    clientToken: window.ENV_DATADOG_CLIENT_TOKEN,
  },
}
