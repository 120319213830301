import { useCallback, useState } from 'react'
import { FieldValues, SubmitHandler } from 'react-hook-form'
import { getTextForError, isErrorResponse } from 'services/errors'
import { DEFAULT_ERROR } from 'utils/apis'
import { useUpdatedRef } from './useUpdatedRef'

export const useSubmitAction = <T extends FieldValues>(
  action: SubmitHandler<T>,
  onError: (message: string) => void,
): [SubmitHandler<T>, boolean] => {
  const [isInProgress, setIsInProgress] = useState(false)
  const isInProgressRef = useUpdatedRef(isInProgress)

  const runAction = useCallback(
    async (...args: Parameters<SubmitHandler<T>>) => {
      if (isInProgressRef.current) return

      setIsInProgress(true)
      try {
        return await action(...args)
      } catch (err: unknown) {
        if (isErrorResponse(err)) {
          const errorMessage = getTextForError(err.error)
          onError(errorMessage)
        } else {
          onError(DEFAULT_ERROR.description)
        }
      } finally {
        setIsInProgress(false)
      }
    },
    [action, isInProgressRef, onError],
  )

  return [runAction, isInProgress]
}
