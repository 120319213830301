// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ErrorCode = {
  InternalError: 'INTERNAL_ERROR',
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  DeviceNotVerified: 'DEVICE_NOT_VERIFIED',
  DeviceInactive: 'DEVICE_INACTIVE',
  EmailIsRequired: 'EMAIL_IS_REQUIRED',
  DeviceidIsRequired: 'DEVICEID_IS_REQUIRED',
  DeviceIsRequired: 'DEVICE_IS_REQUIRED',
  InvalidDeviceid: 'INVALID_DEVICEID',
  OtpIsRequired: 'OTP_IS_REQUIRED',
  InvalidOtp: 'INVALID_OTP',
  InvalidPassword: 'INVALID_PASSWORD',
  PasswordNotStrongEnough: 'PASSWORD_NOT_STRONG_ENOUGH',
  PasswordAlreadySet: 'PASSWORD_ALREADY_SET',
  InvalidUser: 'INVALID_USER',
  InvalidEnrollment: 'INVALID_ENROLLMENT',
  UserNotVerified: 'USER_NOT_VERIFIED',
  InvalidRecovery: 'INVALID_RECOVERY',
  InvalidClaimSet: 'INVALID_CLAIM_SET',
  EmailOrPasswordIsIncorrect: 'EMAIL_OR_PASSWORD_IS_INCORRECT',
  SystemIsRequired: 'SYSTEM_IS_REQUIRED',
  SystemVersionIsRequired: 'SYSTEM_VERSION_IS_REQUIRED',
  DeviceTypeIsRequired: 'DEVICE_TYPE_IS_REQUIRED',
  DeviceNameIsRequired: 'DEVICE_NAME_IS_REQUIRED',
  DeviceModelIsRequired: 'DEVICE_MODEL_IS_REQUIRED',
  BrandIsRequired: 'BRAND_IS_REQUIRED',
  PushNotificationTokenIsRequired: 'PUSH_NOTIFICATION_TOKEN_IS_REQUIRED',
  StrongDevicePublicKeyDataIsRequired: 'STRONG_DEVICE_PUBLIC_KEY_DATA_IS_REQUIRED',
  WeakDevicePublicKeyDataIsRequired: 'WEAK_DEVICE_PUBLIC_KEY_DATA_IS_REQUIRED',
  WeakDevicePublicKeyDataIsAlreadyPresent: 'WEAK_DEVICE_PUBLIC_KEY_DATA_IS_ALREADY_PRESENT',
  BadData: 'BAD_DATA',
  InvalidOidcClient: 'INVALID_OIDC_CLIENT',
  InvalidSamlClient: 'INVALID_SAML_CLIENT',
  InvalidSession: 'INVALID_SESSION',
  InvalidMrz: 'INVALID_MRZ',
  MrzMissing: 'MRZ_MISSING',
  InactiveDocument: 'INACTIVE_DOCUMENT',
  FacetecFailedReplayFailed: 'FACETEC_FAILED_REPLAY_FAILED',
  FacetecFailedNoSession: 'FACETEC_FAILED_NO_SESSION',
  FacetecFailedAuditTrailVerificationFailed: 'FACETEC_FAILED_AUDIT_TRAIL_VERIFICATION_FAILED',
  FacetecFailedLivenessFailed: 'FACETEC_FAILED_LIVENESS_FAILED',
  FacetecFailedUnknown: 'FACETEC_FAILED_UNKNOWN',
  FacetecFailedNotAuthentic: 'FACETEC_FAILED_NOT_AUTHENTIC',
  FacetecFailedFullIdNotDetected: 'FACETEC_FAILED_FULL_ID_NOT_DETECTED',
  FacetecFailedCantDetectPhysicalId: 'FACETEC_FAILED_CANT_DETECT_PHYSICAL_ID',
  FacetecFailedNfcSkippedOrFailed: 'FACETEC_FAILED_NFC_SKIPPED_OR_FAILED',
  FacetecFailedBarcodeFailed: 'FACETEC_FAILED_BARCODE_FAILED',
  DocumentDataMismatch: 'DOCUMENT_DATA_MISMATCH',
  DocumentMrzOcrMismatch: 'DOCUMENT_MRZ_OCR_MISMATCH',
  DocumentMrzNfcMismatch: 'DOCUMENT_MRZ_NFC_MISMATCH',
  UnknownDocument: 'UNKNOWN_DOCUMENT',
  UnsupportedDocument: 'UNSUPPORTED_DOCUMENT',
  InvalidEnrollmentState: 'INVALID_ENROLLMENT_STATE',
  SignatureCheckFailed: 'SIGNATURE_CHECK_FAILED',
  PhotoValidationFailed: 'PHOTO_VALIDATION_FAILED',
  SignatureIsRequired: 'SIGNATURE_IS_REQUIRED',
  InvalidTransactionState: 'INVALID_TRANSACTION_STATE',
  InvalidKey: 'INVALID_KEY',
  InvalidPageSize: 'INVALID_PAGE_SIZE',
  InvalidApiVersion: 'INVALID_API_VERSION',
  InvalidToken: 'INVALID_TOKEN',
  InvalidPhoneNumber: 'INVALID_PHONE_NUMBER',
  UnsupportedPhoneNumber: 'UNSUPPORTED_PHONE_NUMBER',
  PhoneNumberChangeRequestedTooSoon: 'PHONE_NUMBER_CHANGE_REQUESTED_TOO_SOON',
  PhoneNumberChangeNotInProgress: 'PHONE_NUMBER_CHANGE_NOT_IN_PROGRESS',
  PhoneNumberOtpExpired: 'PHONE_NUMBER_OTP_EXPIRED',
  PhoneNumberOtpInvalid: 'PHONE_NUMBER_OTP_INVALID',
  PhoneNumberOtpInvalidMaxAttemptsReached: 'PHONE_NUMBER_OTP_INVALID_MAX_ATTEMPTS_REACHED',
  InteractionAlreadyLoggedIn: 'INTERACTION_ALREADY_LOGGED_IN',
  StartRequestDelegationMissingClaimset: 'START_REQUEST_DELEGATION_MISSING_CLAIMSET',
  StartRequestDelegationMissingRecipientClaimset: 'START_REQUEST_DELEGATION_MISSING_RECIPIENT_CLAIMSET',
  StartRequestDelegationMissingRecipientWeakKey: 'START_REQUEST_DELEGATION_MISSING_RECIPIENT_WEAK_KEY',
  StartRequestDelegationMissingRequestorWeakKey: 'START_REQUEST_DELEGATION_MISSING_REQUESTOR_WEAK_KEY',
  StartRequestDelegationMissingRecipientNoDevice: 'START_REQUEST_DELEGATION_MISSING_RECIPIENT_NO_DEVICE',
  StartRequestDelegationInvalidPairingCode: 'START_REQUEST_DELEGATION_INVALID_PAIRING_CODE',
  StartRequestDelegationInvalidClient: 'START_REQUEST_DELEGATION_INVALID_CLIENT',
  HandleRevokeDelegationDelegationRevoked: 'HANDLE_REVOKE_DELEGATION_DELEGATION_REVOKED',
  HandleRevokeDelegationStatusUnexpected: 'HANDLE_REVOKE_DELEGATION_STATUS_UNEXPECTED',
  HandleStartRequestDelegationDisallowedSelfDelegation: 'HANDLE_START_REQUEST_DELEGATION_DISALLOWED_SELF_DELEGATION',
  ApproveTransactionDelegationRequestCprMissmatch: 'APPROVE_TRANSACTION_DELEGATION_REQUEST_CPR_MISSMATCH',
  ApproveTransactionDelegationRequestInvalidPairingCode: 'APPROVE_TRANSACTION_DELEGATION_REQUEST_INVALID_PAIRING_CODE',
  ApproveTransactionDelegationOtherDeviceWeakKeyMissing: 'APPROVE_TRANSACTION_DELEGATION_OTHER_DEVICE_WEAK_KEY_MISSING',
} as const
export type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode]

export function ErrorCodeFromJSON(json: any): ErrorCode {
  return ErrorCodeFromJSONTyped(json, false)
}

export function ErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorCode {
  return json as ErrorCode
}

export function ErrorCodeToJSON(value?: ErrorCode | null): any {
  return value as any
}
