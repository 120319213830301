import { ConsentStatus } from 'oneid-api-frontend'
import { abortInteraction, confirmConsent } from 'services/consent'
import { Token } from 'types'
import { navigateTo } from 'utils'

export const handleOidcConsentStatus = async (
  status: ConsentStatus,
  uid: string,
  addToken: (token: Token) => void,
  clearTokens: () => void,
) => {
  switch (status) {
    case ConsentStatus.Denied: {
      clearTokens()
      navigateTo(await abortInteraction(uid))
      break
    }
    case ConsentStatus.Granted: {
      const { location, token } = await confirmConsent(uid)
      if (token) addToken(token)
      navigateTo(location)
      break
    }
  }
}
