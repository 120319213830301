import { AvailableLocale } from 'oneid-common'

export const DEFAULT_CLIENT = 'foo'
export const DEFAULT_CODE_VERIFIER = '12345678901234567890123456789012345678901234567890'

export const DEFAULT_FALLBACK_LOCALE = AvailableLocale.En

export const rtlLanguages: AvailableLocale[] = [AvailableLocale.Ar]

export const localeNames: Record<AvailableLocale, string> = {
  en: 'English',
  ar: 'العربية',
}

export const LOCALE_STORAGE = 'lang'

export const DEFAULT_STORAGE_KEY_TTL = 24 * 60 * 60 // 1 day
