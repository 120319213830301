import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { Button, createOpenErrorToast, Form, openSuccessToast, SubmitButton, Template } from 'components'
import { SubtitleStyled } from 'components/containers/Template.styled'
import { useSubmitAction } from 'hooks/useSubmitAction'
import { FC, useCallback } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { Routes } from 'routes'
import { otp } from 'services/validators'
import { ResetPasswordPathParams, ResetPasswordRouteState } from 'types'
import { resetPasswordApi } from 'utils/apis'
import * as yup from 'yup'
import { EmailStyled, OtpInputStyled } from './ResetPasswordOtp.styled'

type FormData = {
  otp: string
}

const LENGTH = 6
const CODE_INPUT_PLACEHOLDER = '-'.repeat(LENGTH).split('')

export const ResetPasswordOtp: FC = () => {
  const validator = yup.object({
    otp: otp(LENGTH).required(),
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormData>({
    defaultValues: { otp: '' },
    resolver: yupResolver(validator),
    mode: 'onChange',
  })

  const history = useHistory<ResetPasswordRouteState>()
  const { email } = history.location.state
  const { resetPasswordId } = useParams<ResetPasswordPathParams>()

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async (data) => {
      await resetPasswordApi.resetPasswordOtp({
        resetPasswordId,
        resetPasswordOtpRequest: { otp: data.otp },
      })
      history.replace(generatePath(Routes.resetPasswordNewPassword, { resetPasswordId }), history.location.state)
    },
    [history, resetPasswordId],
  )

  const [submit, isSubmitting] = useSubmitAction<FormData>(onSubmit, createOpenErrorToast())

  const onResend = useCallback(async () => {
    await resetPasswordApi.resetPasswordResend({ resetPasswordId })
    openSuccessToast('Access code sent')
  }, [resetPasswordId])
  const [resend, isResending] = useSubmitAction(onResend, createOpenErrorToast())

  return (
    <Template
      title={t({ id: 'resetPassword.otpPage.title', message: 'Enter access code' })}
      subtitle={
        <SubtitleStyled>
          <Trans id="resetPassword.otpPage.subtitle">
            Sent to <EmailStyled>{email}</EmailStyled>
          </Trans>
        </SubtitleStyled>
      }
      wrapContent
      titleBottomMargin={24}
    >
      <Form
        onSubmit={handleSubmit(submit)}
        footer={
          <>
            <Button type="button" variant="secondary" onClick={resend} disabled={isSubmitting || isResending}>
              <Trans id="resetPassword.otpPage.resendButton.label">Resend email</Trans>
            </Button>
            <SubmitButton disabled={!isValid || isSubmitting || isResending}>
              <Trans id="resetPassword.otpPage.submitButton.label">Continue</Trans>
            </SubmitButton>
          </>
        }
      >
        <Controller
          name="otp"
          control={control}
          render={({ field }) => (
            <OtpInputStyled
              {...field}
              type="number"
              title={t({ id: 'resetPassword.otpPage.accessCodeInput.label', message: 'Access code' })}
              autoFocus
              fields={LENGTH}
              values={field.value.split('')}
              placeholder={CODE_INPUT_PLACEHOLDER}
            />
          )}
        />
      </Form>
    </Template>
  )
}
