import { ContainerPropertiesProvider, LocaleInitializer, TokensProvider } from 'appProviders'
import { FC } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppLayout } from './AppLayout'

const queryClient = new QueryClient()

export const App: FC = () => (
  <ContainerPropertiesProvider>
    <QueryClientProvider client={queryClient}>
      <TokensProvider>
        <LocaleInitializer>
          <AppLayout />
        </LocaleInitializer>
      </TokensProvider>
    </QueryClientProvider>
  </ContainerPropertiesProvider>
)
