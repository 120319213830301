import { config } from 'config'
import { AvailableLocale } from 'oneid-common'
import { useCallback, useEffect, useState } from 'react'
import { ContainerProperties, ContainerPropertiesContext, ScreenType } from 'services/containerProperties'
import { selectLocale } from 'services/locale'
import { FCC } from 'types'
import { getIsInIframe } from 'utils'

type ScreenDimensions = {
  width: number
  height: number
}

enum MessageType {
  ContainerProperties = 'container-properties',
}

interface Message {
  type: MessageType.ContainerProperties
  size: ScreenDimensions
  locale: AvailableLocale
}

const initialValue: ContainerProperties = {
  isReadyToRender: !getIsInIframe(),
}

const ALLOWED_ORIGIN = config.allowedOrigin ? new RegExp(config.allowedOrigin) : /https:\/\/.*\.oneid\.bh/
const WAITING_TIMEOUT = 1000

export const ContainerPropertiesProvider: FCC = ({ children }) => {
  const [containerVariant, setContainerVariant] = useState<ContainerProperties>(initialValue)

  const handleMessage = useCallback((e: MessageEvent<Message>) => {
    if (!ALLOWED_ORIGIN.exec(e.origin)) return
    if (e.data.type === MessageType.ContainerProperties) {
      const width = e?.data?.size?.width

      selectLocale(e.data.locale)
      if (width > 768) {
        setContainerVariant({ screenType: ScreenType.Desktop, isReadyToRender: true })
      }
      if (width <= 768) {
        setContainerVariant({ screenType: ScreenType.Mobile, isReadyToRender: true })
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [handleMessage])

  useEffect(() => {
    if (!containerVariant.isReadyToRender) {
      const timeout = window.setTimeout(
        () => setContainerVariant((prevValue) => ({ ...prevValue, isReadyToRender: true })),
        WAITING_TIMEOUT,
      )
      return () => {
        window.clearTimeout(timeout)
      }
    }
    return
  }, [containerVariant.isReadyToRender])

  return <ContainerPropertiesContext.Provider value={containerVariant}>{children}</ContainerPropertiesContext.Provider>
}
