import { keyframes } from '@stitches/react'
import { styled, theme } from 'styles/stitches.config'
import { Icon } from '../icon'

const ldsRingAnimation = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

const SIZE = '4rem' // = 64px
export const OVERLAY_ANIMATION_DURATION = 300

export const SpinnerStyled = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: theme.space.t4,
  backgroundColor: theme.colors.backgroundSurface, // backgroundColor: theme.colors.gray100,
  padding: `${theme.space.t8} ${theme.space.t12}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column nowrap',
})

export const SpinnerVisualStyled = styled('div', {
  width: SIZE, // 'var(--size)',
  height: SIZE, // 'var(--size)',
  position: 'relative',
  marginTop: theme.space.t8,
  marginBottom: theme.space.t8,
})

export const DelayStyled = styled('div', {
  boxSizing: 'border-box',
  display: 'block',
  position: 'absolute',
  width: `calc(${SIZE} * 0.8)`, // var(--size)
  height: `calc(${SIZE} * 0.8)`,
  margin: `calc(${SIZE} * 0.1)`,
  borderWidth: `calc(${SIZE} * 0.05)`,
  borderStyle: 'solid',
  // TODO: what should be the colors? borderColor: `${theme.colors.primary900} transparent transparent transparent`,
  borderColor: `${theme.colors.componentDefault} transparent transparent transparent`,
  borderRadius: theme.radii.round,
  animation: `${ldsRingAnimation.name} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,

  '&.delaySmall': {
    animationDelay: '-0.15s',
  },

  '&.delayBig': {
    animationDelay: '-0.45s',
  },

  '&.delay': {
    animationDelay: '-0.3s',
  },
})

export const IconStyled = styled(Icon, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  '& path': {
    fill: theme.colors.componentDefault, // fill: theme.colors.primary800,
  },
})

export const SpinnerWrapperStyled = styled('div', {
  background: 'rgba(0, 0, 0, 0.4)',
  willChange: 'opacity',
  position: 'fixed',
  inset: 0,
  opacity: 1,

  '&.enter': {
    opacity: 0,
  },

  '&.enter-active': {
    opacity: 1,
    transition: `opacity ${OVERLAY_ANIMATION_DURATION}ms ease-in-out`,
  },

  '&.exit': {
    opacity: 1,
  },

  '&.exit-active': {
    opacity: 0,
    transition: `opacity ${OVERLAY_ANIMATION_DURATION}ms ease-in-out`,
  },
})

export const TitleStyled = styled('span', {
  marginTop: theme.space.t8,
  fontSize: theme.fontSizes.t10,
})
