// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ResetPasswordInitResponse
 */
export interface ResetPasswordInitResponse {
  /**
   *
   * @type {number}
   * @memberof ResetPasswordInitResponse
   */
  expiration: number
  /**
   *
   * @type {string}
   * @memberof ResetPasswordInitResponse
   */
  id: string
}

export function ResetPasswordInitResponseFromJSON(json: any): ResetPasswordInitResponse {
  return ResetPasswordInitResponseFromJSONTyped(json, false)
}

export function ResetPasswordInitResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResetPasswordInitResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    expiration: json['expiration'],
    id: json['id'],
  }
}

export function ResetPasswordInitResponseToJSON(value?: ResetPasswordInitResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    expiration: value.expiration,
    id: value.id,
  }
}
