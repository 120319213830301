import * as yup from 'yup'
import { email } from './email'
import { getEmailError } from './utils'

export const login = () =>
  yup.object().shape({
    email: email().required(getEmailError()),
    password: yup.string().required(),
    remember: yup.boolean().required(),
  })
