import { isNotNil } from '../typechecks'

/**
 * Filter undefined values from array
 * @param arr - array to filter
 */
export const filterUndefinedFromArray = <T>(arr: Array<T | undefined>): T[] => arr.filter((v) => v !== undefined) as T[]

/**
 * Serially map array with await
 * @param values - array to map
 * @param mapFunction - function to mapFunction values
 */
export const mapSerial = async <T, R>(values: T[], mapFunction: (value: T) => Promise<R>): Promise<R[]> => {
  const transformedValues: R[] = []
  for (const value of values) {
    transformedValues.push(await mapFunction(value))
  }
  return transformedValues
}

/**
 * Map function that filters undefined values from resulting array
 * @param values - array to run the mapFunction on
 * @param mapFunction - array map function that returns null or undefined for items that should be removed
 */
export const compactMap = <T, R>(values: T[], mapFunction: (val: T) => R | null | undefined): R[] =>
  values.map(mapFunction).filter(isNotNil)

/**
 * Map function that filters undefined values from resulting array of promises
 * @param values - array to run the mapFunction on
 * @param mapFunction - array map function that returns null or undefined in promise for items that should be removed
 */
export const compactPromisedMap = async <T, R>(
  values: T[],
  mapFunction: (val: T) => Promise<R | null | undefined>,
): Promise<R[]> => (await Promise.all(values.map(mapFunction))).filter(isNotNil)

/**
 * Map function that filters undefined values in awaited promises
 * @param arr - array to run the mapSerial function on
 * @param mapFunction - array map function that returns null or undefined in promise for items that should be removed
 */
export const compactSerialPromisedMap = async <T, R>(
  arr: T[],
  mapFunction: (val: T) => Promise<R | null | undefined>,
): Promise<R[]> => (await mapSerial(arr, mapFunction)).filter(isNotNil)
