import { t } from '@lingui/macro'
import { Template, TemplateError, TokenLoginForm, openErrorToast } from 'components'
import { FC, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useSamlTokenLoginController } from './hooks'

export const SamlTokenLogin: FC = () => {
  const { error, handleSubmit, isSubmitting, isExpired, interaction, passwordRoute, tokens, requestError } =
    useSamlTokenLoginController()

  // TODO: can this be moved to useSamlTokenLoginController?
  useEffect(() => {
    if (requestError) {
      openErrorToast(requestError.description)
    }
  }, [requestError])

  if (isExpired)
    return <TemplateError errorText={t({ id: 'signInPage.sessionExpiredError', message: 'Session has expired' })} />

  if (error) return <TemplateError error={error} />

  return tokens.length > 0 ? (
    <Template
      title={t({ id: 'signInPage.loginToSavedAccount.title', message: 'Choose your account' })}
      isLoading={!interaction || isSubmitting}
      wrapContent
      titleBottomMargin={44}
    >
      {interaction && <TokenLoginForm onSubmit={handleSubmit} tokens={tokens} passwordLoginRoute={passwordRoute} />}
    </Template>
  ) : (
    <Redirect to={passwordRoute} />
  )
}
