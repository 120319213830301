import { FC, useEffect, useRef } from 'react'

type AutoSubmitFormProps = {
  actionUrl: string
  params: Record<string, string>
}

export const AutoSubmitForm: FC<AutoSubmitFormProps> = ({ actionUrl, params }) => {
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [])

  return (
    <form ref={formRef} method="POST" action={actionUrl}>
      {Object.keys(params).map((name) => (
        <input type="hidden" name={name} id={name} value={params[name]} />
      ))}
    </form>
  )
}
