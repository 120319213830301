import { t } from '@lingui/macro'
import { string } from 'yup'
import { ValidationRule } from './types'
import { extendSchema } from './utils'

export const makePasswordRules = (minLength: number): ValidationRule[] => [
  {
    id: 'minlength',
    description: t({
      id: 'passwordRules.minimalLength',
      message: 'Must be at least {minLength} characters long',
      values: { minLength },
    }),
    test: (value) => !!value && value.length >= minLength,
  },
]

export const password = (minLength: number) => extendSchema(string(), makePasswordRules(minLength))
