import { t } from '@lingui/macro'
import { branding } from 'branding'
import { AutoSubmitForm, CountdownCounter, InteractionInfo, Steps, Template, TemplateError } from 'components'
import { FC } from 'react'
import { getConsentSteps } from 'services/consent'
import { useSamlConsentController } from './hooks'

export const SamlConsent: FC = () => {
  const { interaction, error, remainingSeconds, data } = useSamlConsentController()

  if (error) return <TemplateError error={error} />

  return (
    <Template
      title={t({ id: 'consentPage.giveConsent.title', message: `Authenticate with ${branding.brand}` })}
      isLoading={!interaction}
      wrapContent
      titleBottomMargin={32}
    >
      {interaction && (
        <>
          <InteractionInfo user={interaction.user} client={interaction.client} />
          <CountdownCounter remainingSeconds={remainingSeconds} clientName={interaction.client.name} />
          {remainingSeconds > 0 && <Steps steps={getConsentSteps(interaction.client.name)} />}
        </>
      )}
      {data && <AutoSubmitForm actionUrl={data.entityEndpoint} params={{ [data.type]: data.context }} />}
    </Template>
  )
}
