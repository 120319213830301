import { FC } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Routes } from 'routes'
import {
  ConnectFlowSignup,
  Consent,
  EKYC,
  FinishWebSignup,
  Home,
  Login,
  MobileNotCapturedRedirect,
  OIDCCallback,
  ResetPassword,
  SamlConsent,
  SamlLogin,
  UserInfo,
  WebSignup,
} from 'screens'

export const AppRouter: FC = () => (
  <Router>
    <Switch>
      <Route exact path={Routes.index}>
        <Home />
      </Route>
      <Route path={Routes.mobileLoginRedirect}>
        <MobileNotCapturedRedirect />
      </Route>
      <Route path={Routes.login}>
        <Login />
      </Route>
      <Route path={Routes.finishSignup}>
        <FinishWebSignup />
      </Route>
      <Route path={Routes.webSignup} exact>
        <WebSignup />
      </Route>
      <Route path={Routes.signup}>
        <ConnectFlowSignup />
      </Route>
      <Route path={Routes.consent}>
        <Consent />
      </Route>
      <Route path={Routes.ekyc}>
        <EKYC />
      </Route>
      <Route path={Routes.tokenCallback}>
        <OIDCCallback />
      </Route>
      <Route path={Routes.test}>
        <UserInfo />
      </Route>
      <Route path={Routes.samlLogin}>
        <SamlLogin />
      </Route>
      <Route path={Routes.samlConsent}>
        <SamlConsent />
      </Route>
      <Route path={Routes.resetPassword}>
        <ResetPassword />
      </Route>
    </Switch>
  </Router>
)
