import CodeInput from 'react-verification-code-input'
import { styled, theme } from 'styles/stitches.config'

export const OtpInputStyled = styled(CodeInput, {
  // TODO: there should be a better way to style this
  width: '100% !important',
  display: 'flex',
  flexDirection: 'column',

  // Need to increase specificity to override CodeInput cmp styles
  '&&& input': {
    flexGrow: 1,
    minWidth: 0,
    maxWidth: '54px',
    width: 'unset !important',
    fontFamily: 'aventa, sans-serif',
    position: 'relative',
    color: theme.colors.textPrimary,
    borderRadius: theme.radii.t4,
    marginRight: theme.space.t8,
    marginBottom: theme.space.t8,
    borderStyle: 'solid',
    borderWidth: theme.borderWidths.hairline,
    borderColor: theme.colors.strokeAlt,
    borderRightWidth: theme.borderWidths.hairline,
    borderRightColor: theme.colors.strokeAlt,

    '&:last-child': {
      marginRight: 0,
    },

    '&:focus::placeholder': {
      color: 'transparent',
    },

    '@mobile': {
      maxWidth: '48px',
      maxHeight: '48px',
    },
  },

  '& > p': {
    color: theme.colors.textSecondary,
    fontWeight: theme.fontWeights.bold,
    margin: `${theme.space.t4} 0`,
    fontSize: theme.fontSizes.t14,
    paddingBottom: 0,
  },

  '& > div': {
    '@mobile': {
      display: 'flex!important',
    },
  },
})

export const EmailStyled = styled('span', {
  fontWeight: theme.fontWeights.bold,
})
