import { isEnum } from '../typechecks'
import { OneOf } from '../types'

export const getEnvironment = () => process.env.NODE_ENV || 'development'
export const isDevelopment = () => getEnvironment() === 'development'

export function parseNumber<T extends number | undefined>(value: string | undefined, defaultValue: T): T | number {
  if (value) {
    const parsedValue = parseInt(value, 10)
    if (!Number.isNaN(parsedValue)) {
      return parsedValue
    }
  }
  return defaultValue
}

export function parseBoolean(value: string | undefined, defaultValue: boolean): boolean {
  if (value) {
    return value.toLowerCase() === 'true'
  }
  return defaultValue
}

export const parseOptionalEnum = <TEnum extends object>(
  value: string | undefined,
  enumObj: TEnum,
): OneOf<TEnum> | undefined => {
  if (isEnum(enumObj)(value)) {
    return value
  }
  return undefined
}

export const parseEnum = <TEnum extends object>(
  value: string | undefined,
  enumObj: TEnum,
  defaultValue: OneOf<TEnum>,
): OneOf<TEnum> => parseOptionalEnum(value, enumObj) ?? defaultValue

export const parseBase64 = (value: string | undefined): string =>
  value == undefined ? '' : Buffer.from(value, 'base64').toString('binary')

export const parseStringArray = (value: string | undefined, delimiter = ',') =>
  value == undefined ? [] : value.split(delimiter)

export const required = <T>(value: T | undefined, valueName: string): T => {
  // Intentionally throwing on empty strings and nulls
  if (!value) throw new Error(`Value ${valueName} is required`)
  return value
}

export const requiredEnv = (valueName: string) => required(process.env[valueName], valueName)
