import { useCountdown } from 'hooks/useCountdown'
import { useInteraction } from 'hooks/useInteraction'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useOidcConsent } from 'services/oidc'
import { useTokens } from 'services/tokens'
import { handleOidcConsentStatus } from './effects'

const useIsConsentCountdownPageHidden = () => {
  const location = useLocation()
  return new URLSearchParams(location.search).get('hideConsentCountdownPage') === 'true'
}

export const useOidcConsentController = () => {
  const hideConsentCountdownPage = useIsConsentCountdownPageHidden()
  const { interaction, error, interactionUid } = useInteraction()
  const { addToken, clearTokens } = useTokens()

  const remainingSeconds = useCountdown(interaction?.expiresAt)

  const { consent } = useOidcConsent(interactionUid, remainingSeconds > 0)

  useEffect(() => {
    if (consent?.consentStatus) {
      void handleOidcConsentStatus(consent.consentStatus, interactionUid, addToken, clearTokens)
    }
  }, [addToken, clearTokens, consent, interactionUid])

  return { error, interaction, remainingSeconds, hideConsentCountdownPage }
}
