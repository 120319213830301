import { useEffect, useRef } from 'react'

export const useRecursiveTimeout = <T>(callback: () => Promise<T> | (() => void), delay: number | null) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout loop
  useEffect(() => {
    let timeoutId: number

    const tick = async () => {
      const ret = savedCallback.current()

      await ret
      if (delay !== null) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        timeoutId = window.setTimeout(tick, delay)
      }
    }

    if (delay !== null) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      timeoutId = window.setTimeout(tick, delay)
    }
    return () => {
      timeoutId && window.clearTimeout(timeoutId)
    }
  }, [delay])
}
