import { t } from '@lingui/macro'
import { Template, TemplateError } from 'components'
import { useApiError } from 'hooks/useApiError'
import { FC, useCallback, useEffect } from 'react'
import { redirectWithAuthTokenLoginOIDC } from 'services/oidc'
import { useOidcLoginController } from './hooks'

export const AuthTokenLogin: FC = () => {
  const { interaction, error, isExpired } = useOidcLoginController()
  const [apiError, setApiError] = useApiError()

  const loginWithAuthToken = useCallback(async () => {
    if (interaction) {
      try {
        await redirectWithAuthTokenLoginOIDC(interaction.id)
      } catch (err: unknown) {
        setApiError(err)
      }
    }
  }, [interaction, setApiError])

  useEffect(() => {
    void loginWithAuthToken()
  }, [loginWithAuthToken])

  // TODO handle errors
  if (apiError) return <TemplateError error={apiError} />
  if (isExpired)
    return <TemplateError errorText={t({ id: 'signInPage.savedAccount.errorText', message: 'Session has expired' })} />
  if (error) return <TemplateError error={error} />

  return <Template title={t({ id: 'signInPage.savedAccount.title', message: 'Signing in' })} isLoading />
}
