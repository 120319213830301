// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const AuthType = {
  Saml: 'SAML',
  Oidc: 'OIDC',
} as const
export type AuthType = (typeof AuthType)[keyof typeof AuthType]

export function AuthTypeFromJSON(json: any): AuthType {
  return AuthTypeFromJSONTyped(json, false)
}

export function AuthTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthType {
  return json as AuthType
}

export function AuthTypeToJSON(value?: AuthType | null): any {
  return value as any
}
