export const Routes = {
  consent: '/consent/:uid', // has to match URL used on BE
  ekyc: '/ekyc/:uid', // has to match URL used on BE

  login: '/login/:uid', // has to match URL used on BE
  tokenLogin: '/login/:uid/token',
  mobileLoginIntro: '/login/:uid/mobile', // has to match the URL used on BE
  passwordLogin: '/login/:uid/password',
  authTokenLogin: '/login/:uid/authToken', // has to match URL used on BE

  resetPassword: '/resetPassword',
  resetPasswordEmail: '/resetPassword/email',
  resetPasswordOtp: '/resetPassword/:resetPasswordId/otp',
  resetPasswordNewPassword: '/resetPassword/:resetPasswordId/newPassword',
  resetPasswordConfirm: '/resetPassword/:transactionId/confirm',

  signup: '/signup/:uid', // has to match URL used on BE
  tokenCallback: '/token-callback', // has to match URL used on BE

  index: '/',

  test: '/test', // in reality page of RP handling the result

  finishSignup: '/webSignup/finish',
  webSignup: '/webSignup',

  // Route to which we're linking in case of app-to-app login -> should be captured by the OneID Mobile
  mobileLoginRedirect: '/mobileLogin', // has to match the Universal Link intercepted by the OneID Mobile

  samlLogin: '/saml/login/:uid', // has to match URL used on BE
  samlLoginPassword: '/saml/login/:uid/password',
  samlLoginToken: '/saml/login/:uid/token',
  samlConsent: '/saml/consent/:uid',
} as const
