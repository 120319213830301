import { config } from 'config'
import { useCountdown } from 'hooks/useCountdown'
import { useInteractionDetail } from 'hooks/useInteractionDetail'
import { AuthType, ConsentStatus, InteractionStatus } from 'oneid-api-frontend'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { decryptKeyUsingStoredKeyPair } from 'services/security'
import { useTokens } from 'services/tokens'
import { RequestResponse } from 'types'
import { interactionApi } from 'utils/apis'

export const useSamlConsent = (interactionId: string, enabled: boolean) => {
  const result = useQuery<InteractionStatus>(
    ['samlConsent', interactionId],
    () =>
      interactionApi.getInteractionConsentStatus({
        authType: AuthType.Saml,
        interactionId: interactionId,
      }),
    {
      // use function to run next request after the previous finished
      refetchInterval: () => config.consentPollingInterval,
      enabled,
    },
  )

  return { consent: result.data, isLoading: result.isLoading, isError: result.isError }
}

export const useSamlConsentController = () => {
  const { interaction, error, interactionUid } = useInteractionDetail(AuthType.Saml)
  const remainingSeconds = useCountdown(interaction?.expiresAt)
  const { consent } = useSamlConsent(interactionUid, remainingSeconds > 0)
  const { clearTokens, addToken } = useTokens()

  const [data, setData] = useState<RequestResponse>()

  const handleConsentStatusResponse = useCallback(
    async ({ consentStatus, key, pubKeySha }: InteractionStatus) => {
      if (!interaction || !consentStatus) return

      switch (consentStatus) {
        case ConsentStatus.Granted: {
          if (!key || !pubKeySha) {
            console.error('Consent -> consent granted but key is missing')
            return
          }
          const decryptedKey = await decryptKeyUsingStoredKeyPair(pubKeySha, key)
          if (!decryptedKey) {
            console.error('Consent -> consent granted unable to decrypt')
            return
          }

          const { signInToken, context, type, entityEndpoint } = await interactionApi.finishInteraction({
            interactionId: interaction.id,
            authType: AuthType.Saml,
            finishInteractionBody: {
              decryptedKey,
            },
          })

          if (signInToken) addToken(signInToken)
          setData({ context, type, entityEndpoint })
          return
        }
        case ConsentStatus.Denied: {
          const { context, type, entityEndpoint } = await interactionApi.abortInteractionByAuthType({
            interactionId: interaction.id,
            authType: AuthType.Saml,
          })
          setData({ context, type, entityEndpoint })

          clearTokens()
          return
        }
      }
    },
    [addToken, clearTokens, interaction],
  )

  useEffect(() => {
    if (consent) {
      void handleConsentStatusResponse(consent)
    }
  }, [consent, handleConsentStatusResponse])

  return { error, interaction, data, remainingSeconds }
}
