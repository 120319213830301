import { Session, SessionContextProps, useSession } from 'hooks/useSession'
import { createContext } from 'react'
import { FCC } from 'types'
import { useDefinedContext } from 'utils'

const ResetPasswordContext = createContext<SessionContextProps<Session> | undefined>(undefined)

export const ResetPasswordContextProvider: FCC = ({ children }) => {
  const session = useSession()
  return <ResetPasswordContext.Provider value={session}>{children}</ResetPasswordContext.Provider>
}

export const useResetPassword = () => useDefinedContext(ResetPasswordContext)
