import { t } from '@lingui/macro'
import * as yup from 'yup'

export const otp = (length: number) =>
  yup
    .string()
    .matches(/^[0-9]*$/, {
      message: t({ id: 'otpValidator.invalidInputMessage', message: 'Must only contain digits' }),
    })
    .length(length)
