import { ReactComponent as LogoSymbolDark } from 'assets/logos/symbolDark.svg'
import { ReactComponent as LogoDark } from './assets/logos/dark.svg'
import { ReactComponent as LogoLight } from './assets/logos/light.svg'
import { Branding } from './types/branding'

const baseUrl = 'https://www.oneid.bh'

export const branding: Branding = {
  brand: 'OneID',
  logo: {
    dark: LogoDark,
    light: LogoLight,
    symbolDark: LogoSymbolDark,
  },
  strings: {},
  url: {
    mail: () => 'support@oneid.bh',
    termsOfUse: (options) => `https://static.oneid.bh/OneID-terms-of-use${options.locale}`,
    privacyPolicy: (options) => `${baseUrl}/${options.locale}/privacy-policy/`,
  },
  colors: {
    primary: {
      700: '#4C77A8',
      800: '#0D4380',
      900: '#012E5D',
    },
    secondary: {
      800: '#29668D',
    },
    accent: {
      600: '#EE4D3B',
    },
  },
}
