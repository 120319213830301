import cx from 'classnames'
import { config } from 'config'
import { forwardRef, useState } from 'react'
import { ChangeHandler, FieldError } from 'react-hook-form'
import { CSS } from 'styles/stitches.config'
import { IconStyled, InputStyled, InputWrapperStyled, LabelTextStyled, TextFieldStyled } from '../TextField'
import { PasswordStrength } from './PasswordStrength'

type Props = {
  label: string
  name: string
  value?: string
  showStrength?: boolean
  error?: FieldError
  className?: string
  onChange: ChangeHandler
  onBlur: ChangeHandler
  inputClass?: string
  inputWrapperClass?: string
  marginBottom?: CSS['marginBottom']
}

export const PasswordField = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      name,
      value,
      showStrength,
      onChange,
      onBlur,
      error,
      inputClass,
      inputWrapperClass,
      marginBottom,
    },
    ref,
  ) => {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

    const toggleVisibility = () => {
      setPasswordVisible(!passwordVisible)
    }

    return (
      <TextFieldStyled className={cx({ ['error']: !!error }, className)} css={{ marginBottom: marginBottom }}>
        <label>
          <LabelTextStyled>{label}</LabelTextStyled>
          <InputWrapperStyled className={cx(inputWrapperClass)}>
            <InputStyled
              ref={ref}
              type={!passwordVisible ? 'password' : 'text'}
              name={name}
              placeholder={passwordVisible ? 'password' : '******'}
              onChange={onChange}
              onBlur={onBlur}
              className={cx(inputClass)}
            />
            <IconStyled name={passwordVisible ? 'EyeSlash' : 'Eye'} onClick={toggleVisibility} />
          </InputWrapperStyled>
        </label>
        {showStrength && <PasswordStrength password={value} minLength={config.passwordMinLenght} hasError={!!error} />}
      </TextFieldStyled>
    )
  },
)
