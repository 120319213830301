import { t, Trans } from '@lingui/macro'
import { branding } from 'branding'
import { Form, LinkButton, Template, Unordered } from 'components'
import { config } from 'config'
import { FC } from 'react'
import { getPlatform, Platform } from 'services/platform'
import { StyledFinishWebSignup } from './FinishWebSignup.styled'

const {
  FooterStyled,
  FooterTextStyled,
  BeyonLogoStyled,
  OneIDLogoStyled,
  WebStyled,
  TextStyled,
  LinkBox,
  ImgStyled,
  StyledOr,
} = StyledFinishWebSignup

export const FinishWebSignup: FC = () => {
  const detectedPlatform = getPlatform()

  const storeLink = {
    [Platform.Android]: 'https://play.google.com/store/apps/details?id=com.public2.oneid',
    [Platform.Ios]: 'https://apps.apple.com/app/beyon-oneid/id1592996533',
  }

  const headlineCopy = {
    mobile: t({ id: 'finishSignupPage.titleMobile', message: 'Install additional app to continue' }),
    desktop: t({
      id: 'finishSignupPage.titleDesktop',
      message: `Install ${branding.brand} app to continue`,
    }),
  }

  return (
    <Template
      title={detectedPlatform ? headlineCopy.mobile : headlineCopy.desktop}
      subtitle={t({
        id: 'finishSignupPage.subtitle',
        message: `${branding.brand} makes identity verification fast and easy with just three steps`,
      })}
      wrapContent
      isWide
      titleBottomMargin={44}
    >
      <Form
        footer={
          <FooterStyled>
            <FooterTextStyled>
              <Trans id="finishSignupPage.trustedBy">Trusted by</Trans>
            </FooterTextStyled>
            <BeyonLogoStyled isMobile={!!detectedPlatform} />
            {detectedPlatform && (
              <LinkButton href={storeLink[detectedPlatform]}>
                <OneIDLogoStyled />
                <Trans id="finishSignupPage.downloadButton">Download {branding.brand}</Trans>
              </LinkButton>
            )}
          </FooterStyled>
        }
      >
        {!detectedPlatform && (
          <WebStyled data-qa="app-installation-options">
            <div>
              <TextStyled>
                <Trans id="instructionsPage.downloadOptions.qrCode">Scan the QR Code</Trans>
              </TextStyled>
              <LinkBox>
                <ImgStyled src={`${config.apiPrefix}/links/store-qr.svg`} alt="url" />
              </LinkBox>
            </div>
            <StyledOr>
              <Trans id="instructionsPage.or" comment='the "or" in "Scan the QR code or use the store"'>
                or
              </Trans>
            </StyledOr>
            <div>
              <TextStyled>
                <Trans id="instructionsPage.downloadOptions.appStore">Use the store</Trans>
              </TextStyled>
              <LinkBox>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.public2.oneid"
                  title={t({ id: 'finishSignupPage.oneIDinGooglePlay', message: `${branding.brand} in Google Play` })}
                >
                  <ImgStyled src="/google-play-badge.png" alt="Google Play" className="googlePlay" />
                </a>
                <a
                  target="_blank"
                  href="https://apps.apple.com/app/beyon-oneid/id1592996533"
                  title={t({ id: 'finishSignupPage.oneIDinAppStore', message: `${branding.brand} in AppStore` })}
                >
                  <ImgStyled src="/app-store-badge.png" alt="App Store" className="appStore" />
                </a>
              </LinkBox>
            </div>
          </WebStyled>
        )}
        <Unordered
          header={t({ id: 'finishSignupPage.whyHeader', message: 'What are the benefits?' })}
          items={[
            <Trans id="finishSignupPage.listItemAccess">Easy access to e-services</Trans>,
            <Trans id="finishSignupPage.listItemSecureId">Keep your ID securely in digital form</Trans>,
          ]}
        />
      </Form>
    </Template>
  )
}
