// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AbortConsentResponse
 */
export interface AbortConsentResponse {
  /**
   *
   * @type {string}
   * @memberof AbortConsentResponse
   */
  location: string
}

export function AbortConsentResponseFromJSON(json: any): AbortConsentResponse {
  return AbortConsentResponseFromJSONTyped(json, false)
}

export function AbortConsentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbortConsentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    location: json['location'],
  }
}

export function AbortConsentResponseToJSON(value?: AbortConsentResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    location: value.location,
  }
}
