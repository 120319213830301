import { styled, theme } from 'styles/stitches.config'

export const Divider = styled('hr', {
  height: 0,
  width: '100%',
  margin: `${theme.space.t8} 0`,
  overflow: 'hidden',
  borderWidth: theme.borderWidths.hairline,
  borderStyle: 'solid',
  borderColor: theme.colors.strokeDefault,
})
