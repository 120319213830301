import { config } from 'config'
import {
  Configuration,
  ConfigurationParameters,
  ErrorBody,
  ErrorCode,
  InteractionControllerApi,
  Middleware,
  OidcControllerApi,
  ResetPasswordControllerApi,
} from 'oneid-api-frontend'
import { navigateTo } from 'utils'

const baseApiConfigParams: ConfigurationParameters = {
  basePath: config.oidcApiPrefix,
}

export const DEFAULT_ERROR: ErrorBody = {
  code: ErrorCode.InternalError,
  description: 'Unexpected failure',
  status: 500,
}

const errorApiMiddleware: Middleware['post'] = async (context) => {
  if (!context.response.ok) {
    throw await context.response.json().catch(() => {
      throw {
        error: DEFAULT_ERROR,
      }
    })
  }
}

const navigateToLocationMiddleware: Middleware['post'] = async (context) => {
  navigateTo(context.response.url)

  return Promise.resolve()
}

const baseApiConfig = new Configuration({ ...baseApiConfigParams, middleware: [{ post: errorApiMiddleware }] })

export const oidcApi = new OidcControllerApi(baseApiConfig)
export const interactionApi = new InteractionControllerApi(
  new Configuration({ basePath: config.apiPrefix, middleware: [{ post: errorApiMiddleware }] }),
)

export const oidcRedirectApi = new OidcControllerApi(
  new Configuration({
    ...baseApiConfigParams,
    middleware: [
      {
        post: errorApiMiddleware,
      },
      {
        post: navigateToLocationMiddleware,
      },
    ],
  }),
)

export const resetPasswordApi = new ResetPasswordControllerApi(
  new Configuration({ basePath: config.apiPrefix, middleware: [{ post: errorApiMiddleware }] }),
)
