import cx from 'classnames'
import { FC } from 'react'
import { styled, theme } from 'styles/stitches.config'
import { Icon } from '../../icon'

const CheckItemStyled = styled('li', {
  display: 'flex',
  flexFlow: 'row nowrap',
  color: theme.colors.statesSuccessBase,

  '& svg': {
    flex: '0 0 auto',
    marginInlineEnd: theme.space.t8,

    '*': {
      fill: theme.colors.statesSuccessBase,
    },
  },

  '&.invalid': {
    color: theme.colors.textSecondary,

    '& svg *': {
      fill: theme.colors.textSecondary,
    },

    '&.hasError': {
      color: theme.colors.statesErrorBase,

      '& svg *': {
        fill: theme.colors.statesErrorBase,
      },
    },
  },
})

type Props = {
  valid: boolean
  text: string
  hasError?: boolean
}

export const CheckItem: FC<Props> = ({ valid, text, hasError }) => (
  <CheckItemStyled className={cx({ ['invalid']: !valid, ['hasError']: hasError })}>
    <Icon name={valid ? 'Check' : 'Close'} size="small" /> {text}
  </CheckItemStyled>
)
