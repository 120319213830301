// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ResetPasswordNewPasswordResponse
 */
export interface ResetPasswordNewPasswordResponse {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordNewPasswordResponse
   */
  transactionId: string
}

export function ResetPasswordNewPasswordResponseFromJSON(json: any): ResetPasswordNewPasswordResponse {
  return ResetPasswordNewPasswordResponseFromJSONTyped(json, false)
}

export function ResetPasswordNewPasswordResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResetPasswordNewPasswordResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    transactionId: json['transactionId'],
  }
}

export function ResetPasswordNewPasswordResponseToJSON(value?: ResetPasswordNewPasswordResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    transactionId: value.transactionId,
  }
}
