import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Routes } from 'routes'
import { AuthTokenLogin } from './AuthTokenLogin'
import { MobileLogin } from './MobileLogin'
import { PasswordLogin } from './PasswordLogin'
import { TokenLogin } from './TokenLogin'

export const Login: FC = () => (
  <Switch>
    <Route path={Routes.passwordLogin}>
      <PasswordLogin />
    </Route>
    <Route path={Routes.tokenLogin}>
      <TokenLogin />
    </Route>
    <Route path={Routes.authTokenLogin}>
      <AuthTokenLogin />
    </Route>
    <Route path={Routes.mobileLoginIntro}>
      <MobileLogin />
    </Route>
    <Redirect to={Routes.tokenLogin} from={Routes.login} />
  </Switch>
)
