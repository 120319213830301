import { t } from '@lingui/macro'
import { branding } from 'branding'
import {
  CountdownCounter,
  ErrorBox,
  InteractionInfo,
  Steps,
  Template,
  openErrorToast,
  openSuccessToast,
} from 'components'
import { useCountdown } from 'hooks/useCountdown'
import { ConsentStatus } from 'oneid-api-frontend'
import { FC, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Routes } from 'routes'
import { ResetPasswordPathParams, ResetPasswordRouteState } from 'types'
import { useTransaction } from './hooks'

export const ResetPasswordConfirm: FC = () => {
  const { interactionId, transactionId } = useParams<ResetPasswordPathParams>()
  const { transaction, isLoading, isError } = useTransaction(transactionId)
  const history = useHistory()
  const {
    state: { returnUrl },
  } = useLocation<ResetPasswordRouteState>()

  useEffect(() => {
    if (!transaction?.consentStatus) return
    switch (transaction.consentStatus) {
      case ConsentStatus.Granted:
        openSuccessToast(t({ id: 'passwordResetConfirmation.successMessage', message: 'Password has been changed' }))
        history.replace(returnUrl)
        break
      case ConsentStatus.Denied:
        openErrorToast(t({ id: 'passwordResetConfirmation.cancelledMessage', message: 'Change password cancelled' }))
        history.replace(Routes.resetPasswordEmail, history.location.state)
        break
    }
  }, [transaction, history, interactionId, returnUrl])

  const remainingSeconds = useCountdown(transaction?.expiresAt)

  if (isError) {
    return (
      <Template centerVertically>
        <ErrorBox
          error={t({ id: 'passwordResetConfirmation.errorMessage', message: 'Failed to fetch the consent status' })}
        />
      </Template>
    )
  }

  return (
    <Template
      title={t({ id: 'passwordResetConfirmation.title', message: `Confirm change with ${branding.brand} app` })}
      wrapContent
      isLoading={isLoading}
      titleBottomMargin={32}
    >
      {transaction && (
        <>
          {transaction.activeDevice && (
            <InteractionInfo
              user={{
                device: { model: transaction.activeDevice.model, brand: transaction.activeDevice.brand },
              }}
            />
          )}
          <CountdownCounter remainingSeconds={remainingSeconds} />
          {remainingSeconds > 0 && (
            <Steps
              steps={[
                {
                  title: t({
                    id: 'passwordResetConfirmation.openAppStep.label',
                    message: `Open your ${branding.brand} app`,
                  }),
                },
                {
                  title: t({ id: 'passwordResetConfirmation.takeActionStep.label', message: 'Perform liveness check' }),
                },
              ]}
            />
          )}
        </>
      )}
    </Template>
  )
}
