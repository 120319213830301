import { t, Trans } from '@lingui/macro'
import { FC } from 'react'
import { styled, theme } from 'styles/stitches.config'
import { Icon } from '../icon'
import { Countdown } from './Countdown'

const StyledBox = styled('div', {
  boxSizing: 'border-box',
  width: '100%',
  padding: theme.space.t16,
  gap: theme.space.t16,
  position: 'relative',
  borderRadius: theme.space.t8,
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start',
  alignItems: 'start',

  variants: {
    isExpired: {
      true: {
        backgroundColor: theme.colors.statesErrorBackground,
        color: theme.colors.statesErrorBase,
      },
      false: {
        backgroundColor: theme.colors.backgroundSecondary,
      },
    },
    hasMarginBottom: {
      true: {
        marginBottom: theme.space.t40,
      },
      false: {
        marginBottom: 0,
      },
    },
  },

  defaultVariants: {
    hasMarginBottom: true,
    isExpired: false,
  },

  '@mobile': {
    width: '100%',
  },
})

const StyledStateIcon = styled(Icon, {
  borderRadius: theme.space.t20,
  padding: theme.space.t8,
  display: 'flex',

  '& path': {
    fill: theme.colors.iconPrimaryAlt,
  },

  variants: {
    isExpired: {
      true: {
        color: theme.colors.statesErrorBase,
        backgroundColor: theme.colors.statesErrorBase,
      },
      false: {
        backgroundColor: theme.colors.brandSecondary,
      },
    },
  },

  defaultVariants: {
    isExpired: false,
  },
})

const StyledContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space.t4,
  minHeight: 40,
  justifyContent: 'center',
})

const StyledRemainingTimeText = styled('span', {
  fontSize: theme.fontSizes.t14,
  lineHeight: theme.lineHeights.text,
})

export interface CountdownInfoBoxProps {
  remainingSeconds: number
  hasMarginBottom?: boolean
}

export const CountdownInfoBox: FC<CountdownInfoBoxProps> = ({ remainingSeconds, ...rest }) => {
  const isExpired = remainingSeconds <= 0

  return (
    <StyledBox isExpired={isExpired} {...rest}>
      {/* TODO: replace Icon with WarningCircle from Onex UI */}
      <StyledStateIcon name={isExpired ? 'WarningOctagon' : 'HourglassHigh'} isExpired={isExpired} />
      <StyledContent>
        <Countdown
          remainingSeconds={remainingSeconds}
          expirationLabel={t({ id: 'infoBox.timeExpired.label', message: 'Time expired' })}
          isExpired={isExpired}
        />
        {!isExpired && (
          <StyledRemainingTimeText>
            <Trans id="infoBox.remainingTimeText">Time left to approve</Trans>
          </StyledRemainingTimeText>
        )}
      </StyledContent>
    </StyledBox>
  )
}
