import { addSeconds, fromUnixTime, getUnixTime, isAfter } from 'date-fns'
import { isNumber, isString, isStructure, isUndefinedOr } from 'oneid-common'

const assertLocalStorage = () => {
  if (!window.localStorage) {
    throw new Error('Local storage is not available')
  }
}

type StorageRecord = {
  value: string
  ttl?: number
}

const isStorageItem = (data: unknown): data is StorageRecord =>
  isStructure<StorageRecord>({ value: isString, ttl: isUndefinedOr(isNumber) })(data)

export const set = (key: string, value: string, ttl?: number) => {
  assertLocalStorage()

  localStorage.setItem(
    key,
    JSON.stringify(
      ttl === undefined
        ? value
        : {
            value,
            ttl: getUnixTime(addSeconds(Date.now(), ttl)),
          },
    ),
  )
}

export const get = (key: string) => {
  assertLocalStorage()

  const data = window.localStorage.getItem(key)

  if (!data) {
    return null
  }

  try {
    const item: unknown = JSON.parse(data)
    if (!isStorageItem(item)) {
      return data
    }
    if (item.ttl && isAfter(Date.now(), fromUnixTime(item.ttl))) {
      window.localStorage.removeItem(key)
      return null
    }
    return item.value
  } catch {
    return data
  }
}

export const remove = (key: string) => {
  assertLocalStorage()
  window.localStorage.removeItem(key)
}

export const cleanUpExpired = () => {
  assertLocalStorage()
  Object.keys(window.localStorage).forEach(get)
}
