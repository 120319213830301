import { t } from '@lingui/macro'
import { branding } from 'branding'
import { getPlatform } from 'services/platform'

const createConsentSteps = (clientName: string) => ({
  openAppStepDesktop: {
    title: t({ id: 'consentPage.openAppStepMobile.label', message: `Open ${branding.brand} on your phone` }),
  },
  openAppStep: {
    title: t({ id: 'consentPage.openAppStep.label', message: `Open ${branding.brand}` }),
  },
  takeActionStep: {
    title: t({ id: 'consentPage.takeActionStep.label', message: 'Provide consent' }),
  },
  returnBackStep: {
    title: t({ id: 'consentPage.returnBackStep.label', message: `Continue to ${clientName}` }),
  },
})

export const getConsentSteps = (clientName: string) => {
  const { openAppStep, openAppStepDesktop, returnBackStep, takeActionStep } = createConsentSteps(clientName)
  const detectedPlatform = getPlatform()

  return [detectedPlatform ? openAppStep : openAppStepDesktop, takeActionStep, returnBackStep]
}
