import { globalCss, theme } from './stitches.config'

export const globalStyles = globalCss({
  html: {
    height: '100%',
  },
  body: {
    fontSize: '16px',
    lineHeight: theme.lineHeights.paragraph,
    margin: 0,
    height: '100%',
    color: '#000', // TODO theme
    fontWeight: theme.fontWeights.regular,
  },
  'body, button': {
    fontFamily: 'aventa, sans-serif',
  },
  a: {
    color: theme.colors.linkDefault,
    fontWeight: theme.fontWeights.semiBold,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  'h1, h2, h3, h4': {
    margin: 0,
  },
  strong: {
    fontWeight: theme.fontWeights.semiBold,
  },
  '#root': {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',

    '@mobile': {
      justifyContent: 'flex-start',
      height: '100%',
    },
  },
})
