// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ConsentStatus = {
  Granted: 'GRANTED',
  Denied: 'DENIED',
} as const
export type ConsentStatus = (typeof ConsentStatus)[keyof typeof ConsentStatus]

export function ConsentStatusFromJSON(json: any): ConsentStatus {
  return ConsentStatusFromJSONTyped(json, false)
}

export function ConsentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentStatus {
  return json as ConsentStatus
}

export function ConsentStatusToJSON(value?: ConsentStatus | null): any {
  return value as any
}
