// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface AccessTokenErrorResponse
 */
export interface AccessTokenErrorResponse {
  /**
   * A single ASCII [USASCII] error code from the following:
   * - `invalid_request` The request is missing a required parameter, includes an unsupported parameter value (other than grant type), repeats a parameter, includes multiple credentials, utilizes more than one mechanism for authenticating the client, or is otherwise malformed.
   * - `invalid_client` Client authentication failed (e.g., unknown client, no client authentication included, or unsupported authentication method).  The authorization server MAY return an HTTP 401 (Unauthorized) status code to indicate which HTTP authentication schemes are supported.  If the client attempted to authenticate via the "Authorization" request header field, the authorization server MUST respond with an HTTP 401 (Unauthorized) status code and include the "WWW-Authenticate" response header field matching the authentication scheme used by the client.
   * - `invalid_grant` The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.
   * - `unauthorized_client` The authenticated client is not authorized to use this authorization grant type.
   * - `unsupported_grant_type` The authorization grant type is not supported by the authorization server.
   * - `invalid_scope` The requested scope is invalid, unknown, malformed, or exceeds the scope granted by the resource owner.
   * @type {string}
   * @memberof AccessTokenErrorResponse
   */
  error: AccessTokenErrorResponseErrorEnum
  /**
   * Human-readable ASCII [USASCII] text providing additional information, used to assist the client developer in understanding the error that occurred.
   * @type {string}
   * @memberof AccessTokenErrorResponse
   */
  errorDescription?: string
  /**
   * A URI identifying a human-readable web page with information about the error, used to provide the client developer with additional information about the error.
   * @type {string}
   * @memberof AccessTokenErrorResponse
   */
  errorUri?: string
}

/**
 * @export
 */
export const AccessTokenErrorResponseErrorEnum = {
  InvalidRequest: 'invalid_request',
  InvalidClient: 'invalid_client',
  InvalidGrant: 'invalid_grant',
  UnauthorizedClient: 'unauthorized_client',
  UnsupportedGrantType: 'unsupported_grant_type',
  InvalidScope: 'invalid_scope',
} as const
export type AccessTokenErrorResponseErrorEnum =
  (typeof AccessTokenErrorResponseErrorEnum)[keyof typeof AccessTokenErrorResponseErrorEnum]

export function AccessTokenErrorResponseFromJSON(json: any): AccessTokenErrorResponse {
  return AccessTokenErrorResponseFromJSONTyped(json, false)
}

export function AccessTokenErrorResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccessTokenErrorResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    error: json['error'],
    errorDescription: !exists(json, 'error_description') ? undefined : json['error_description'],
    errorUri: !exists(json, 'error_uri') ? undefined : json['error_uri'],
  }
}

export function AccessTokenErrorResponseToJSON(value?: AccessTokenErrorResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    error: value.error,
    error_description: value.errorDescription,
    error_uri: value.errorUri,
  }
}
