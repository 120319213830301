import { datadogRum } from '@datadog/browser-rum'
import { config } from 'config'

export const dataDogInit = () => {
  if (
    !config.dataDog.env ||
    !config.dataDog.version ||
    !config.dataDog.clientToken ||
    !config.dataDog.applicationId ||
    !config.dataDog.site ||
    !config.dataDog.service
  ) {
    return
  }

  datadogRum.init({
    applicationId: config.dataDog.applicationId,
    clientToken: config.dataDog.clientToken,
    site: config.dataDog.site,
    service: config.dataDog.service,
    env: config.dataDog.env,
    version: config.dataDog.version,
    sampleRate: 100,
    trackInteractions: true,
  })
}
