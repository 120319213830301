import parsePhoneNumber, { AsYouType, CountryCode, isValidNumberForRegion } from 'libphonenumber-js'

export type PhoneNumber = {
  isValid: boolean
  nationalNumber: string
  countryCode: CountryCode
  formatted: string
}

export const parsePhoneNumberAsYouType = (phoneNumber: string): PhoneNumber => {
  const asYouTypeParser = new AsYouType()
  asYouTypeParser.input(phoneNumber)

  const parsedPhoneNumber = asYouTypeParser.getNumber()

  if (!parsedPhoneNumber || !parsedPhoneNumber.country || !parsedPhoneNumber.nationalNumber) {
    throw new Error('Failed to parse the phone number')
  }
  return {
    formatted: parsedPhoneNumber.number,
    nationalNumber: parsedPhoneNumber.nationalNumber,
    countryCode: parsedPhoneNumber.country,
    isValid: parsedPhoneNumber.isValid(),
  }
}

export const formatPhoneNumber = (phoneNumber: string): string | undefined => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
  if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
    return parsedPhoneNumber.number
  }
  return undefined
}

export const formatPhoneNumberWithHyphen = (phoneNumber: string): string | undefined => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
  if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
    return `+${parsedPhoneNumber.countryCallingCode}-${parsedPhoneNumber.nationalNumber}`
  }
  return undefined
}

export const isValidPhoneNumberForRegion = (phoneNumber: string, countryCode: string) =>
  isValidNumberForRegion(phoneNumber, countryCode as CountryCode)
