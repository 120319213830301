// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface EkycResponse
 */
export interface EkycResponse {
  /**
   *
   * @type {string}
   * @memberof EkycResponse
   */
  location?: string
}

export function EkycResponseFromJSON(json: any): EkycResponse {
  return EkycResponseFromJSONTyped(json, false)
}

export function EkycResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EkycResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    location: !exists(json, 'location') ? undefined : json['location'],
  }
}

export function EkycResponseToJSON(value?: EkycResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    location: value.location,
  }
}
