import { ErrorBody } from 'oneid-api-frontend'
import { FC } from 'react'
import { getTextForError } from 'services/errors'
import { ErrorBox } from './ErrorBox'
import { Template } from './Template'

type Props = {
  error?: ErrorBody
  errorText?: string
}

export const TemplateError: FC<Props> = ({ error, errorText }) => {
  const text = errorText ?? getTextForError(error)
  return (
    <Template centerVertically>
      <ErrorBox error={text} />
    </Template>
  )
}
