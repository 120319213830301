import { differenceInSeconds } from 'date-fns'
import { useEffect, useState } from 'react'

const getRemainingSeconds = (expirationTime: Date) => differenceInSeconds(expirationTime, new Date())

export const useCountdown = (expiration: Date = new Date()): number => {
  const [remainingSeconds, setRemainingSeconds] = useState(getRemainingSeconds(expiration))

  useEffect(() => setRemainingSeconds(getRemainingSeconds(expiration)), [expiration])

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getRemainingSeconds(expiration)
      setRemainingSeconds(remaining)
      if (remaining <= 0) {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [expiration])

  return remainingSeconds
}
