import { t } from '@lingui/macro'
import { branding } from 'branding'
import { LoginForm, Template, TemplateError } from 'components'
import { FC } from 'react'
import { useOidcLoginController } from './hooks'

export const PasswordLogin: FC = () => {
  const { interaction, error, isExpired, isSubmitting, handleSubmit } = useOidcLoginController()

  if (isExpired)
    return <TemplateError errorText={t({ id: 'signInPage.sessionExpiredError', message: 'Session has expired' })} />
  if (error) return <TemplateError error={error} />

  return (
    <Template
      title={t({ id: 'signInPage.loginWithPassword.title', message: `Sign in to your ${branding.brand} account` })}
      isLoading={!interaction || isSubmitting}
      wrapContent
      titleBottomMargin={44}
    >
      {interaction && <LoginForm onSubmit={handleSubmit} interaction={interaction} withCreateAccount />}
    </Template>
  )
}
