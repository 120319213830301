import {
  CloseIcon,
  DuotoneCrossIcon,
  DuotoneErrorIcon,
  DuotoneInfoIcon,
  DuotoneSuccessIcon,
} from '@beyon-connect/web-icons'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, ReactNode, useMemo } from 'react'
import { ToastOptions, Toaster, toast } from 'react-hot-toast'
import { CSS, theme } from 'styles/stitches.config'
import { CloseIconContainer, Description, IconContainer, MessageContainer, Title, ToastContainer } from './Toast.styled'

// TODO: remove after moved back into OneX UI
const StatusType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
} as const

type ValueOf<T> = T[keyof T]

// TODO: remove after moved back into OneX UI
type StatusType = ValueOf<typeof StatusType>

// TODO: remove after moved back into OneX UI
const getSemanticIcon = (type: StatusType) =>
  ({
    [StatusType.ERROR]: DuotoneCrossIcon,
    [StatusType.INFO]: DuotoneInfoIcon,
    [StatusType.SUCCESS]: DuotoneSuccessIcon,
    [StatusType.WARNING]: DuotoneErrorIcon,
  })[type]

export type ToastProps = {
  type: StatusType
  title?: ReactNode
  message?: ReactNode
  hasIcon?: boolean
  width?: CSS['width']
  minWidth?: CSS['minWidth']
  maxWidth?: CSS['maxWidth']
  css?: CSS
  closeToast?: () => void
}

export const ToastMessage: FC<ToastProps> = ({
  type,
  hasIcon = true,
  title,
  message,
  css,
  closeToast,
  width = 500,
  maxWidth,
  minWidth,
  ...props
}) => {
  if (!title && !message) {
    throw new Error('Toast must contain title, message or both!')
  }

  const SemanticIcon = useMemo(() => getSemanticIcon(type), [type])

  return (
    <ToastContainer data-testid="onexui-toast" type={type} css={{ width, maxWidth, minWidth, ...css }} {...props}>
      {hasIcon && (
        <IconContainer>
          <SemanticIcon size={theme.sizes.t20.value} data-testid={`onexui-toast-icon-${type}`} />
        </IconContainer>
      )}
      <MessageContainer>
        {title && <Title data-testid="onexui-toast-title">{title}</Title>}
        {message && <Description data-testid="onexui-toast-description">{message}</Description>}
      </MessageContainer>
      {closeToast && (
        <CloseIconContainer onClick={closeToast}>
          <CloseIcon size={theme.sizes.t20.value} />
        </CloseIconContainer>
      )}
    </ToastContainer>
  )
}

type PropsWithQA<T> = T & { dataQa?: string }

const openToast = ({ dataQa, ...props }: PropsWithQA<ToastProps>, options?: ToastOptions) =>
  toast.custom(
    (t) => (
      <AnimatePresence>
        {t.visible && (
          <motion.div initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -100, opacity: 0 }}>
            <ToastMessage {...t.ariaProps} {...props} data-qa={dataQa} closeToast={() => toast.dismiss(t.id)} />
          </motion.div>
        )}
      </AnimatePresence>
    ),
    options,
  )

export const OneXUIToast = {
  /** Static toast message for inline use */
  ToastMessage,
  /** Callback that opens new toast message */
  openToast,
  /** Root for rendering toast */
  Toaster,
}
