import { OneOf } from './types'

const PrefixQrCode = {
  v1: 'v1',
} as const
type PrefixQrCode = OneOf<typeof PrefixQrCode>

// not part of base64
const separator = ':'

/**
 * Returns prefixed data for new qr code
 * @param data - data to be stored in qr code
 */
export const getNewestPrefixedQrCode = (data: string) => `${PrefixQrCode.v1}${separator}${data}`

/**
 * Return true if userKey was created with v1
 * @param userKey - data loaded from qr code
 */
export const isV1UserKey = (userKey: string) => userKey.startsWith(PrefixQrCode.v1)

/**
 * Get plain data from any QR code version
 * @param qrCodeData - data loaded from qr code
 */
export const getUserKeyPlainData = (qrCodeData: string) =>
  (isV1UserKey(qrCodeData) && qrCodeData.split(separator)[1]) || qrCodeData
