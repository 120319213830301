import { forwardRef } from 'react'
import { ChangeHandler, FieldError } from 'react-hook-form'
import { styled, theme } from 'styles/stitches.config'
import { Icon } from '../icon'

const IconWrapperStyled = styled('div', {
  position: 'absolute',
  top: '0',
  left: '0',
  borderRadius: theme.space.t4,
  borderWidth: theme.borderWidths.hairline,
  borderStyle: 'solid',
  borderColor: theme.colors.strokeAlt,
  backgroundColor: theme.colors.backgroundSurface,
  width: theme.space.t20,
  height: theme.space.t20,
  cursor: 'pointer',

  '&& svg': {
    // Avoid icon size classes to change the size
    width: theme.space.t20,
    height: theme.space.t20,
  },
})

const CheckboxFieldStyled = styled('div', {
  marginBottom: theme.space.t32,
})

const LabelStyled = styled('label', {
  fontSize: theme.fontSizes.t14,
  color: theme.colors.textPrimary,
  cursor: 'pointer',
  userSelect: 'none',
})

const CheckboxStyled = styled('div', {
  position: 'relative',
  display: 'inline-flex',
  marginInlineEnd: theme.space.t16,
  width: theme.space.t20,
  height: theme.space.t20,
})

const InputStyled = styled('input', {
  [`& + ${IconWrapperStyled.selector} svg`]: {
    display: 'none',
  },

  [`&:checked + ${IconWrapperStyled.selector}`]: {
    borderColor: theme.colors.componentDefault,
    background: theme.colors.componentDefault,

    '& svg': {
      display: 'block',

      '& path': {
        fill: theme.colors.iconPrimaryAlt,
      },
    },
  },
})

const ErrorMessageStyled = styled('span', {
  marginLeft: theme.space.t8,
  color: theme.colors.statesErrorBase,
})

type CheckboxProps = {
  label: string
  name: string
  value?: string
  error?: FieldError
  onChange?: ChangeHandler
}

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxProps>(({ label, name, error, onChange }, ref) => (
  <CheckboxFieldStyled>
    <LabelStyled>
      <CheckboxStyled>
        <InputStyled ref={ref} name={name} type="checkbox" onChange={onChange} data-qa={`checkbox-${name}`} />
        <IconWrapperStyled data-qa={`checkbox-${name}-icon`}>
          <Icon name="Check" />
        </IconWrapperStyled>
      </CheckboxStyled>
      <span>{label}</span>
    </LabelStyled>
    {error && <ErrorMessageStyled>{error.message}</ErrorMessageStyled>}
  </CheckboxFieldStyled>
))
