import cx from 'classnames'
import { FC, ReactNode } from 'react'
import { styled, theme } from 'styles/stitches.config'

const StepsStyled = styled('ol', {
  listStyle: 'none',
  position: 'relative',
  margin: '0',
  padding: '0',

  '&:before': {
    position: 'absolute',
    top: theme.space.t32,
    bottom: theme.space.t48,
    marginInlineStart: theme.space.t20,
    width: '2px',
    backgroundColor: theme.colors.brandSecondary,
    content: '',
    zIndex: '0',
  },

  '@mobile': {
    width: '100%',
  },
})

const StepStyled = styled('li', {
  padding: '0',
  position: 'relative',
  zIndex: 1,
  marginBottom: theme.space.t24,
})

const HeadStyled = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
})

const TitleWrapperStyled = styled('div', {
  flex: '1 1 auto',

  '& > a > img': {
    display: 'block',
    height: theme.space.t48,
  },
})

const TitleStyled = styled('h3', {
  margin: 0,
  paddingTop: theme.space.t8,
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes.h3,
  lineHeight: theme.space.t24,
})

const SubtitleStyled = styled('span', {
  display: 'block',
  lineHeight: '130%',
})

const StepNumberStyled = styled('div', {
  boxSizing: 'border-box',
  flex: '0 0',
  flexBasis: theme.space.t40,
  height: theme.space.t40,
  marginInlineEnd: theme.space.t20,
  backgroundColor: theme.colors.backgroundSurface,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: theme.colors.brandSecondary,
  borderRadius: theme.space.t20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StepNumberContentStyled = styled('span', {
  fontSize: theme.fontSizes.h3,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.space.t20,
  color: theme.colors.brandSecondary,
})

const ChildrenStyled = styled('div', {
  marginInlineStart: theme.space.t60,
})

interface StepProps {
  step?: number
  completed?: boolean
  title?: string
  subtitle?: string
  addition?: ReactNode
  children?: ReactNode
}

export interface StepsProps {
  steps: StepProps[]
  className?: string
}

const Step: FC<StepProps> = ({ step, title, subtitle, completed, addition, children }) => (
  <StepStyled>
    <HeadStyled>
      <StepNumberStyled>
        {completed ? (
          'done'
        ) : completed === undefined ? (
          <StepNumberContentStyled>{step}</StepNumberContentStyled>
        ) : (
          'in progress'
        )}
      </StepNumberStyled>
      <TitleWrapperStyled>
        {title && <TitleStyled>{title}</TitleStyled>}
        {subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
        {addition}
      </TitleWrapperStyled>
    </HeadStyled>
    <ChildrenStyled>{children}</ChildrenStyled>
  </StepStyled>
)

export const Steps: FC<StepsProps> = ({ steps, className }) => (
  <StepsStyled className={cx(className)}>
    {steps.map((item, i) => (
      <Step
        key={item.title}
        step={i + 1}
        title={item.title}
        subtitle={item.subtitle}
        completed={item.completed}
        addition={item.addition}
      >
        {item.children}
      </Step>
    ))}
  </StepsStyled>
)
