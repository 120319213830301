// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ErrorCode, ErrorCodeFromJSON, ErrorCodeToJSON } from './'

/**
 *
 * @export
 * @interface ErrorBody
 */
export interface ErrorBody {
  /**
   *
   * @type {ErrorCode}
   * @memberof ErrorBody
   */
  code: ErrorCode
  /**
   *
   * @type {string}
   * @memberof ErrorBody
   */
  description: string
  /**
   *
   * @type {number}
   * @memberof ErrorBody
   */
  status: number
}

export function ErrorBodyFromJSON(json: any): ErrorBody {
  return ErrorBodyFromJSONTyped(json, false)
}

export function ErrorBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    code: ErrorCodeFromJSON(json['code']),
    description: json['description'],
    status: json['status'],
  }
}

export function ErrorBodyToJSON(value?: ErrorBody | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    code: ErrorCodeToJSON(value.code),
    description: value.description,
    status: value.status,
  }
}
