import { t, Trans } from '@lingui/macro'
import { openErrorToast, Template, TemplateError, TokenLoginForm } from 'components'
import { AuthPromptEnum } from 'oneid-api-frontend'
import { FC, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { getChangePromptUrl } from 'services/oidc'
import { useOidcTokenLoginController } from './hooks'

export const TokenLogin: FC = () => {
  const { error, isExpired, isSubmitting, handleSubmit, tokens, interaction, passwordRoute, requestError } =
    useOidcTokenLoginController()

  // TODO: can this be moved to useOidcTokenLoginController?
  useEffect(() => {
    if (requestError) {
      openErrorToast(requestError.description)
    }
  }, [requestError])

  if (isExpired)
    return <TemplateError errorText={t({ id: 'signInPage.sessionExpiredError', message: 'Session has expired' })} />
  if (error) return <TemplateError error={error} />

  return tokens.length > 0 ? (
    <Template
      title={t({ id: 'signInPage.loginToSavedAccount.title', message: 'Choose your account' })}
      isLoading={!interaction || isSubmitting}
      wrapContent
      titleBottomMargin={44}
    >
      {interaction && <TokenLoginForm onSubmit={handleSubmit} tokens={tokens} passwordLoginRoute={passwordRoute} />}
      {interaction && (
        <a href={getChangePromptUrl(interaction.id, AuthPromptEnum.Create)}>
          <Trans id="signInPage.newAccountLink.label">Create account</Trans>
        </a>
      )}
    </Template>
  ) : (
    <Redirect to={passwordRoute} />
  )
}
