// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface AccessTokenSuccessfulResponse
 */
export interface AccessTokenSuccessfulResponse {
  /**
   * The access token issued by the authorization server
   * @type {string}
   * @memberof AccessTokenSuccessfulResponse
   */
  accessToken: string
  /**
   * The lifetime in seconds of the access token.  For example, the value "3600" denotes that the access token will expire in one hour from the time the response was generated. If omitted, the authorization server SHOULD provide the expiration time via other means or document the default value.
   * @type {number}
   * @memberof AccessTokenSuccessfulResponse
   */
  expiresIn?: number
  /**
   * ID Token value associated with the authenticated session. The ID Token is represented as a JSON Web Token (JWT)
   * @type {string}
   * @memberof AccessTokenSuccessfulResponse
   */
  idToken: string
  /**
   * The scope of the access token. OPTIONAL, if identical to the scope requested by the client; otherwise, REQUIRED.
   * @type {string}
   * @memberof AccessTokenSuccessfulResponse
   */
  scope?: string
  /**
   * The type of the token issued. Value is case insensitive. The access token type provides the client with the information required to successfully utilize the access token to make a protected resource request (along with type-specific attributes).  The client MUST NOT use an access token if it does not understand the token type.
   *
   * The OAuth 2.0 token_type response parameter value MUST be Bearer, as specified in OAuth 2.0 Bearer Token Usage [RFC6750], unless another Token Type has been negotiated with the Client. Servers SHOULD support the Bearer Token Type; use of other Token Types is outside the scope of this specification.
   * @type {string}
   * @memberof AccessTokenSuccessfulResponse
   */
  tokenType: string
}

export function AccessTokenSuccessfulResponseFromJSON(json: any): AccessTokenSuccessfulResponse {
  return AccessTokenSuccessfulResponseFromJSONTyped(json, false)
}

export function AccessTokenSuccessfulResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccessTokenSuccessfulResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    accessToken: json['access_token'],
    expiresIn: !exists(json, 'expires_in') ? undefined : json['expires_in'],
    idToken: json['id_token'],
    scope: !exists(json, 'scope') ? undefined : json['scope'],
    tokenType: json['token_type'],
  }
}

export function AccessTokenSuccessfulResponseToJSON(value?: AccessTokenSuccessfulResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    access_token: value.accessToken,
    expires_in: value.expiresIn,
    id_token: value.idToken,
    scope: value.scope,
    token_type: value.tokenType,
  }
}
