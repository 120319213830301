import { Icon as IconComponent } from '@beyon-connect/onex-ui-web'
import { styled, theme } from 'styles/stitches.config'

const Email = styled('span', {
  textAlign: 'left',
  overflow: 'hidden',
  wordBreak: 'break-all',

  fontSize: theme.fontSizes.base,
  fontWeight: theme.fontWeights.medium,
  lineHeight: theme.lineHeights.paragraph,
})

const Note = styled('span', {
  fontSize: theme.fontSizes.t12,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.text,
  color: theme.colors.textSubdued,
  marginTop: theme.space.t4,
})

const TextWrapper = styled('div', {
  marginInline: theme.space.t16,
  overflow: 'hidden',
  textAlign: 'start',
  width: '100%',
})

const IconWrapper = styled('div', {
  width: theme.space.t40,
  height: theme.space.t40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.space.t20,
  flex: '0 0',
  flexBasis: theme.space.t40,
  backgroundColor: theme.colors.backgroundPrimary,

  '& svg': {
    color: theme.colors.strokeAlt,
  },
})

const Icon = styled(IconComponent, {
  flexShrink: 0,
})

const Button = styled('button', {
  border: `1px solid ${theme.colors.strokeDefault}`,
  borderRadius: theme.space.t8,
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  padding: theme.space.t16,
  overflow: 'hidden',
  backgroundColor: 'unset',
})

const TokenLoginFormBody = styled('ul', {
  margin: `${theme.space.t16} 0 ${theme.space.t32} 0`,
  listStyle: 'none',
  padding: 0,

  '& li': {
    marginBottom: theme.space.t16,
  },
})

export const StyledTokenLoginForm = {
  TokenLoginFormBody,
  Button,
  Icon,
  IconWrapper,
  TextWrapper,
  Email,
  Note,
}
