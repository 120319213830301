import { ReactComponent as Avatar } from 'assets/Avatar.svg'
import { ReactComponent as BagSimple } from 'assets/BagSimple.svg'
import { ReactComponent as Card } from 'assets/Card.svg'
import { ReactComponent as CaretDown } from 'assets/CaretDown.svg'
import { ReactComponent as CaretLeft } from 'assets/CaretLeft.svg'
import { ReactComponent as CaretRight } from 'assets/CaretRight.svg'
import { ReactComponent as Check } from 'assets/Check.svg'
import { ReactComponent as Close } from 'assets/Close.svg'
import { ReactComponent as DeviceMobile } from 'assets/DeviceMobile.svg'
import { ReactComponent as ErrorBig } from 'assets/ErrorBig.svg'
import { ReactComponent as Eye } from 'assets/Eye.svg'
import { ReactComponent as EyeSlash } from 'assets/EyeSlash.svg'
import { ReactComponent as Globe } from 'assets/Globe.svg'
import { ReactComponent as HourglassHigh } from 'assets/HourglassHigh.svg'
import { ReactComponent as Loading } from 'assets/Loading.svg'
import { ReactComponent as Lock } from 'assets/Lock.svg'
import { ReactComponent as Menu } from 'assets/Menu.svg'
import { ReactComponent as Success } from 'assets/Success.svg'
import { ReactComponent as User } from 'assets/User.svg'
import { ReactComponent as WarningOctagon } from 'assets/WarningOctagon.svg'
import { ReactComponent as Wifi } from 'assets/Wifi.svg'
import cx from 'classnames'
import { FC } from 'react'
import { styled, theme } from 'styles/stitches.config'

const IconWrapperStyled = styled('span', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& .extraBig': {
    width: theme.space.t88,
    height: theme.space.t88,
  },

  // TODO: temp solution. Icon should be refactored and removed in favor of OneX UI
  '& .t40': {
    width: theme.space.t40,
    height: theme.space.t40,
  },

  '& .big': {
    width: theme.space.t32,
    height: theme.space.t32,
  },

  '& .medium': {
    width: theme.space.t24,
    height: theme.space.t24,
  },

  '& .small': {
    width: theme.space.t16,
    height: theme.space.t16,
  },
})

type IconNames = keyof typeof Icons

export interface IconProps {
  name: IconNames
  size?: 'small' | 'medium' | 'big' | 't40' | 'extraBig'
  onClick?: () => void
  className?: string
}

const Icons = {
  Avatar,
  BagSimple,
  DeviceMobile,
  Globe,
  CaretDown,
  CaretLeft,
  CaretRight,
  Card,
  Check,
  Close,
  Eye,
  EyeSlash,
  HourglassHigh,
  Loading,
  Lock,
  Menu,
  User,
  WarningOctagon,
  Wifi,
  Success,
  ErrorBig,
}

export const Icon: FC<IconProps> = ({ name, size = 'medium', onClick, className }) => {
  const IconComponent = Icons[name]

  return (
    <IconWrapperStyled>
      <IconComponent className={cx(size, className)} onClick={onClick} />
    </IconWrapperStyled>
  )
}
