import { Icon } from '@beyon-connect/onex-ui-web'
import { FC, ReactNode } from 'react'
import { styled, theme } from 'styles/stitches.config'

const HeaderStyled = styled('h3', {
  fontSize: theme.space.t12,
  fontWeight: theme.fontWeights.bold,
  lineHeight: theme.lineHeights.heading,
  textTransform: 'uppercase',
})

const ListStyled = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space.t16,
  listStyle: 'none',
  padding: '0',
})

const ListItemStyled = styled('li', {
  display: 'flex',
  lineHeight: theme.lineHeights.paragraph,
})

const DotStyled = styled('span', {
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.space.t20,
  height: theme.space.t20,
  borderRadius: theme.radii.round,
  backgroundColor: theme.colors.brandAccent,
  marginInlineEnd: theme.space.t16,
})

type Props = {
  header?: ReactNode
  items: ReactNode[]
}

export const Unordered: FC<Props> = ({ header, items }) => (
  <>
    {header && <HeaderStyled>{header}</HeaderStyled>}
    <ListStyled>
      {items.map((item, index) => (
        <ListItemStyled key={index}>
          <DotStyled>
            <Icon name="CheckIcon" size="t12" color="iconPrimaryAlt" />
          </DotStyled>
          <span>{item}</span>
        </ListItemStyled>
      ))}
    </ListStyled>
  </>
)
