// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ErrorBody, ErrorBodyFromJSON, ErrorBodyToJSON } from './'

/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {ErrorBody}
   * @memberof ErrorResponse
   */
  error: ErrorBody
}

export function ErrorResponseFromJSON(json: any): ErrorResponse {
  return ErrorResponseFromJSONTyped(json, false)
}

export function ErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    error: ErrorBodyFromJSON(json['error']),
  }
}

export function ErrorResponseToJSON(value?: ErrorResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    error: ErrorBodyToJSON(value.error),
  }
}
