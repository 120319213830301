// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Device, DeviceFromJSON, DeviceToJSON } from './'

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {Device}
   * @memberof User
   */
  device?: Device
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false)
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
  if (json === undefined || json === null) {
    return json
  }
  return {
    device: !exists(json, 'device') ? undefined : DeviceFromJSON(json['device']),
  }
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    device: DeviceToJSON(value.device),
  }
}
