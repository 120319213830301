import { ReactComponent as BeyonLogo } from 'assets/beyon-connect-logo.svg'
import { ReactComponent as OneIDLogo } from 'assets/oneid-symbol-on-dark.svg'
import { styled, theme } from 'styles/stitches.config'

const BeyonLogoStyled = styled(BeyonLogo, {
  marginTop: theme.space.t8,
  height: theme.space.t36,

  variants: {
    isMobile: {
      true: {
        marginBottom: theme.space.t44,
      },
    },
  },
})

const OneIDLogoStyled = styled(OneIDLogo, {
  height: theme.space.t24,
  marginInlineEnd: theme.space.t16,
})

const TextStyled = styled('span', {
  fontSize: theme.fontSizes.t14,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledOr = styled(TextStyled, {
  paddingBlock: `${theme.space.t40} 0`,
  paddingInline: theme.space.t56,
  fontWeight: theme.fontWeights.semiBold,
})

const FooterStyled = styled('div', {
  width: '100%',
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
})

const FooterTextStyled = styled('span', {
  fontSize: theme.fontSizes.t12,
  marginTop: theme.space.t16,
  color: theme.colors.textSecondary,
})

const WebStyled = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',
  marginBottom: theme.space.t20,
})

const ImgStyled = styled('img', {
  display: 'block',

  '&.googlePlay': {
    width: '100%',
  },

  '&.appStore': {
    width: '90%',
    marginTop: theme.space.t8,
  },
})

const LinkBox = styled('div', {
  padding: theme.space.t8,
  border: '1px solid',
  borderColor: theme.colors.strokeAlt,
  borderRadius: theme.radii.t8,
})

export const StyledFinishWebSignup = {
  BeyonLogo,
  BeyonLogoStyled,
  FooterStyled,
  FooterTextStyled,
  ImgStyled,
  LinkBox,
  StyledOr,
  TextStyled,
  WebStyled,
  OneIDLogoStyled,
}
