import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { createOpenErrorToast, Form, PasswordField, SubmitButton, Template } from 'components'
import { config } from 'config'
import { useSubmitAction } from 'hooks/useSubmitAction'
import { FC, useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { Routes } from 'routes'
import { password } from 'services/validators'
import { ResetPasswordPathParams } from 'types'
import { resetPasswordApi } from 'utils/apis'
import * as yup from 'yup'
import { useResetPassword } from './ResetPasswordContext'

type FormData = {
  password: string
}

const validator = yup.object({
  password: password(config.passwordMinLenght).required(),
})

export const ResetPasswordNewPassword: FC = () => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validator),
  })
  const history = useHistory()
  const { resetPasswordId } = useParams<ResetPasswordPathParams>()
  const passwordFieldValue = watch('password')
  const { completeSession } = useResetPassword()

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async (data) => {
      const { transactionId } = await resetPasswordApi.resetPasswordNewPassword({
        resetPasswordId,
        resetPasswordNewPasswordRequest: { newPassword: data.password },
      })
      completeSession()
      history.replace(generatePath(Routes.resetPasswordConfirm, { transactionId }), history.location.state)
    },
    [resetPasswordId, completeSession, history],
  )

  const [submit, isSubmitting] = useSubmitAction<FormData>(onSubmit, createOpenErrorToast())

  return (
    <Template
      title={t({ id: 'resetPassword.newPasswordPage.title', message: 'New password' })}
      subtitle={t({ id: 'resetPassword.newPasswordPage.subtitle', message: 'Set your password to continue' })}
      wrapContent
      titleBottomMargin={24}
    >
      <Form
        onSubmit={handleSubmit(submit)}
        footer={
          <SubmitButton disabled={isSubmitting}>
            <Trans id="resetPassword.newPasswordPage.submitButton.label">Create password</Trans>
          </SubmitButton>
        }
      >
        <PasswordField
          label={t({ id: 'loginForm.passwordField.label', message: 'Password' })}
          {...register('password')}
          value={passwordFieldValue}
          error={errors.password}
          showStrength
        />
      </Form>
    </Template>
  )
}
