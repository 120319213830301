// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InteractionPasswordLoginBodyAllOf
 */
export interface InteractionPasswordLoginBodyAllOf {
  /**
   * User e-mail
   * @type {string}
   * @memberof InteractionPasswordLoginBodyAllOf
   */
  email: string
  /**
   * User password
   * @type {string}
   * @memberof InteractionPasswordLoginBodyAllOf
   */
  password: string
}

export function InteractionPasswordLoginBodyAllOfFromJSON(json: any): InteractionPasswordLoginBodyAllOf {
  return InteractionPasswordLoginBodyAllOfFromJSONTyped(json, false)
}

export function InteractionPasswordLoginBodyAllOfFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InteractionPasswordLoginBodyAllOf {
  if (json === undefined || json === null) {
    return json
  }
  return {
    email: json['email'],
    password: json['password'],
  }
}

export function InteractionPasswordLoginBodyAllOfToJSON(value?: InteractionPasswordLoginBodyAllOf | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    email: value.email,
    password: value.password,
  }
}
