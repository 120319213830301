import { FC } from 'react'
import { CountdownExpirationInstruction, CountdownExpirationInstructionProps } from './CountdownExpirationInstruction'
import { CountdownInfoBox, CountdownInfoBoxProps } from './CountdownInfoBox'

type CountdownCounterProps = Pick<CountdownInfoBoxProps, 'remainingSeconds'> & CountdownExpirationInstructionProps

export const CountdownCounter: FC<CountdownCounterProps> = ({ remainingSeconds, clientName }) => {
  const isExpired = remainingSeconds <= 0

  return (
    <>
      <CountdownInfoBox remainingSeconds={remainingSeconds} hasMarginBottom={!isExpired} />
      {isExpired && <CountdownExpirationInstruction clientName={clientName} />}
    </>
  )
}
