import { yupResolver } from '@hookform/resolvers/yup'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import {
  CheckboxField,
  createOpenErrorToast,
  Form,
  PasswordField,
  SubmitButton,
  Template,
  TemplateError,
  TermsOfUseLink,
  TextField,
} from 'components'
import { useInteraction } from 'hooks/useInteraction'
import { useIsExpiredDate } from 'hooks/useIsExpiredDate'
import { useSubmitAction } from 'hooks/useSubmitAction'
import { AuthPromptEnum } from 'oneid-api-frontend'
import { FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { getChangePromptUrl, signupOIDC } from 'services/oidc'
import { connectFlowSignup as connectFlowSignupValidator } from 'services/validators'
import { theme } from 'styles/stitches.config'
import { navigateTo } from 'utils'

type FormData = {
  email: string
  password: string
  remember: boolean
}

export const ConnectFlowSignup: FC = () => {
  const { interaction, error: loadInteractionError } = useInteraction()

  const isExpired = useIsExpiredDate(interaction?.expiresAt)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(connectFlowSignupValidator()) })

  const [onSubmit, isSubmitting] = useSubmitAction(
    useCallback(
      async ({ email, password, remember }: FormData) => {
        if (!interaction) {
          return
        }

        const activeLocale = i18n.locale

        const redirectUrl = await signupOIDC(interaction.id, {
          email,
          password,
          issueToken: remember,
          activeLocale,
        })
        navigateTo(redirectUrl)
      },
      [interaction],
    ),
    createOpenErrorToast('toast-error'),
  )

  const passwordFieldValue = watch('password')

  if (isExpired)
    return <TemplateError errorText={t({ id: 'signupPage.sessionExpiredError', message: 'Session has expired' })} />

  if (loadInteractionError) return <TemplateError error={loadInteractionError} />

  return (
    <Template
      title={t({ id: 'signupPage.title', message: 'Create your account' })}
      isLoading={isSubmitting}
      wrapContent
      titleBottomMargin={44}
    >
      {interaction && (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          footer={
            <>
              <TermsOfUseLink />
              <SubmitButton disabled={isSubmitting}>
                <Trans id="signupPage.signupButton.label">Sign up</Trans>
              </SubmitButton>
            </>
          }
        >
          <TextField
            label={t({ id: 'signupPage.emailField.label', message: 'E-mail' })}
            placeholder="john.doe@gmail.com"
            type="email"
            {...register('email')}
            error={errors.email}
            marginBottom={theme.space.t16}
          />
          <PasswordField
            label={t({ id: 'signupPage.passwordField.label', message: 'Password' })}
            {...register('password')}
            value={passwordFieldValue}
            error={errors.password}
            showStrength
            marginBottom={theme.space.t12}
          />
          <CheckboxField
            label={t({ id: 'signupPage.checkboxField.label', message: 'Remember this device' })}
            {...register('remember')}
          />
          <a href={getChangePromptUrl(interaction.id, AuthPromptEnum.Login)}>
            <Trans id="signupPage.alreadyHaveAccountLink.label">I already have an account</Trans>
          </a>
        </Form>
      )}
    </Template>
  )
}
