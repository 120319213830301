// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface Client
 */
export interface Client {
  /**
   *
   * @type {string}
   * @memberof Client
   */
  clientUrl?: string
  /**
   *
   * @type {string}
   * @memberof Client
   */
  logo?: string
  /**
   *
   * @type {string}
   * @memberof Client
   */
  name: string
}

export function ClientFromJSON(json: any): Client {
  return ClientFromJSONTyped(json, false)
}

export function ClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Client {
  if (json === undefined || json === null) {
    return json
  }
  return {
    clientUrl: !exists(json, 'clientUrl') ? undefined : json['clientUrl'],
    logo: !exists(json, 'logo') ? undefined : json['logo'],
    name: json['name'],
  }
}

export function ClientToJSON(value?: Client | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    clientUrl: value.clientUrl,
    logo: value.logo,
    name: value.name,
  }
}
