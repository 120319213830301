// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ResetPasswordNewPasswordRequest
 */
export interface ResetPasswordNewPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordNewPasswordRequest
   */
  newPassword: string
}

export function ResetPasswordNewPasswordRequestFromJSON(json: any): ResetPasswordNewPasswordRequest {
  return ResetPasswordNewPasswordRequestFromJSONTyped(json, false)
}

export function ResetPasswordNewPasswordRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResetPasswordNewPasswordRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    newPassword: json['newPassword'],
  }
}

export function ResetPasswordNewPasswordRequestToJSON(value?: ResetPasswordNewPasswordRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    newPassword: value.newPassword,
  }
}
