import { format, lightFormat } from 'date-fns'

/**
 * Format date to readable with time
 * @param date
 */
export const dateToFormattedString = (date: Date) => lightFormat(date, 'yyyy-MM-dd h:mm a')

/**
 * Format time from given date
 */
export const formatAsTime = (date: Date) => format(date, 'HH:mm:SS')
