import { isNumber } from '../typechecks'

const MINUTES_MILLISECONDS = 60 * 1000
const DAY_MILLISECONDS = 24 * 60 * MINUTES_MILLISECONDS

const getTimezoneShift = (timezoneOffset: number) => timezoneOffset * MINUTES_MILLISECONDS
const getCurrentTimezoneShift = () => new Date().getTimezoneOffset()
const getDateAsNumber = (date: number | Date) => (isNumber(date) ? date : date.getTime())

export const getStartOfDay = (date: number | Date, timezoneOffset = getCurrentTimezoneShift()) =>
  new Date(Math.floor(getDateAsNumber(date) / DAY_MILLISECONDS) * DAY_MILLISECONDS + getTimezoneShift(timezoneOffset))

export const getEndOfDay = (date: number | Date, timezoneOffset = getCurrentTimezoneShift()) =>
  new Date(
    Math.floor(getDateAsNumber(date) / DAY_MILLISECONDS) * DAY_MILLISECONDS +
      DAY_MILLISECONDS -
      1 +
      getTimezoneShift(timezoneOffset),
  )

export const formatSeconds = (seconds: number) => {
  const minuteFraction = Math.floor(seconds / 60)
  const secondFraction = seconds % 60
  return `${minuteFraction}:${secondFraction.toString().padStart(2, '0')}`
}
