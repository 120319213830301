import { useInteractionDetail } from 'hooks/useInteractionDetail'
import { useIsExpiredDate } from 'hooks/useIsExpiredDate'
import { AuthType, ErrorBody, ErrorCode } from 'oneid-api-frontend'
import { useCallback, useState } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { Routes } from 'routes'
import { isErrorResponse } from 'services/errors'
import { createAndStoreKeyPair } from 'services/security'
import { useTokens } from 'services/tokens'
import { LoginFormData, TokenLoginSubmitData, UidPathParams } from 'types'
import { DEFAULT_ERROR, interactionApi } from 'utils/apis'

export const useSamlLoginController = () => {
  const history = useHistory()
  const { interaction, error } = useInteractionDetail(AuthType.Saml)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const isExpired = useIsExpiredDate(interaction?.expiresAt)

  const handleSubmit = useCallback(
    async ({ email, password, remember }: LoginFormData) => {
      if (!interaction) return

      setIsSubmitting(true)
      try {
        await interactionApi.interactionLogin({
          authType: AuthType.Saml,
          interactionId: interaction.id,
          interactionPasswordLoginBody: {
            issueToken: remember,
            email,
            password,
            publicKey: await createAndStoreKeyPair(),
          },
        })
      } finally {
        setIsSubmitting(false)
      }

      history.push(generatePath(Routes.samlConsent, { uid: interaction.id }))
    },
    [history, interaction],
  )

  return { interaction, error, isSubmitting, isExpired, handleSubmit }
}

export const useSamlTokenLoginController = () => {
  const history = useHistory()
  const match = useRouteMatch<UidPathParams>()
  const passwordRoute = generatePath(Routes.samlLoginPassword, { uid: match.params.uid })
  const { tokens, removeToken } = useTokens()
  const { interaction, error } = useInteractionDetail(AuthType.Saml)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const isExpired = useIsExpiredDate(interaction?.expiresAt)
  const [requestError, setRequestError] = useState<ErrorBody | undefined>()

  const handleSubmit = useCallback(
    async ({ email, token }: TokenLoginSubmitData) => {
      if (!interaction || isSubmitting) return

      setIsSubmitting(true)
      try {
        await interactionApi.interactionTokenLogin({
          authType: AuthType.Saml,
          interactionId: interaction.id,
          interactionTokenLoginBody: {
            signInToken: token,
            publicKey: await createAndStoreKeyPair(),
            issueToken: true,
          },
        })
        history.push(generatePath(Routes.samlConsent, { uid: interaction.id }))
      } catch (err: unknown) {
        if (isErrorResponse(err)) {
          if (err.error.code === ErrorCode.InvalidToken) {
            history.push(`${passwordRoute}?email=${encodeURIComponent(email)}`)
            removeToken(token)
          } else {
            setRequestError(err.error)
          }
        } else {
          setRequestError(DEFAULT_ERROR)
        }
      } finally {
        setIsSubmitting(false)
      }
    },
    [history, interaction, isSubmitting, passwordRoute, removeToken],
  )

  return { interaction, error, isSubmitting, isExpired, handleSubmit, passwordRoute, tokens, requestError }
}
