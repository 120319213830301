// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AbortInteraction,
  AbortInteractionFromJSON,
  AuthType,
  FinishInteraction,
  FinishInteractionBody,
  FinishInteractionBodyToJSON,
  FinishInteractionFromJSON,
  InteractionDetail,
  InteractionDetailFromJSON,
  InteractionPasswordLoginBody,
  InteractionPasswordLoginBodyToJSON,
  InteractionStatus,
  InteractionStatusFromJSON,
  InteractionTokenLoginBody,
  InteractionTokenLoginBodyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface AbortInteractionByAuthTypeRequest {
  authType: AuthType
  interactionId: string
}

export interface FinishInteractionRequest {
  authType: AuthType
  interactionId: string
  finishInteractionBody?: FinishInteractionBody
}

export interface GetInteractionConsentStatusRequest {
  authType: AuthType
  interactionId: string
}

export interface GetInteractionDetailRequest {
  authType: AuthType
  interactionId: string
}

export interface InteractionLoginRequest {
  authType: AuthType
  interactionId: string
  interactionPasswordLoginBody: InteractionPasswordLoginBody
}

export interface InteractionTokenLoginRequest {
  authType: AuthType
  interactionId: string
  interactionTokenLoginBody: InteractionTokenLoginBody
}

/**
 *
 */
export class InteractionControllerApi extends runtime.BaseAPI {
  /**
   */
  async abortInteractionByAuthTypeRaw(
    requestParameters: AbortInteractionByAuthTypeRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AbortInteraction>> {
    if (requestParameters.authType === null || requestParameters.authType === undefined) {
      throw new runtime.RequiredError(
        'authType',
        'Required parameter requestParameters.authType was null or undefined when calling abortInteractionByAuthType.',
      )
    }
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling abortInteractionByAuthType.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/interaction/{authType}/{interactionId}/abort`
          .replace(`{${'authType'}}`, encodeURIComponent(String(requestParameters.authType)))
          .replace(`{${'interactionId'}}`, encodeURIComponent(String(requestParameters.interactionId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => AbortInteractionFromJSON(jsonValue))
  }
  /**
   */
  async abortInteractionByAuthType(
    requestParameters: AbortInteractionByAuthTypeRequest,
    initOverrides?: RequestInit,
  ): Promise<AbortInteraction> {
    const response = await this.abortInteractionByAuthTypeRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async finishInteractionRaw(
    requestParameters: FinishInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<FinishInteraction>> {
    if (requestParameters.authType === null || requestParameters.authType === undefined) {
      throw new runtime.RequiredError(
        'authType',
        'Required parameter requestParameters.authType was null or undefined when calling finishInteraction.',
      )
    }
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling finishInteraction.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/interaction/{authType}/{interactionId}/finish`
          .replace(`{${'authType'}}`, encodeURIComponent(String(requestParameters.authType)))
          .replace(`{${'interactionId'}}`, encodeURIComponent(String(requestParameters.interactionId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FinishInteractionBodyToJSON(requestParameters.finishInteractionBody),
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => FinishInteractionFromJSON(jsonValue))
  }
  /**
   */
  async finishInteraction(
    requestParameters: FinishInteractionRequest,
    initOverrides?: RequestInit,
  ): Promise<FinishInteraction> {
    const response = await this.finishInteractionRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async getInteractionConsentStatusRaw(
    requestParameters: GetInteractionConsentStatusRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InteractionStatus>> {
    if (requestParameters.authType === null || requestParameters.authType === undefined) {
      throw new runtime.RequiredError(
        'authType',
        'Required parameter requestParameters.authType was null or undefined when calling getInteractionConsentStatus.',
      )
    }
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling getInteractionConsentStatus.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/interaction/{authType}/{interactionId}/status`
          .replace(`{${'authType'}}`, encodeURIComponent(String(requestParameters.authType)))
          .replace(`{${'interactionId'}}`, encodeURIComponent(String(requestParameters.interactionId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => InteractionStatusFromJSON(jsonValue))
  }
  /**
   */
  async getInteractionConsentStatus(
    requestParameters: GetInteractionConsentStatusRequest,
    initOverrides?: RequestInit,
  ): Promise<InteractionStatus> {
    const response = await this.getInteractionConsentStatusRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async getInteractionDetailRaw(
    requestParameters: GetInteractionDetailRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InteractionDetail>> {
    if (requestParameters.authType === null || requestParameters.authType === undefined) {
      throw new runtime.RequiredError(
        'authType',
        'Required parameter requestParameters.authType was null or undefined when calling getInteractionDetail.',
      )
    }
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling getInteractionDetail.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/interaction/{authType}/{interactionId}/detail`
          .replace(`{${'authType'}}`, encodeURIComponent(String(requestParameters.authType)))
          .replace(`{${'interactionId'}}`, encodeURIComponent(String(requestParameters.interactionId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => InteractionDetailFromJSON(jsonValue))
  }
  /**
   */
  async getInteractionDetail(
    requestParameters: GetInteractionDetailRequest,
    initOverrides?: RequestInit,
  ): Promise<InteractionDetail> {
    const response = await this.getInteractionDetailRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async interactionLoginRaw(
    requestParameters: InteractionLoginRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.authType === null || requestParameters.authType === undefined) {
      throw new runtime.RequiredError(
        'authType',
        'Required parameter requestParameters.authType was null or undefined when calling interactionLogin.',
      )
    }
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling interactionLogin.',
      )
    }
    if (
      requestParameters.interactionPasswordLoginBody === null ||
      requestParameters.interactionPasswordLoginBody === undefined
    ) {
      throw new runtime.RequiredError(
        'interactionPasswordLoginBody',
        'Required parameter requestParameters.interactionPasswordLoginBody was null or undefined when calling interactionLogin.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/interaction/{authType}/{interactionId}/login`
          .replace(`{${'authType'}}`, encodeURIComponent(String(requestParameters.authType)))
          .replace(`{${'interactionId'}}`, encodeURIComponent(String(requestParameters.interactionId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionPasswordLoginBodyToJSON(requestParameters.interactionPasswordLoginBody),
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async interactionLogin(requestParameters: InteractionLoginRequest, initOverrides?: RequestInit): Promise<void> {
    await this.interactionLoginRaw(requestParameters, initOverrides)
  }
  /**
   */
  async interactionTokenLoginRaw(
    requestParameters: InteractionTokenLoginRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.authType === null || requestParameters.authType === undefined) {
      throw new runtime.RequiredError(
        'authType',
        'Required parameter requestParameters.authType was null or undefined when calling interactionTokenLogin.',
      )
    }
    if (requestParameters.interactionId === null || requestParameters.interactionId === undefined) {
      throw new runtime.RequiredError(
        'interactionId',
        'Required parameter requestParameters.interactionId was null or undefined when calling interactionTokenLogin.',
      )
    }
    if (
      requestParameters.interactionTokenLoginBody === null ||
      requestParameters.interactionTokenLoginBody === undefined
    ) {
      throw new runtime.RequiredError(
        'interactionTokenLoginBody',
        'Required parameter requestParameters.interactionTokenLoginBody was null or undefined when calling interactionTokenLogin.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/interaction/{authType}/{interactionId}/token-login`
          .replace(`{${'authType'}}`, encodeURIComponent(String(requestParameters.authType)))
          .replace(`{${'interactionId'}}`, encodeURIComponent(String(requestParameters.interactionId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionTokenLoginBodyToJSON(requestParameters.interactionTokenLoginBody),
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async interactionTokenLogin(
    requestParameters: InteractionTokenLoginRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.interactionTokenLoginRaw(requestParameters, initOverrides)
  }
}
