import { config } from 'config'
import { TransactionStatus } from 'oneid-api-frontend'
import { useQuery } from 'react-query'
import { resetPasswordApi } from 'utils/apis'

export const useTransaction = (tid: string) => {
  const result = useQuery<TransactionStatus, Error, TransactionStatus, [string, string]>(
    ['transaction', tid],
    () => resetPasswordApi.resetPasswordStatus({ transactionId: tid }),
    { refetchInterval: config.consentPollingInterval },
  )
  return { transaction: result.data, isLoading: result.isLoading, isError: result.isError }
}
