import { OneOf } from './types'

export const MetaClaimName = {
  Number: 'number',
  FirstName: 'firstname:en',
  MiddleName: 'middlename:en',
  LastName: 'lastname:en',
  FirstNameArabic: 'firstname:ar',
  MiddleNameArabic: 'middlename:ar',
  LastNameArabic: 'lastname:ar',
  Gender: 'gender',
  DateOfBirth: 'dob',

  Expire: 'expire',
  Issue: 'issue',

  Nid: 'nid',

  PortraitPhoto: 'portraitphoto',
  Signature: 'signature',

  ResidenceNo: 'address:residenceno',
  FlatNo: 'address:flatno',
  BuildingNo: 'address:buildingno',
  RoadNo: 'address:roadno',
  RoadName: 'address:roadname',
  BlockNo: 'address:blockno',
  BlockName: 'address:blockname',

  Nationality: 'nationality',
  NationalityCountryCode: 'nationality:countrycode',
  BirthCountryCode: 'birthcountrycode',

  PassportNumber: 'passport:number',
  PassportIssue: 'passport:issue',
  PassportExpire: 'passport:expire',

  Employer: 'employer:en',
  EmployerArabic: 'employer:ar',
  Occupation: 'occupation:en',
  OccupationArabic: 'occupation:ar',

  ResidentPermitNumber: 'residentpermit:number',
  ResidentPermitExpire: 'residentpermit:expire',
  ResidentPermitType: 'residentpermit:type',

  SerialNumber: 'serialnumber',
} as const
export type MetaClaimName = OneOf<typeof MetaClaimName>

export const UserClaimName = {
  PhoneNumber: 'phone_number',
  PhoneNumberVerified: 'phone_number_verified',
  Email: 'email',
  EmailVerified: 'email_verified',
} as const
export type UserClaimName = OneOf<typeof UserClaimName>

/**
 * Claims obtained from the EnrolledDocument record `proofs` property
 */
export const DocumentProofClaimName = {
  IdPhotoFront: 'photo:front',
  IdPhotoBack: 'photo:back',
} as const
export type DocumentProofClaimName = OneOf<typeof DocumentProofClaimName>

export const LivenessPhotoClaimName = {
  LivenessPhoto: 'liveness_photo',
} as const
export type LivenessPhotoClaimName = OneOf<typeof LivenessPhotoClaimName>
