import { JwtPayload } from 'jwt-decode'
import { createContext } from 'react'
import { Token } from 'types'
import { useDefinedContext } from 'utils'

export interface DecodedToken extends JwtPayload {
  sub: string
  exp: number
  raw: Token
}

interface TokensContext {
  tokens: DecodedToken[]
  addToken: (token: Token) => void
  removeToken: (token: Token) => void
  clearTokens: () => void
}

export const TokenContext = createContext<TokensContext | undefined>(undefined)

export const useTokens = () => useDefinedContext(TokenContext)
