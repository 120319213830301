import { createAndStoreKeyPair } from 'services/security'
import { Token } from 'types'
import { oidcRedirectApi } from 'utils/apis'

export const redirectWithPasswordLoginOIDC = async (
  email: string,
  password: string,
  uid: string,
  remember: boolean,
) => {
  await oidcRedirectApi.loginUserRaw({
    interactionId: uid,
    interactionPasswordLoginBody: { email, password, publicKey: await createAndStoreKeyPair(), issueToken: remember },
  })
}

export const redirectWithTokenLoginOIDC = async (token: Token, uid: string) => {
  await oidcRedirectApi.tokenLoginUserRaw({
    interactionId: uid,
    interactionTokenLoginBody: { signInToken: token, publicKey: await createAndStoreKeyPair(), issueToken: true },
  })
}

export const redirectWithAuthTokenLoginOIDC = async (uid: string) => {
  await oidcRedirectApi.authTokenLogin({
    interactionId: uid,
    interactionLoginBody: {
      publicKey: await createAndStoreKeyPair(),
      issueToken: false,
    },
  })
}

export const redirectWithFinishMobileLoginOIDC = async (uid: string) => {
  await oidcRedirectApi.finishMobileLogin({
    interactionId: uid,
  })
}
