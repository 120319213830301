import { Trans } from '@lingui/macro'
import { FC } from 'react'
import { styled, theme } from 'styles/stitches.config'

const StyledExpirationInstruction = styled('span', {
  marginTop: theme.space.t12,
  display: 'block',
  textAlign: 'center',
  fontSize: theme.fontSizes.t14,
  alignSelf: 'center',
  color: theme.colors.statesErrorBase,
  lineHeight: theme.lineHeights.text,

  marginBottom: 192, // to keep space below when Steps are hidden
})

export type CountdownExpirationInstructionProps = {
  clientName?: string
}

export const CountdownExpirationInstruction: FC<CountdownExpirationInstructionProps> = ({ clientName }) => (
  <StyledExpirationInstruction>
    {clientName ? (
      <Trans id="consentCountdown.restartWithClient">
        Go back to <strong>{clientName}</strong> and start the process again
      </Trans>
    ) : (
      <Trans id="consentCountdown.restart">Start the process again</Trans>
    )}
  </StyledExpirationInstruction>
)
