import { isObject, isString } from '../typechecks/index'

const [FRONT_OFFSET, BACK_OFFSET] = [50, 50]
const maxLength = FRONT_OFFSET + BACK_OFFSET

export const trimLongString = (str: string): string =>
  str.length > maxLength ? `${str.slice(0, FRONT_OFFSET)}...<<trimmed>>...${str.slice(-BACK_OFFSET)}` : str

export const trimStringRecursively = <T>(value: T): T => {
  if (isString(value)) return trimLongString(value) as T
  if (isObject(value)) {
    return Object.fromEntries(Object.entries(value).map(([key, v]) => [key, trimStringRecursively(v)])) as T
  }
  return value
}
