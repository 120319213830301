import { branding } from 'branding'
import { Divider, Template } from 'components'
import { config } from 'config'
import { DEFAULT_CLIENT, DEFAULT_CODE_VERIFIER } from 'const'
import {
  AuthCodeChallengeMethodEnum,
  AuthPromptEnum,
  AuthResponseModeEnum,
  AuthResponseTypeEnum,
} from 'oneid-api-frontend'
import { ChangeEventHandler, FC, useCallback, useState } from 'react'
import { Routes } from 'routes'
import { getRedirectUri } from 'services/oidc'
import { SamlPostLogin } from './SamlPostLogin'

const getAuthUri = (prompt: string, responseMode: AuthResponseModeEnum) => {
  const queryParams = new URLSearchParams({
    client_id: DEFAULT_CLIENT,
    response_type: AuthResponseTypeEnum.Code,
    redirect_uri: getRedirectUri(),
    response_mode: responseMode,
    scope: 'openid profile email',
    nonce: 'foobar',
    prompt,
    code_challenge: DEFAULT_CODE_VERIFIER,
    code_challenge_method: AuthCodeChallengeMethodEnum.Plain,
    state: 'my-state',
    claims: JSON.stringify({
      id_token: {
        'urn:oneid:passport:cze:firstname:en': null,
        'urn:oneid:id:bhr:firstname:en': null,
      },
    }),
  })
  return `${config.oidcApiPrefix}/auth?${queryParams.toString()}`
}

export const Home: FC = () => {
  const [responseMode, setResponseMode] = useState<AuthResponseModeEnum>(AuthResponseModeEnum.Query)
  const loginUri = getAuthUri(AuthPromptEnum.Login, responseMode)
  const createUri = getAuthUri(AuthPromptEnum.Create, responseMode)
  const handleModeChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setResponseMode(event.target.value as AuthResponseModeEnum),
    [setResponseMode],
  )
  return (
    <Template title="Home" wrapContent titleBottomMargin={44}>
      <div>
        <label>
          <input
            name="response_mode"
            type="radio"
            value={AuthResponseModeEnum.Query}
            onChange={handleModeChange}
            checked={responseMode === AuthResponseModeEnum.Query}
            data-qa="query-mode"
          />
          query mode
        </label>{' '}
        <label>
          <input
            name="response_mode"
            type="radio"
            value={AuthResponseModeEnum.Fragment}
            onChange={handleModeChange}
            checked={responseMode === AuthResponseModeEnum.Fragment}
            data-qa="fragment-mode"
          />
          fragment mode
        </label>
      </div>
      <div>
        <a href={loginUri} data-qa="oidc-login">
          OIDC login flow
        </a>
      </div>
      <div>
        <a href={createUri} data-qa="oidc-login-with-registration">
          OIDC login flow with registration
        </a>
      </div>
      <div>
        <a href={Routes.webSignup} data-qa="web-sign-up">
          Signup to {branding.brand}
        </a>
      </div>
      <Divider />
      <div>
        <a href={config.samlClientLoginInit}>SAML Login</a>
      </div>
      <div>
        <SamlPostLogin />
      </div>
    </Template>
  )
}
