import { VariantProps } from '@stitches/react'
import { Spinner } from 'components'
import { isString } from 'oneid-common'
import { FC, ReactElement, ReactNode } from 'react'
import { useContainerProperties } from 'services/containerProperties'
import { styled, theme } from 'styles/stitches.config'
import { getIsInIframe } from 'utils'
import {
  ContentStyled,
  InnerLanguageSelectorStyled,
  LogoStyled,
  OuterLanguageSelectorStyled,
  SubtitleStyled,
  TemplateStyled,
  TemplateWrapperStyled,
  TitleStyled,
} from './Template.styled'

const HeaderStyled = styled('header', {
  maxWidth: 360,
  alignSelf: 'center',

  '@mobile': {
    maxWidth: 'unset',
    alignSelf: 'auto',
  },

  variants: {
    marginBottom: {
      24: {
        marginBottom: theme.space.t24,
      },
      32: {
        marginBottom: theme.space.t32,
      },
      44: {
        marginBottom: theme.space.t44,
      },
    },
  },
})

export interface TemplateProps {
  title?: string | ReactElement
  subtitle?: string | ReactElement
  className?: string
  children?: ReactNode
  isLoading?: boolean
  wrapContent?: boolean
  centerVertically?: boolean
  isWide?: boolean
  titleBottomMargin?: VariantProps<typeof HeaderStyled>['marginBottom']
}

export const Template: FC<TemplateProps> = ({
  title,
  subtitle,
  titleBottomMargin,
  className,
  isLoading,
  centerVertically,
  isWide,
  children,
}) => {
  const containerProperties = useContainerProperties()
  const isInIframe = getIsInIframe()

  return (
    <>
      <TemplateWrapperStyled>
        <TemplateStyled isInIframe={isInIframe} screenType={containerProperties.screenType} className={className}>
          {!isInIframe && <LogoStyled className="logo" />}
          <ContentStyled centered={centerVertically} wide={isWide} isInIframe={isInIframe}>
            {(title || subtitle) && (
              <HeaderStyled marginBottom={titleBottomMargin}>
                {isString(title) ? <TitleStyled isInIframe={isInIframe}>{title}</TitleStyled> : title}
                {isString(subtitle) ? <SubtitleStyled>{subtitle}</SubtitleStyled> : subtitle}
              </HeaderStyled>
            )}
            {children}
            <InnerLanguageSelectorStyled />
          </ContentStyled>
        </TemplateStyled>
        <Spinner isLoading={isLoading} />
      </TemplateWrapperStyled>
      <OuterLanguageSelectorStyled />
    </>
  )
}
