import { AuthResponseModeEnum } from 'oneid-api-frontend'
import { decrypt } from 'services/crypto'
import * as storage from 'services/storage'
import { base64ToUrlSafe } from 'utils/base64ToUrlSafe'
import { isJsonWebKey } from 'utils/jsonWebKey'

type Params = {
  code: string
  key: string | null
  pubKeySha: string
}

export const callbackOIDC = async ({ code, key, pubKeySha }: Params) => {
  const privateKeyJson = storage.get(pubKeySha)
  if (!privateKeyJson) {
    throw Error('Private key is missing!')
  }

  const privateKey: unknown = JSON.parse(privateKeyJson)
  if (!isJsonWebKey(privateKey)) {
    throw Error('Private key is invalid!')
  }
  const encryptionKeyBase64 = key ? await decrypt(privateKey, key) : ''
  const encryptionKey = encryptionKeyBase64 && base64ToUrlSafe(encryptionKeyBase64)

  storage.remove(pubKeySha)

  return `${code}:${encryptionKey}`
}

const getRedirectUrl = (uri: string) => {
  try {
    return new URL(uri)
  } catch (e) {
    console.error(`Cannot create new URL object from ${uri}`)
    return
  }
}

export const getRedirectUriCallbackOIDC = (query: URLSearchParams) => {
  const redirectUri = query.get('redirectUri')
  const responseMode = query.get('responseMode')
  query.delete('redirectUri')
  query.delete('responseMode')

  if (!redirectUri) {
    return
  }

  const url = getRedirectUrl(redirectUri)
  if (!url) {
    return
  }

  url[responseMode === AuthResponseModeEnum.Fragment ? 'hash' : 'search'] = query.toString()

  return url.href
}
