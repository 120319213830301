// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InteractionTokenLoginBodyAllOf
 */
export interface InteractionTokenLoginBodyAllOf {
  /**
   * SignIn token
   * @type {string}
   * @memberof InteractionTokenLoginBodyAllOf
   */
  signInToken: string
}

export function InteractionTokenLoginBodyAllOfFromJSON(json: any): InteractionTokenLoginBodyAllOf {
  return InteractionTokenLoginBodyAllOfFromJSONTyped(json, false)
}

export function InteractionTokenLoginBodyAllOfFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InteractionTokenLoginBodyAllOf {
  if (json === undefined || json === null) {
    return json
  }
  return {
    signInToken: json['signInToken'],
  }
}

export function InteractionTokenLoginBodyAllOfToJSON(value?: InteractionTokenLoginBodyAllOf | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    signInToken: value.signInToken,
  }
}
