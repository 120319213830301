import { FC, useEffect } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { Routes } from 'routes'
import { ResetPasswordConfirm } from './ResetPasswordConfirm'
import { ResetPasswordContextProvider, useResetPassword } from './ResetPasswordContext'
import { ResetPasswordEmail } from './ResetPasswordEmail'
import { ResetPasswordNewPassword } from './ResetPasswordNewPassword'
import { ResetPasswordOtp } from './ResetPasswordOtp'

const ResetPasswordInner: FC = () => {
  const { session } = useResetPassword()
  const history = useHistory()
  const confirmRouteMatch = useRouteMatch(Routes.resetPasswordConfirm)

  useEffect(() => {
    if (!session && !confirmRouteMatch) history.replace(Routes.resetPasswordEmail, history.location.state)
  }, [history, session, confirmRouteMatch])

  return (
    <Switch>
      <Route path={Routes.resetPasswordEmail}>
        <ResetPasswordEmail />
      </Route>
      <Route path={Routes.resetPasswordOtp}>
        <ResetPasswordOtp />
      </Route>
      <Route path={Routes.resetPasswordNewPassword}>
        <ResetPasswordNewPassword />
      </Route>
      <Route path={Routes.resetPasswordConfirm}>
        <ResetPasswordConfirm />
      </Route>
    </Switch>
  )
}

export const ResetPassword: FC = () => (
  <ResetPasswordContextProvider>
    <ResetPasswordInner />
  </ResetPasswordContextProvider>
)
