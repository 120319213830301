import { config } from 'config'
import { ConsentStatusResponse } from 'oneid-api-frontend'
import { useQuery } from 'react-query'
import { oidcApi } from 'utils/apis'

export const useOidcConsent = (interactionId: string, enabled: boolean) => {
  const result = useQuery<ConsentStatusResponse>(
    ['oidcConsent', interactionId],
    () =>
      oidcApi.fetchConsentStatus({
        interactionId: interactionId,
      }),
    {
      // use function to run next request after the previous finished
      refetchInterval: () => config.consentPollingInterval,
      enabled,
    },
  )

  return { consent: result.data, isLoading: result.isLoading, isError: result.isError }
}
