import { ErrorBody, Interaction } from 'oneid-api-frontend'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isErrorResponse } from 'services/errors'
import { UidPathParams } from 'types'
import { DEFAULT_ERROR, oidcApi } from 'utils/apis'

export const useInteraction = () => {
  const { uid } = useParams<UidPathParams>()

  const [interaction, setInteraction] = useState<Interaction>()
  const [error, setError] = useState<ErrorBody>()

  const fetchInteraction = useCallback(async () => {
    if (!uid) return
    try {
      setInteraction(await oidcApi.getInteraction({ interactionId: uid }))
    } catch (err: unknown) {
      if (isErrorResponse(err) && err.error.code) {
        setError(err.error)
      } else {
        setError(DEFAULT_ERROR)
      }
    }
  }, [uid])

  useEffect(() => {
    void fetchInteraction()
  }, [fetchInteraction])

  return { interaction, error, interactionUid: uid }
}
