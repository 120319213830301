import { oidcApi } from 'utils/apis'

export const confirmConsent = async (uid: string) => {
  const { location, signInToken } = await oidcApi.confirmInteraction({
    interactionId: uid,
  })

  if (!location) {
    throw new Error('Expected location in response')
  }

  return { location, token: signInToken }
}

export const abortInteraction = async (uid: string): Promise<string> => {
  const { location } = await oidcApi.abortInteraction({
    interactionId: uid,
  })

  if (!location) {
    throw new Error('Expected location in response')
  }

  return location
}
