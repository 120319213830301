export type LogError = Error | unknown | null

export type AdditionalLoggerData = string | Record<string, unknown> | unknown

export type Logger = {
  error: (message: string, error?: LogError, additionalData?: AdditionalLoggerData) => void
  warn: (message: string, additionalData?: AdditionalLoggerData) => void
  info: (message: string, additionalData?: AdditionalLoggerData) => void
  debug: (message: string, additionalData?: AdditionalLoggerData) => void
}

export const getPrefixedLoggerFactory =
  <T extends Logger>(logger: T) =>
  (prefix: string): T => {
    const addPrefix = (message: string) => [prefix, message].join(' ')
    return {
      ...logger,
      info: (message, additionalData) => logger.info(addPrefix(message), additionalData),
      debug: (message, additionalData) => logger.debug(addPrefix(message), additionalData),
      warn: (message, additionalData) => logger.warn(addPrefix(message), additionalData),
      error: (message, error, additionalData) => logger.error(addPrefix(message), error, additionalData),
    }
  }
