import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { branding } from 'branding'
import cx from 'classnames'
import { localeNames } from 'const'
import { AvailableLocale } from 'oneid-common'
import { FC } from 'react'
import { selectLocale } from 'services/locale'
import { styled, theme } from 'styles/stitches.config'
import { getIsInIframe } from 'utils'

const AnchorStyled = styled('a', {
  margin: `0 ${theme.space.t8}`,
  textDecoration: 'none',
  cursor: 'pointer',
  fontWeight: theme.fontWeights.regular,

  '&.selected': {
    textDecoration: 'underline',
    fontWeight: theme.fontWeights.bold,

    '&:hover': {
      textDecoration: 'none',
    },
  },
})

const LanguageSelectorBase: FC = (props) => {
  const { i18n } = useLingui()
  const selectedLocale = i18n.locale

  if (getIsInIframe()) return null

  return (
    <div {...props}>
      <span>
        <Trans id="languageSelector.label">{`${branding.brand}`} offered in:</Trans>
      </span>
      {Object.values(AvailableLocale).map((locale, index) => (
        <span key={index}>
          <AnchorStyled
            className={cx({ ['selected']: selectedLocale === locale })}
            onClick={selectedLocale !== locale ? () => selectLocale(locale) : undefined}
          >
            {localeNames[locale]}
          </AnchorStyled>
          {index < Object.values(AvailableLocale).length - 1 && '|'}
        </span>
      ))}
    </div>
  )
}

export const LanguageSelector = styled(LanguageSelectorBase, {
  fontSize: theme.fontSizes.t12,
  textAlign: 'center',
})
