import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { t, Trans } from '@lingui/macro'
import { createOpenErrorToast } from 'components/toast'
import { useSubmitAction } from 'hooks/useSubmitAction'
import { AuthPromptEnum, Interaction, InteractionDetail } from 'oneid-api-frontend'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { Routes } from 'routes'
import { getChangePromptUrl } from 'services/oidc'
import { login as loginValidator } from 'services/validators'
import { theme } from 'styles/stitches.config'
import { LoginFormData, ResetPasswordRouteState } from 'types'
import { SubmitButton } from '../buttons'
import { CheckboxField, PasswordField, TextField } from '../input'
import { Form } from './Form'

type LoginFormProps = {
  onSubmit: SubmitHandler<LoginFormData>
  interaction: Interaction | InteractionDetail
  withCreateAccount?: boolean
}

export const LoginForm: FC<LoginFormProps> = ({ onSubmit, interaction, withCreateAccount }) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const { url } = useRouteMatch()
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(loginValidator()),
    defaultValues: { email: query.get('email') || undefined },
  })
  const email = watch('email')

  const [submit, isSubmitting] = useSubmitAction<LoginFormData>(onSubmit, createOpenErrorToast('toast-error'))
  const resetPasswordRouteState: ResetPasswordRouteState = { email, returnUrl: url }

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      footer={
        <SubmitButton disabled={isSubmitting}>
          <Trans id="loginForm.submitButton.label">Sign In</Trans>
        </SubmitButton>
      }
    >
      <TextField
        label={t({ id: 'loginForm.emailField.label', message: 'E-mail' })}
        placeholder="john.doe@gmail.com"
        type="email"
        {...register('email')}
        error={errors.email}
        autoFocus
        marginBottom={theme.space.t16}
      />
      <PasswordField
        label={t({ id: 'loginForm.passwordField.label', message: 'Password' })}
        {...register('password')}
        error={errors.password}
        marginBottom={theme.space.t12}
      />
      <CheckboxField
        label={t({ id: 'loginForm.checkboxField.label', message: 'Remember this device' })}
        {...register('remember')}
      />
      {withCreateAccount && (
        <a href={getChangePromptUrl(interaction.id, AuthPromptEnum.Create)}>
          <Trans id="loginForm.newAccountLink.label">Create account</Trans>
        </a>
      )}
      <Link to={{ pathname: Routes.resetPassword, state: resetPasswordRouteState }}>
        <Trans id="loginForm.forgottenPasswordLink.label">Forgot password</Trans>
      </Link>
    </Form>
  )
}
