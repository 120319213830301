import { t } from '@lingui/macro'
import { branding } from 'branding'
import { CountdownCounter, ErrorBox, InteractionInfo, Spinner, Steps, Template } from 'components'
import { Interaction, SpecialConsentType } from 'oneid-api-frontend'
import { FC } from 'react'
import { getConsentSteps } from 'services/consent'
import { ValidationCode } from './ValidationCode'
import { useOidcConsentController } from './hooks'

const getConsentTitle = (interaction: Interaction) => {
  switch (interaction.specialConsent?.consentType) {
    case SpecialConsentType.Sign:
      return t({ id: 'consentPage.signDocuments.title', message: `Sign documents in your ${branding.brand} app` })
    case SpecialConsentType.CreateCertificate:
      return t({ id: 'consentPage.createCertificate.title', message: 'Create certificate' })
    case undefined:
      return t({ id: 'consentPage.giveConsent.title', message: `Authenticate with ${branding.brand}` })
  }
}

export const Consent: FC = () => {
  const { interaction, error, remainingSeconds, hideConsentCountdownPage } = useOidcConsentController()

  if (error) {
    return (
      <Template centerVertically>
        <ErrorBox error={error?.description} />
      </Template>
    )
  }

  if (interaction && !interaction.isAppLogin && !hideConsentCountdownPage) {
    return (
      <Template title={getConsentTitle(interaction)} wrapContent titleBottomMargin={32}>
        <InteractionInfo user={interaction.user} client={interaction.client} />
        {interaction.validationCode && <ValidationCode code={interaction.validationCode} />}
        <CountdownCounter remainingSeconds={remainingSeconds} clientName={interaction.client.name} />
        {remainingSeconds > 0 && <Steps steps={getConsentSteps(interaction.client.name)} />}
      </Template>
    )
  }

  return <Spinner isLoading />
}
