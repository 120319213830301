import { config } from 'config'
import { AuthPromptEnum } from 'oneid-api-frontend'
import { Routes } from 'routes'

export const getChangePromptUrl = (uid: string, prompt: AuthPromptEnum) =>
  `${config.oidcApiPrefix}/interaction/${uid}/change_prompt/${prompt}`

export const getMobileUniversalLink = (uid: string, publicKey: string) => {
  const rootUrl = !config.appLinkHostname.match('://') ? `https://${config.appLinkHostname}` : config.appLinkHostname
  const url = new URL(`${rootUrl}${Routes.mobileLoginRedirect}`)
  url.searchParams.set('interactionId', uid)
  url.searchParams.set('publicKey', publicKey)
  return url
}

export const getRedirectUri = () => `${window.location.origin}${Routes.test}`
