import { t, Trans } from '@lingui/macro'
import { branding } from 'branding'
import { Form, LinkButton, StoreLink, Template, TemplateError } from 'components'
import { FC } from 'react'
import { generatePath } from 'react-router-dom'
import { Routes } from 'routes'
import { styled, theme } from 'styles/stitches.config'
import { useMobileLoginController } from './hooks'

const OrStyled = styled('div', {
  textTransform: 'uppercase',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'center',

  '&:before, &:after': {
    flex: '1',
    height: theme.space.t1,
    background: theme.colors.strokeDefault,
    content: '',
    display: 'block',
    margin: `0 ${theme.space.t16}`,
  },
})

const ContentStyled = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  width: '100%',
  marginTop: theme.space.t32,
})

const ImgWrapperStyled = styled('div', {
  flex: '0 0 40%',

  '& img': {
    width: '100%',
  },
})

const TextStyled = styled('div', {
  flex: '0 0 60%',
  paddingLeft: theme.space.t16,
  boxSizing: 'border-box',

  '& p': {
    fontSize: theme.fontSizes.t14,
    width: '100%',
  },

  '& a': {
    maxWidth: '10rem',
    width: '100%',
    display: 'block',
  },

  '& img': {
    width: '100%',
    display: 'block',
  },
})

export const MobileLogin: FC = () => {
  const { error, isExpired, mobileUniversalLink, interaction } = useMobileLoginController()

  if (isExpired)
    return (
      <TemplateError
        errorText={t({ id: 'signInPage.loginViaMobile.errorSessionExpired', message: 'Session has expired' })}
      />
    )
  if (error) return <TemplateError error={error} />

  return (
    <Template
      title={
        interaction &&
        t({
          id: 'signInPage.loginViaMobile.title',
          message: `${interaction.client.name} asks to verify your identity`,
        })
      }
      isLoading={!mobileUniversalLink}
      wrapContent
      titleBottomMargin={24}
    >
      <Form
        footer={
          interaction &&
          mobileUniversalLink && (
            <>
              <OrStyled>or</OrStyled>
              <LinkButton href={mobileUniversalLink.href}>
                <Trans id="signInPage.openOneIdApp.label">
                  <branding.logo.symbolDark /> Continue with {branding.brand} App
                </Trans>
              </LinkButton>
              <LinkButton to={generatePath(Routes.login, { uid: interaction.id })} variant="secondary">
                <Trans id="signInPage.useAnotherAccount.label">Use another account</Trans>
              </LinkButton>
            </>
          )
        }
      >
        <ContentStyled>
          <ImgWrapperStyled>
            <img src="/app-screenshot.png" alt="app screenshot" />
          </ImgWrapperStyled>
          <TextStyled>
            <Trans id="signInPage.infoText">
              <p>Provides a highly-secured digital ID profile</p>
              <p>Verify your identity in three simple steps</p>
              <p>Then you decide who can access your personal data</p>
            </Trans>
            <StoreLink />
          </TextStyled>
        </ContentStyled>
      </Form>
    </Template>
  )
}
