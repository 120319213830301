import { themeConstants } from '@beyon-connect/onex-ui-web'
import { rem } from 'polished'
import { branding } from '../branding'

// TODO: import constants from ui library

const BASE_SIZE = 8

export const SPACE = {
  t1: rem(1),
  t72: rem(BASE_SIZE * 9),
  t88: rem(BASE_SIZE * 11),

  ...themeConstants.SPACE,
}

export const SIZE = {
  ...SPACE,
}

export const LINE_HEIGHTS = {
  table: 1.2,
  heading: 1.2,
  text: 1.3,
  paragraph: 1.5,
  textRem: '1.3rem',
}

export const FONT_WEIGHTS = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
}

export const FONT_SIZES = {
  xs: '9px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  h3: '20px',
  h2: '24px',
  h1: '28px',
}

// TODO: add specific radii values and refactor code in places where we are using space values for BR
export const RADII = {
  round: '50%',
}

export const BORDER_WIDTHS = {
  hairline: '1px',
  ...SPACE,
}

export const TRANSITIONS = {
  onHover: 'background-color 0.15s, color 0.15s, border-color 0.15s',
}

export const SHADOWS = {
  default: '0px 0px 1px rgba(17, 47, 78, 0.24), 0px -2px 8px -1px rgba(17, 36, 41, 0.09)',
  popup: '0px 2px 8px 1px rgba(17, 36, 41, 0.09), 0px 0px 1px 0px rgba(17, 47, 78, 0.24)',
  sideMenu: '0px 8px 12px 0px rgba(17, 47, 78, 0.08), 0px 0px 1px 0px rgba(17, 47, 78, 0.24)',
}

export const PALETTE = {
  info50: '#F0F9FF',
  info100: '#D5EDFD',
  info700: '#0673BB',

  success50: '#eaf4f4',
  success100: '#ddefef',
  success700: '#006767',

  warning50: '#fff0e8',
  warning100: '#ffd3ba',
  warning700: '#a13b01',

  error50: '#fff1f3',
  error100: '#fdd2d8',
  error700: '#b4001a',

  visited: '#28188D',
  outline: '#cfdfff',

  linkDefault: '#0673BB',

  // Aliases and other semantic colors:

  border: '$gray400',
  borderLight: '$gray300',
  bgSelected: 'rgba(2, 46, 95, 0.1)',
  bgInfoHighlight: '$gray300',

  // brand colors
  ...branding.colors,
}
