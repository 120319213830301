import { AppRouter } from 'AppRouter'
import { OneXUIToast, Spinner } from 'components'
import { FC } from 'react'
import { useContainerProperties } from 'services/containerProperties'

export const AppLayout: FC = () => {
  const { isReadyToRender } = useContainerProperties()
  if (!isReadyToRender) return <Spinner isLoading />
  return (
    <>
      <AppRouter />
      <OneXUIToast.Toaster />
    </>
  )
}
