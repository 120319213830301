import { isRecordOfEnumKeysAndValues, isRecordOfEnumRequiredAndOptionalKeys, TypeCheckFunction } from './typechecks'
import { OneOf } from './types'

export const RequiredCPRPage = {
  One: '00A40004020101/00A40200020001',
  Two: '00A40004020101/00A4020C020002',
  Three: '00A40004020101/00A4020C020003',
  Four: '00A40004020101/00A4020C020005',
  Five: '00A4000C020301/00A4020C020001',
  Six: '00A4000C020301/00A4020C020002',
  Seven: '00A4000C020301/00A4020C020003',
  Employment: '00A40004020101/00A4020C020006',

  Final: '00CA010113',
} as const
export type RequiredCPRPage = OneOf<typeof RequiredCPRPage>

// No pages are optional now, but we might need some in the future - leave the
// empty type
export const OptionalCPRPage = {} as const
export type OptionalCPRPage = OneOf<typeof OptionalCPRPage>

export const CPRPage = { ...RequiredCPRPage, ...OptionalCPRPage }
export type CPRPage = RequiredCPRPage | OptionalCPRPage

export type CprPageRecord<T = unknown> = Record<CPRPage, T>

export const isCprPageRecord = <T>(valueTypeCheck: TypeCheckFunction<T>) =>
  isRecordOfEnumKeysAndValues(isRecordOfEnumRequiredAndOptionalKeys(RequiredCPRPage, OptionalCPRPage), valueTypeCheck)
