// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ItemToSign,
  ItemToSignFromJSON,
  ItemToSignToJSON,
  SpecialConsentType,
  SpecialConsentTypeFromJSON,
  SpecialConsentTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface SpecialConsent
 */
export interface SpecialConsent {
  /**
   *
   * @type {SpecialConsentType}
   * @memberof SpecialConsent
   */
  consentType: SpecialConsentType
  /**
   *
   * @type {{ [key: string]: ItemToSign; }}
   * @memberof SpecialConsent
   */
  itemsToSign?: { [key: string]: ItemToSign }
}

export function SpecialConsentFromJSON(json: any): SpecialConsent {
  return SpecialConsentFromJSONTyped(json, false)
}

export function SpecialConsentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialConsent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    consentType: SpecialConsentTypeFromJSON(json['consentType']),
    itemsToSign: !exists(json, 'itemsToSign') ? undefined : mapValues(json['itemsToSign'], ItemToSignFromJSON),
  }
}

export function SpecialConsentToJSON(value?: SpecialConsent | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    consentType: SpecialConsentTypeToJSON(value.consentType),
    itemsToSign: value.itemsToSign === undefined ? undefined : mapValues(value.itemsToSign, ItemToSignToJSON),
  }
}
