import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { createOpenErrorToast, Form, SubmitButton, Template, TextField } from 'components'
import { addSeconds } from 'date-fns'
import { useSubmitAction } from 'hooks/useSubmitAction'
import { FC, useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router-dom'
import { Routes } from 'routes'
import { email as emailValidator, getEmailError } from 'services/validators'
import { ResetPasswordRouteState } from 'types'
import { resetPasswordApi } from 'utils/apis'
import * as yup from 'yup'
import { useResetPassword } from './ResetPasswordContext'

type FormData = {
  email: string
}

export const ResetPasswordEmail: FC = () => {
  const validator = yup.object({ email: emailValidator().required(getEmailError()) })

  const history = useHistory<ResetPasswordRouteState>()
  const { email } = history.location.state
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm<FormData>({
    resolver: yupResolver(validator),
    defaultValues: {
      email,
    },
  })

  const { startSession } = useResetPassword()

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async ({ email }) => {
      const { id, expiration } = await resetPasswordApi.resetPasswordInit({
        resetPasswordInitRequest: { email },
      })
      startSession({ sessionId: id, expiration: addSeconds(new Date(), expiration) })
      history.push(generatePath(Routes.resetPasswordOtp, { resetPasswordId: id }), {
        ...history.location.state,
        email,
      })
    },
    [startSession, history],
  )

  const [submit, isSubmitting] = useSubmitAction<FormData>(onSubmit, createOpenErrorToast())

  return (
    <Template
      title={t({ id: 'resetPassword.enterEmailPage.title', message: 'Reset your password' })}
      subtitle={t({
        id: 'resetPassword.enterEmailPage.subtitle',
        message: 'Enter your email and we will send you an access code to reset your password.',
      })}
      wrapContent
      titleBottomMargin={24}
    >
      <Form
        onSubmit={handleSubmit(submit)}
        footer={
          <SubmitButton disabled={(!isValid && isSubmitted) || isSubmitting}>
            <Trans id="resetPassword.enterEmailPage.submitButton.label">Send access code</Trans>
          </SubmitButton>
        }
      >
        <TextField
          label={t({ id: 'resetPassword.enterEmailPage.emailField.label', message: 'E-mail' })}
          placeholder="john.doe@gmail.com"
          type="email"
          error={errors.email}
          autoFocus
          {...register('email')}
        />
      </Form>
    </Template>
  )
}
