// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ConsentStatus, ConsentStatusFromJSON, ConsentStatusToJSON, Device, DeviceFromJSON, DeviceToJSON } from './'

/**
 *
 * @export
 * @interface TransactionStatus
 */
export interface TransactionStatus {
  /**
   *
   * @type {Device}
   * @memberof TransactionStatus
   */
  activeDevice: Device
  /**
   *
   * @type {ConsentStatus}
   * @memberof TransactionStatus
   */
  consentStatus?: ConsentStatus
  /**
   *
   * @type {Date}
   * @memberof TransactionStatus
   */
  expiresAt: Date
}

export function TransactionStatusFromJSON(json: any): TransactionStatus {
  return TransactionStatusFromJSONTyped(json, false)
}

export function TransactionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionStatus {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activeDevice: DeviceFromJSON(json['activeDevice']),
    consentStatus: !exists(json, 'consentStatus') ? undefined : ConsentStatusFromJSON(json['consentStatus']),
    expiresAt: new Date(json['expiresAt']),
  }
}

export function TransactionStatusToJSON(value?: TransactionStatus | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activeDevice: DeviceToJSON(value.activeDevice),
    consentStatus: ConsentStatusToJSON(value.consentStatus),
    expiresAt: value.expiresAt.toISOString(),
  }
}
