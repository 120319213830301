import { t } from '@lingui/macro'
import { ErrorBody, ErrorCode } from 'oneid-api-frontend'

export const getTextForError = (error: ErrorBody | undefined) => {
  const errorTexts: Partial<Record<ErrorCode, string>> = {
    [ErrorCode.EmailOrPasswordIsIncorrect]: t({
      id: 'signupError.emailOrPasswordIncorrect.text',
      message: 'E-mail or password are incorrect!',
    }),
    [ErrorCode.InvalidOtp]: t({
      id: 'signupError.accessCodeIncorrect.text',
      message: 'Access code is incorrect!',
    }),
    [ErrorCode.InvalidPassword]: t({
      id: 'signupError.invalidPassword.text',
      message: 'Invalid password!',
    }),
    [ErrorCode.InvalidUser]: t({
      id: 'signupError.invalidUser.text',
      message: 'Invalid user!',
    }),
  }

  const otherErrorText = t({ id: 'signupError.other.text', message: 'Something went wrong' })

  return (error && errorTexts[error.code]) ?? otherErrorText
}
