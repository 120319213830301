import { AutoSubmitForm } from 'components'
import { config } from 'config'
import { isString, isStructure } from 'oneid-common'
import { FC, MouseEvent, useState } from 'react'
import { RequestResponse } from 'types'

export const SamlPostLogin: FC = () => {
  const [data, setData] = useState<RequestResponse>()

  const handlePostLogin = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const response = await fetch(config.samlClientLoginInit, {
      method: 'POST',
    })

    const jsonResponse: unknown = await response.json()
    if (
      isStructure<RequestResponse>({
        type: isString,
        context: isString,
        entityEndpoint: isString,
      })(jsonResponse)
    ) {
      setData(jsonResponse)
    }
  }

  return (
    <>
      <a onClick={handlePostLogin} href="">
        Saml POST login
      </a>
      {data && <AutoSubmitForm actionUrl={data.entityEndpoint} params={{ [data.type]: data.context }} />}
    </>
  )
}
