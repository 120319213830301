import { isObject as isObjectLodash } from 'lodash'
import { getObjectKeys } from '../typedObjectUtils'
import { OneOf } from '../types'

/**
 * Function checking whether parameter is given type.
 */
export type TypeCheckFunction<T> = (param: unknown) => param is T

type StructureTypeCheck<T> = {
  [K in keyof Required<T>]: TypeCheckFunction<T[K]>
}

export const isNull: TypeCheckFunction<null> = (param): param is null => param === null

export const isUndefined: TypeCheckFunction<undefined> = (param): param is undefined => param === undefined

export const isBoolean: TypeCheckFunction<boolean> = (param): param is boolean => typeof param === 'boolean'

export const isString: TypeCheckFunction<string> = (param): param is string => typeof param === 'string'

export const isNumber: TypeCheckFunction<number> = (param): param is number => typeof param === 'number'

export const isCallable = (param: CallableFunction | unknown): param is CallableFunction => typeof param === 'function'

export const isObject: TypeCheckFunction<Record<PropertyKey, unknown>> = (
  param,
): param is Record<PropertyKey, unknown> => !isNull(param) && isObjectLodash(param)

export const isNotNil = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined

export const isEnum =
  <TEnum extends object>(enumObj: TEnum): TypeCheckFunction<OneOf<TEnum>> =>
  (value: unknown): value is OneOf<TEnum> =>
    Object.values(enumObj).includes(value)

export const isStructure =
  <T>(typeChecks: StructureTypeCheck<T>) =>
  (structure: unknown): structure is T =>
    isObject(structure) && getObjectKeys(typeChecks).every((property) => typeChecks[property](structure[property]))

export const isArrayOfTypes =
  <T>(checkFunction: TypeCheckFunction<T>): TypeCheckFunction<T[]> =>
  (arr: unknown): arr is T[] =>
    Array.isArray(arr) && arr.every((item) => checkFunction(item))

export const isUndefinedOr =
  <T>(checkFunction: TypeCheckFunction<T>): TypeCheckFunction<T | undefined> =>
  (param): param is T | undefined =>
    isUndefined(param) || checkFunction(param)

export const isNullOr =
  <T>(checkFunction: TypeCheckFunction<T>): TypeCheckFunction<T | null> =>
  (param): param is T | null =>
    isNull(param) || checkFunction(param)

export const isNilOr =
  <T>(checkFunction: TypeCheckFunction<T>): TypeCheckFunction<T | undefined | null> =>
  (param): param is T | null | undefined =>
    isNull(param) || isUndefined(param) || checkFunction(param)
