import { isString, isStructure } from 'oneid-common'

const isErrorWithDescription = (err: unknown): err is { description: string } =>
  isStructure({ description: isString })(err)

export async function assert(response: Response) {
  if (response.status !== 200) {
    const errorJson: unknown = await response.json()
    if (isStructure<{ error: { description: string } }>({ error: isErrorWithDescription })(errorJson)) {
      throw new Error(errorJson.error.description)
    }
    throw new Error('assert --> unknown error')
  }
}
