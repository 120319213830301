import { ErrorBody } from 'oneid-api-frontend'
import { useCallback, useState } from 'react'
import { isErrorResponse } from 'services/errors'
import { DEFAULT_ERROR } from 'utils/apis'

export const useApiError = () => {
  const [error, setError] = useState<ErrorBody>()

  const setApiError = useCallback((err: unknown) => {
    if (isErrorResponse(err) && err.error.code) {
      setError(err.error)
    } else {
      setError(DEFAULT_ERROR)
    }
  }, [])
  return [error, setApiError] as const
}
