// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  ResetPasswordInitRequest,
  ResetPasswordInitRequestToJSON,
  ResetPasswordInitResponse,
  ResetPasswordInitResponseFromJSON,
  ResetPasswordNewPasswordRequest,
  ResetPasswordNewPasswordRequestToJSON,
  ResetPasswordNewPasswordResponse,
  ResetPasswordNewPasswordResponseFromJSON,
  ResetPasswordOtpRequest,
  ResetPasswordOtpRequestToJSON,
  TransactionStatus,
  TransactionStatusFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface ResetPasswordInitOperationRequest {
  resetPasswordInitRequest?: ResetPasswordInitRequest
}

export interface ResetPasswordNewPasswordOperationRequest {
  resetPasswordId: string
  resetPasswordNewPasswordRequest?: ResetPasswordNewPasswordRequest
}

export interface ResetPasswordOtpOperationRequest {
  resetPasswordId: string
  resetPasswordOtpRequest?: ResetPasswordOtpRequest
}

export interface ResetPasswordResendRequest {
  resetPasswordId: string
}

export interface ResetPasswordStatusRequest {
  transactionId: string
}

/**
 *
 */
export class ResetPasswordControllerApi extends runtime.BaseAPI {
  /**
   */
  async resetPasswordInitRaw(
    requestParameters: ResetPasswordInitOperationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ResetPasswordInitResponse>> {
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/resetPassword/init`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordInitRequestToJSON(requestParameters.resetPasswordInitRequest),
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => ResetPasswordInitResponseFromJSON(jsonValue))
  }
  /**
   */
  async resetPasswordInit(
    requestParameters: ResetPasswordInitOperationRequest,
    initOverrides?: RequestInit,
  ): Promise<ResetPasswordInitResponse> {
    const response = await this.resetPasswordInitRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async resetPasswordNewPasswordRaw(
    requestParameters: ResetPasswordNewPasswordOperationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ResetPasswordNewPasswordResponse>> {
    if (requestParameters.resetPasswordId === null || requestParameters.resetPasswordId === undefined) {
      throw new runtime.RequiredError(
        'resetPasswordId',
        'Required parameter requestParameters.resetPasswordId was null or undefined when calling resetPasswordNewPassword.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/resetPassword/{resetPasswordId}/newPassword`.replace(
          `{${'resetPasswordId'}}`,
          encodeURIComponent(String(requestParameters.resetPasswordId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordNewPasswordRequestToJSON(requestParameters.resetPasswordNewPasswordRequest),
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => ResetPasswordNewPasswordResponseFromJSON(jsonValue))
  }
  /**
   */
  async resetPasswordNewPassword(
    requestParameters: ResetPasswordNewPasswordOperationRequest,
    initOverrides?: RequestInit,
  ): Promise<ResetPasswordNewPasswordResponse> {
    const response = await this.resetPasswordNewPasswordRaw(requestParameters, initOverrides)
    return await response.value()
  }
  /**
   */
  async resetPasswordOtpRaw(
    requestParameters: ResetPasswordOtpOperationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.resetPasswordId === null || requestParameters.resetPasswordId === undefined) {
      throw new runtime.RequiredError(
        'resetPasswordId',
        'Required parameter requestParameters.resetPasswordId was null or undefined when calling resetPasswordOtp.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    headerParameters['Content-Type'] = 'application/json'
    const response = await this.request(
      {
        path: `/resetPassword/{resetPasswordId}/otp`.replace(
          `{${'resetPasswordId'}}`,
          encodeURIComponent(String(requestParameters.resetPasswordId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordOtpRequestToJSON(requestParameters.resetPasswordOtpRequest),
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async resetPasswordOtp(
    requestParameters: ResetPasswordOtpOperationRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.resetPasswordOtpRaw(requestParameters, initOverrides)
  }
  /**
   */
  async resetPasswordResendRaw(
    requestParameters: ResetPasswordResendRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.resetPasswordId === null || requestParameters.resetPasswordId === undefined) {
      throw new runtime.RequiredError(
        'resetPasswordId',
        'Required parameter requestParameters.resetPasswordId was null or undefined when calling resetPasswordResend.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/resetPassword/{resetPasswordId}/resend`.replace(
          `{${'resetPasswordId'}}`,
          encodeURIComponent(String(requestParameters.resetPasswordId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.VoidApiResponse(response)
  }
  /**
   */
  async resetPasswordResend(requestParameters: ResetPasswordResendRequest, initOverrides?: RequestInit): Promise<void> {
    await this.resetPasswordResendRaw(requestParameters, initOverrides)
  }
  /**
   */
  async resetPasswordStatusRaw(
    requestParameters: ResetPasswordStatusRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<TransactionStatus>> {
    if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
      throw new runtime.RequiredError(
        'transactionId',
        'Required parameter requestParameters.transactionId was null or undefined when calling resetPasswordStatus.',
      )
    }
    const queryParameters: any = {}
    const headerParameters: runtime.HTTPHeaders = {}
    const response = await this.request(
      {
        path: `/resetPassword/{transactionId}/status`.replace(
          `{${'transactionId'}}`,
          encodeURIComponent(String(requestParameters.transactionId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )
    return new runtime.JSONApiResponse(response, (jsonValue) => TransactionStatusFromJSON(jsonValue))
  }
  /**
   */
  async resetPasswordStatus(
    requestParameters: ResetPasswordStatusRequest,
    initOverrides?: RequestInit,
  ): Promise<TransactionStatus> {
    const response = await this.resetPasswordStatusRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
