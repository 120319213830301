// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Client,
  ClientFromJSON,
  ClientToJSON,
  SpecialConsent,
  SpecialConsentFromJSON,
  SpecialConsentToJSON,
  User,
  UserFromJSON,
  UserToJSON,
} from './'

/**
 *
 * @export
 * @interface Interaction
 */
export interface Interaction {
  /**
   *
   * @type {Client}
   * @memberof Interaction
   */
  client: Client
  /**
   *
   * @type {Date}
   * @memberof Interaction
   */
  expiresAt: Date
  /**
   *
   * @type {string}
   * @memberof Interaction
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof Interaction
   */
  isAppLogin?: boolean
  /**
   *
   * @type {SpecialConsent}
   * @memberof Interaction
   */
  specialConsent?: SpecialConsent
  /**
   *
   * @type {User}
   * @memberof Interaction
   */
  user?: User
  /**
   *
   * @type {string}
   * @memberof Interaction
   */
  validationCode?: string
}

export function InteractionFromJSON(json: any): Interaction {
  return InteractionFromJSONTyped(json, false)
}

export function InteractionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Interaction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    client: ClientFromJSON(json['client']),
    expiresAt: new Date(json['expiresAt']),
    id: json['id'],
    isAppLogin: !exists(json, 'isAppLogin') ? undefined : json['isAppLogin'],
    specialConsent: !exists(json, 'specialConsent') ? undefined : SpecialConsentFromJSON(json['specialConsent']),
    user: !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    validationCode: !exists(json, 'validationCode') ? undefined : json['validationCode'],
  }
}

export function InteractionToJSON(value?: Interaction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    client: ClientToJSON(value.client),
    expiresAt: value.expiresAt.toISOString(),
    id: value.id,
    isAppLogin: value.isAppLogin,
    specialConsent: SpecialConsentToJSON(value.specialConsent),
    user: UserToJSON(value.user),
    validationCode: value.validationCode,
  }
}
