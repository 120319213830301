import { createContext } from 'react'
import { useDefinedContext } from 'utils'

export enum ScreenType {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export interface ContainerProperties {
  screenType?: ScreenType
  isReadyToRender: boolean
}

export const ContainerPropertiesContext = createContext<ContainerProperties | undefined>(undefined)

export const useContainerProperties = () => useDefinedContext(ContainerPropertiesContext)
