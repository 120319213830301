import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useEffect } from 'react'
import { initLanguage } from 'services/locale'
import { FCC } from 'types'

export const LocaleInitializer: FCC = ({ children }) => {
  useEffect(() => initLanguage(), [])

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
