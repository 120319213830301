import { StatusType } from '@beyon-connect/onex-ui-web'
import { OneXUIToast } from './Toast/Toast'

type OpenToast = typeof OneXUIToast.openToast
const openToast: OpenToast = (props, options) =>
  OneXUIToast.openToast({ maxWidth: 500, minWidth: 200, width: 'max-content', ...props }, options)

const createOpenToast = (type: StatusType) => (dataQa?: string) => (message: string) =>
  openToast({ type, message, dataQa })

export const createOpenErrorToast = createOpenToast('error')
export const createOpenSuccessToast = createOpenToast('success')

export const openErrorToast = createOpenErrorToast()
export const openSuccessToast = createOpenSuccessToast()
