import { ReactComponent as ErrorIcon } from 'assets/ErrorBig.svg'
import { FC } from 'react'
import { styled, theme } from 'styles/stitches.config'

const ErrorBoxStyled = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: `${theme.space.t44} auto 0 auto`,
})

const ErrorIconStyled = styled(ErrorIcon, {
  paddingBottom: theme.space.t16,
})

const ErrorStyled = styled('div', {
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes.h1,
})

type Props = { error: string }

export const ErrorBox: FC<Props> = ({ error }) => (
  <ErrorBoxStyled>
    <ErrorIconStyled />
    <ErrorStyled className="error">{error}</ErrorStyled>
  </ErrorBoxStyled>
)
