import { Trans } from '@lingui/macro'
import { FC, useMemo } from 'react'
import { styled, theme } from 'styles/stitches.config'

const ValidationCodeStyled = styled('div', {
  boxSizing: 'border-box',
  borderWidth: theme.borderWidths.hairline,
  borderStyle: 'solid',
  borderColor: theme.colors.strokeDefault, // TODO: Check the: borderColor: theme.colors.gray400,
  width: '100%',
  minHeight: theme.space.t80,
  padding: `${theme.space.t16} ${theme.space.t24}`,
  position: 'relative',
  marginBottom: theme.space.t40,
  borderRadius: theme.space.t8,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const TitleStyled = styled('div', {
  fontSize: theme.fontSizes.t12,
  color: theme.colors.textSecondary,
  lineHeight: '150%',
  marginBottom: theme.space.t8,
})

const CodeStyled = styled('div', {
  fontSize: theme.fontSizes.h3,
  letterSpacing: theme.space.t4,
  fontWeight: theme.fontWeights.semiBold,
})

type Props = { code: string }

export const ValidationCode: FC<Props> = ({ code }) => {
  const formattedCode = useMemo(
    () =>
      code
        .toUpperCase()
        .split(/(..)/g)
        .filter((s) => s)
        .join(' - '),
    [code],
  )
  return (
    <ValidationCodeStyled>
      <TitleStyled>
        <Trans id="consentPage.validationCode.label">Check if the code in the app is the same</Trans>
      </TitleStyled>
      <CodeStyled dir="ltr">{formattedCode}</CodeStyled>
    </ValidationCodeStyled>
  )
}
