import cx from 'classnames'
import { FC, FormEventHandler, ReactNode } from 'react'
import { styled, theme } from 'styles/stitches.config'

const FooterStyled = styled('div', {
  width: '100%',
  paddingTop: theme.space.t24,
  marginBottom: 0,

  '& p': {
    fontSize: theme.fontSizes.t12,
    marginTop: 0,
    marginBottom: theme.space.t12,
    color: theme.colors.textSecondary,

    '& a': {
      textDecoration: 'underline',
      whiteSpace: 'nowrap',
      fontWeight: theme.fontWeights.bold,

      '&:hover': {
        textDecoration: 'none',
      },
    },
  },

  '& button:not(:first-child), a:not(:first-child)': {
    marginTop: theme.space.t16,
  },

  '@mobile': {
    marginTop: 'auto',
    paddingBottom: theme.space.t24,
    marginBottom: `-${theme.space.t24}`,
  },
})

const FormStyled = styled('form', {
  margin: 0,
  padding: 0,
  display: 'flex',
  flexFlow: 'column nowrap',
  width: '100%',

  '& > a': {
    marginBottom: theme.space.t8,
  },

  '@mobile': {
    width: '100%',
    flex: '1',
  },
})

export interface FormProps {
  action?: string
  method?: 'post' | 'get'
  onSubmit?: FormEventHandler<HTMLFormElement>
  className?: string
  children: ReactNode
  footer?: ReactNode
}

export const Form: FC<FormProps> = ({ className, action, method, onSubmit, footer, children }) => (
  <FormStyled className={cx(className)} onSubmit={onSubmit} action={action} method={method}>
    {children}
    {footer && <FooterStyled className="footer">{footer}</FooterStyled>}
  </FormStyled>
)
