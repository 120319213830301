import { subSeconds } from 'date-fns'
import { DateTimeOperations } from './interface'

export const createDateFnsDateTimeOperations = (): DateTimeOperations => {
  const now: DateTimeOperations['now'] = () => new Date()

  const secondsAgo: DateTimeOperations['secondsAgo'] = (seconds) => {
    if (seconds <= 0) {
      throw new Error('Seconds must be <1,inf)')
    }

    return subSeconds(new Date(), seconds)
  }

  return { now, secondsAgo }
}
