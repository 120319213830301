// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * OneID API
 * OneID API consists of two parts. oidcController flow and additional supporting routes.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ItemToSign
 */
export interface ItemToSign {
  /**
   *
   * @type {string}
   * @memberof ItemToSign
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ItemToSign
   */
  value: string
}

export function ItemToSignFromJSON(json: any): ItemToSign {
  return ItemToSignFromJSONTyped(json, false)
}

export function ItemToSignFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemToSign {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    value: json['value'],
  }
}

export function ItemToSignToJSON(value?: ItemToSign | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    value: value.value,
  }
}
